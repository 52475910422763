import React,{useState,useEffect,useRef,useMemo} from 'react'
import DataService from '../../services/providers/data.service'
import {ProductComponent} from '../../components/general-components/ProductComponent'
import {UserSearchProducts} from '../../services/utilities/arraySearch'
import { LoadingPage } from './LoadingPage'



export const ProductListPage = () => {
  
  const [products, setProducts] = useState([])
  const [hide, setHide] = useState(false)
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState(false)
  var latestDoc = useRef(null)

  //separate component to deal with data calls?
  useEffect(() => {
    const getInitialData = async () => {
  
      let productData= await DataService.getLimitedProducts(12, latestDoc.current);
  
      if (productData.empty) {
        setHide(true);
        return;
      }
  
      const newProducts = productData.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      setProducts((prevProducts) => [...prevProducts, ...newProducts]);
  
      latestDoc.current = productData.docs[productData.docs.length - 1];
    };
  
    if (loading && !filter) {
      getInitialData();
      setLoading(false);
    }
  }, [loading,filter]);

  const handleFilter = async (e) => {
    //get orders by created date
  
    const p = await DataService.getAllDocuments("products")
    setFilter(true)
    setHide(true)
    let data = p.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
        }))

    const q = UserSearchProducts(data,e.target.value)
    if(e.target.value === '' || e.target.value === null){
        setProducts(data)
        setLoading(true)
    }else{
        setProducts(q)
    }
  }
  
  

  // handles the infinite scroll functionality
  
  const buttons = document.querySelectorAll(".load-more-button")

  const observer = useMemo(()=>{ return new IntersectionObserver( entries => {
    entries.forEach( entry => {
      if(entry.isIntersecting && !hide){
        setLoading(true)
      }
    })
  })},[hide])
  buttons.forEach(button => observer?.observe(button))

  if(!products){
    return (
      <LoadingPage/>
    )
  }


  return (
    <div className='w-full min-h-[100vh] relative flex flex-col items-center pb-[25vh] overflow-hidden'>

      {/* <div className={`z-20 border-2 border-black fixed border-l-0 ${open ? `translate-x-[30vw]`:'translate-x-0'} ease-in-out duration-300 bg-primary h-20 left-0 top-[50vh] pl-1 pr-3 lg:pl-2 lg:pr-4 flex items-center rounded-tr-full rounded-br-full cursor-pointer `}
      onClick={()=> setOpen(!open)}
      >
        <BsFillFilterCircleFill className='text-secondary text-4xl lg:text-5xl'/>
      </div> */}


      {/* <FilterSidebar open={open} setOpen={setOpen} callReset={callReset} setLoading={setLoading} categories={data?.categories} tags={data?.tags} filters={filters} setFilters={setFilters}/> */}

      <div className='w-full flex flex-col md:flex-row justify-evenly items-center h-[20vh] text-background bg-primary text-center'>
        <h5 className='w-full md:w-[25%] px-2'>
        Not sure what products would best suit your skin?
        Discover your skin type!
        </h5>
        <a href={`blog/skin-guides`} className='px-10 py-1 bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900'>
          LEARN MORE
        </a>
      </div>

      {/* ACTIVE FILTER RESETER */}
      {/* {(tagsArray || category || filters.length > 0 )&& 
      <div className='w-full flex flex-col items-center justify-center py-3 border-y-2 text-primary'>
        <h3 className='underline decoration-secondary underline-offset-4'>ACTIVE FILTERS</h3>
        <FilterTags filters={activeFilters()}/>
        <button onClick={callReset} className='px-10 py-2 rounded-full bg-gradient-to-b from-red-300 via-red-400 to-accent-secondary hover:shadow-inner hover:shadow-black text-white'>RESET FILTERS</button>
      </div>
      } */}

      <div className='w-full h-20 flex items-center justify-center'>
        <input type='text' placeholder='Search...' className='w-[70vw] lg:w-[50vw] h-10 text-black flex items-center justify-center rounded-sm' onChange={handleFilter}/>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mt-10 ease-linear min-h-screen'>
        {/* INDIVIDUAL ITEM */}

        {products?.map((item,index)=>(<ProductComponent key={index} item={item}/>))}

      </div>
      {!hide &&  <button className='load-more-button text-center my-3 bg-gradient-to-b from-gray-700 via-gray-900 to-black shadow-md hover:shadow-inner hover:shadow-black w-[75%] h-10 text-white' onClick={()=> setLoading(!loading)}>LOAD MORE</button>}

    </div>
  )
}
