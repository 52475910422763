import React from 'react'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
export const LayoutComponent = ({children}) => {
  return (
    <div className='w-[100vw] min-h-[100vh] flex-col font-serif'>
    <Navbar/>
    <div className='w-[100vw] min-h-[100vh] bg-background text-white pt-[20vh] md:pt-[18vh]'>
      <ToastContainer position='bottom-right'/>
      {children}
    </div>
    <Footer/>
    </div>
  )
}
