import {useState, useEffect} from 'react'


const useMonthlyStats = (orders,year,month) => {

    const [configData, setConfigData] = useState()
    

    useEffect(()=> {

    const monthlySalesData = () => {

        var sales = 0
        var numSold = 0
        var numOrders = orders?.length
        var days
        var bestDay

        if(parseInt(month) === 4 || parseInt(month) === 6 || parseInt(month) === 9|| parseInt(month) === 11){
            days = [...Array(30)].map(()=> [])
        }else if(parseInt(year) % 4 === 0 && parseInt(month) === 2){
            days = [...Array(29)].map(()=> [])
        }else if(parseInt(month) === 2){
            days = [...Array(28)].map(()=> [])
        }else{
            days = [...Array(31)].map(()=> [])
        }


        orders?.forEach( item => {
            const day = new Date(item.created.seconds * 1000).getDate() - 1
            sales += parseInt(item.subtotal)

            if(days[day]?.length > 0){
                days[day][0].totalSales = parseInt(days[day][0].totalSales) + parseInt(item.subtotal)
                days[day][0].orders.push({
                    orderAmount: item.subtotal,
                    userID: item?.userUID,
                    orderID: item.id,
                })

            }else{
                days[day] =[{
                    totalSales: item.subtotal,
                    orders: [
                        {
                            orderAmount: item.subtotal,
                            userID: item?.userUID,
                            orderID: item.id,
                        }
                    ]
                }]
            }

            numSold += item.userCart.reduce((total,product) => total + parseInt(product.qty),0)

        })

        const bestSalesDay = () => {
            var index = {index:0,value:0}
            days.forEach((day,i) => {
                if(parseInt(day[0]?.totalSales) > index.value){
                    index.index = i
                    index.value = parseInt(day[0]?.totalSales)
                }
            })
            return {data:days[index.index][0], date:new Date(year,parseInt(month)-1,index.index+1)}
        }

        bestDay = bestSalesDay()

        return {sales, numSold, numOrders, days, bestDay}
    }

      
    setConfigData({
        thisMonthOrders: monthlySalesData().numOrders,
        thisMonthSales: monthlySalesData().sales,
        totalItemsSold: monthlySalesData().numSold,
        ordersByDays: monthlySalesData().days,
        bestSalesDay: monthlySalesData().bestDay,
    })

    },[orders,month,year])

    return {configData}
}

export default useMonthlyStats;