import { useState } from "react"


const ShippingInfoModal = ({shipping,setOpen,runFunction}) => {

    const local = shipping?.filter((item) => item.country === 'nigeria' && item.state !== 'kaduna')
    const kaduna = shipping?.filter((item) => item.state === 'kaduna')
    const international = shipping?.filter((item) => item.country !== 'nigeria')

    const [selected, setSelected] = useState('kaduna')

    return (
    <div className='fixed inset-0 flex flex-col items-center justify-center z-50 font-main'>
        <div className='w-[25rem] lg:w-[40rem] h-[40rem] p-10 z-50 bg-white flex flex-col items-center'>
            <button
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold mb-5"
                onClick={() => setOpen((open)=>!open)}
            >
                <span className="bg-transparent text-black h-6 w-6 text-2xl block">
                X
                </span>
            </button>
            <div className="w-[90%] lg:w-[80%] h-14 flex flex-row items-center justify-around border">
                <h2 onClick={()=>setSelected('kaduna')} className={`${selected === 'kaduna' ? `bg-black text-white`: `bg-white text-secondary underline underline-offset-4 decoration-secondary`} cursor-pointer w-[50%] h-full flex flex-col items-center justify-center text-center`}>
                    Within Kaduna
                </h2>
                <h2 onClick={()=>setSelected('local')} className={`${selected === 'local' ? `bg-black text-white`: `bg-white text-secondary underline underline-offset-4 decoration-secondary`} cursor-pointer w-[50%] h-full flex flex-col items-center justify-center text-center`}>
                    Within Nigeria
                </h2>
                <h2 onClick={()=>setSelected('international')} className={`${selected === 'international' ? `bg-black text-white`: `bg-white text-secondary underline underline-offset-4 decoration-secondary`} cursor-pointer w-[50%] h-full flex flex-col items-center justify-center text-center`}>
                    International Locations
                </h2>
            </div>
            <p className="w-[75%] h-[20] text-center text-black mt-5">
                {"If your desired location is not on the list below, please contact our customer care team for assistance. They will be happy to help you with your shipping needs."}
            </p>
            <div className="w-full h-[60%] mt-5 overflow-y-scroll">
                <div className="w-full h-[5rem] border flex flex-row items-center justify-between text-primary">
                    <h4 className="w-[50%] h-full flex flex-row items-center justify-center">{"City/District"}</h4>
                    <h4 className="w-[50%] h-full flex flex-row items-center justify-center">{"State"}</h4>
                </div>
                {(selected === 'local' ? local : selected === 'kaduna' ? kaduna :international)?.map( (item,index) => (
                    <div key={index} className="w-full h-[5rem] border flex flex-row items-center justify-between text-primary">
                        <h4 className="w-[50%] h-full flex flex-row items-center justify-center">{item.city.toUpperCase()}</h4>
                        <h4 className="w-[50%] h-full flex flex-row items-center justify-center">{item.state.toUpperCase()}</h4>
                    </div>
                ))}
            </div>
        </div>
        <div className="opacity-75 fixed inset-0 z-40 bg-black" onClick={() => setOpen(false)}></div>
    </div>
    )
  }
  

export default ShippingInfoModal