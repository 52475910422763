import React,{useRef,useState} from 'react'
import AuthService from '../../services/providers/auth.service'
import {toast} from 'react-toastify'
import { ValidateUserData } from '../../services/utilities/errorHandler'
// import {useNavigate} from 'react-router-dom'

export const RegistrationPage = () => {

    const formEl = useRef()
    const [lock, setLock] = useState(false)

    const createAccount = async (e) => {
        e.preventDefault()

        try{
          setLock(true)
          let errors = ValidateUserData(formEl.current)
          if(errors.length > 0){
            setLock(false)
            throw new Error(errors)
          }
          await AuthService.createUser(formEl)
          setLock(false)
          document.getElementById("registration-form").reset()
        }catch(err){
          toast.error(err.message)
        }
    }


  return (
    <div className='bg-background w-full h-[100vh] flex justify-center items-center text-black'>
            <form ref={formEl} id={'registration-form'}  onSubmit={createAccount} className='w-[25rem] mb-10 flex flex-col items-center justify-evenly'> 
                <input type='text' name='fname' required placeholder='First Name' className='w-[90%] p-3 m-2 rounded-2xl border-2 border-secondary'/>
                <input type='text' name='lname' required placeholder='Last Name' className='w-[90%] p-3 m-2 rounded-2xl border-2 border-secondary'/>
                <input type='email' name='email' required placeholder='Email Address' className='w-[90%] p-3 m-2 rounded-2xl border-2 border-secondary'/>
                <input type='number' name='phone' required placeholder='Phone Number' className='w-[90%] p-3 m-2 rounded-2xl border-2 border-secondary'/>
                <input type='password' name='password' required placeholder='Password' className='w-[90%] p-3 m-2 rounded-2xl border-2 border-secondary'/>
                <input type='password' name='passwordRepeat' required placeholder='Repeat Password' className='w-[90%] p-3 m-2 rounded-2xl border-2 border-secondary'/>
                <button type='submit' disabled={lock} className='w-[50%] p-3 m-2 bg-black text-white hover:bg-secondary px-5 rounded-2xl border-2 border-secondary'>SIGN UP</button>
            </form>
    </div>
  )
}
