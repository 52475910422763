import React from 'react'
import notFound from '../../assets/404.gif'

export const Error404Page = () => {
  return (
    <div className='w-full h-[100vh] flex flex-col justify-center items-center text-black'>
        <img src={notFound} alt='404 Page' className='w-[full] h-[full] bg-inherit'/>
        <a href='/' className='px-20 py-2 bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 text-white'>Back To Home</a>
    </div>
  )
}
