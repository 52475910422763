import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom'
import {BsFillArrowUpCircleFill as Up, BsFillArrowDownCircleFill as Down} from 'react-icons/bs'

export const AdminUserOrdersComponent = ({order}) => {
  
    const [open,setOpen] = useState(false)
    const navigate = useNavigate()
    const date = order.created?.toDate().toDateString()

    return (
    <>
    <div className='h-10 w-full lg:w-[80vw] grid grid-flow-row-dense grid-cols-10 bg-gradient-to-t from-gray-700 via-gray-900 to-black text-xs md:text-base lg:text-lg border-t-2'>
        <div className='col-span-4 border-b border-x-2 flex items-center justify-center'>{`${date}`}</div>
        <div className='col-span-3 border-b border-r-2 flex items-center justify-center cursor-pointer hover:underline decoration-secondary' onClick={()=> navigate(`/admin/orders/${order.id}`)}>{`${order.id}`}</div>
        <div className={`col-span-2 border-b border-r-2 flex items-center justify-center ${order.status ? `bg-green-500`:`bg-red-500`}`}>{`${order.status ? "PAID":"UNPAID"}`}</div>
        <div className='col-span-1 border-b border-r-2 flex items-center justify-center cursor-pointer text-2xl md:text-3xl' onClick={()=> setOpen(!open)}>{open ?  <Up/>:<Down/>}</div>
    </div>
    {!open ? null :
    <>
    <div className='h-8 w-full lg:w-[80vw] grid grid-flow-row-dense grid-cols-8 bg-secondary text-xs md:text-base lg:text-lg border-t-2'>
        <div className='col-span-3 border-b border-x-2 flex justify-center items-center'>{"Product Name"}</div>
        <div className='col-span-2 border-b border-r-2 flex justify-center items-center'>{"Size"}</div>
        <div className='col-span-1 border-b border-r-2 flex justify-center items-center'>{"Quantity"}</div>
        <div className='col-span-2 border-b border-r-2 flex justify-center items-center'>{"Sale Price"}</div>
    </div>
    {order?.userCart?.map((item,i)=>{
        return <div key={i} className='h-12 w-full lg:w-[80vw] grid grid-flow-row-dense grid-cols-8 bg-white text-black text-xs md:text-base lg:text-lg border-t-2'>
                <div className='col-span-3 border-b border-x-2 flex justify-center items-center text-center'>{item?.name}</div>
                <div className='col-span-2 border-b border-r-2 flex justify-center items-center'>{item?.size}</div>
                <div className='col-span-1 border-b border-r-2 flex justify-center items-center'>{item?.qty}</div>
                <div className='col-span-2 border-b border-r-2 flex justify-center items-center'>N{item?.price}</div>
            </div>
    })}
    <div className='h-12 w-full lg:w-[80vw] grid grid-flow-row-dense grid-cols-2 bg-white text-black text-xs md:text-base lg:text-lg border-t-2'>
        <div className='col-span-1 border-b border-x-2 flex justify-center items-center text-center'>{"Subtotal"}</div>
        <div className='col-span-1 border-b border-r-2 flex justify-center items-center'>N {order?.subtotal}</div>
    </div>
    <div className='h-12 w-full lg:w-[80vw] grid grid-flow-row-dense grid-cols-2 bg-white text-black text-xs md:text-base lg:text-lg border-t-2'>
        <div className='col-span-1 border-b border-x-2 flex justify-center items-center text-center'>{"Shipping"}</div>
        <div className='col-span-1 border-b border-r-2 flex justify-center items-center'>N {order?.shipping}</div>
    </div>
    <div className='h-12 w-full lg:w-[80vw] grid grid-flow-row-dense grid-cols-2 bg-white text-black text-xs md:text-base lg:text-lg border-t-2'>
        <div className='col-span-1 border-b border-x-2 flex justify-center items-center text-center'>{"Total"}</div>
        <div className='col-span-1 border-b border-r-2 flex justify-center items-center'>N {order?.total}</div>
    </div>
    </>
    }
        </>
  )
}
