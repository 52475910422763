import React,{useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import useYearlyStats from '../../../hooks/useYearlyStats'
import DataService from '../../../services/providers/data.service'
import { YearlySalesGraph } from '../../../components/admin-components/YearlySalesGraph'

export const YearlySalesStatsPage = () => {

  const { year } = useParams()
  const [orders, setOrders] = useState()
  const {configData} = useYearlyStats(orders)



  useEffect(()=>{

      const lastDayPrevYear = new Date(parseInt(year),0,1)
      const firstDayNewYear = new Date(parseInt(year)+1,0)


    const getData =  async () => {
        const d = await DataService.ordersByDateSorted(lastDayPrevYear,firstDayNewYear)
        const data = d.docs.map((doc) => ({...doc.data(),id:doc.id}))
        setOrders(data)
      }
  
  
      getData()
  
      return 

  },[year])



  return (
    <div className='w-full min-h-[100vh] flex flex-col items-center bg-background pb-[25vh] text-black'>
      
    {/* page title */}
    <span className='text-white text-2xl md:text-5xl bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 px-10 py-5 mt-10 border-primary border-2 rounded-2xl text-center mb-10'>{`${year} Sales`}</span>


    <div className='w-full flex flex-col lg:flex-row lg:items-center justify-evenly md:ml-5'>

        <div className='w-full lg:w-[35rem] flex flex-row items-center justify-evenly relative'>
          <div className='bg-gradient-to-r from-gray-800 via-black to-gray-800 w-40 z-10 h-40 lg:mt-0 mt-10 border-4 border-black flex items-center justify-center rounded-full'>
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" className="m-5 p-1 text-green-600" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"/>
           </svg>
          </div>
          <div className='w-full h-full text-right flex flex-col text-white'>
            <div className={`${true ? ``:`hidden`} w-full h-8 flex items-center justify-center whitespace-no-wrap text-center bg-emerald-600 translate-y-5 md:translate-y-0 pl-5 text-sm lg:text-base mt-[5px] -translate-x-7 rounded-full`}>
              {`Sales This Year - N${configData?.netSales}`}
            </div>
          </div>
        </div>

        <div className='w-full lg:w-[35rem] flex flex-row items-center justify-evenly relative'>
          <div className='bg-gradient-to-r from-gray-800 via-black to-gray-800 w-40 z-10 h-40 lg:mt-0 mt-10 border-4 border-black flex items-center justify-center rounded-full'>
            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="currentColor" className="text-red-500 m-5" viewBox="0 0 16 16">
              <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z"/>
              <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z"/>
            </svg>
          </div>
          <div className='w-full h-full text-right flex flex-col text-white'>
            <div className={`${configData?.numOrders > 0 ? ``:`hidden`} w-full h-10 flex items-center justify-center whitespace-no-wrap text-center bg-orange-500 translate-y-5 md:translate-y-0 pl-5 text-sm lg:text-base mb-[5px] -translate-x-7 rounded-full`}>
              {`${configData?.numOrders} order${configData?.numOrders === 1 ? ``:`s`} fulfilled`}
            </div>
            <div className={`${configData?.productsSold > 0 ? ``:`hidden`} w-full h-10 flex items-center justify-center whitespace-no-wrap text-center bg-yellow-500 translate-y-5 md:translate-y-0 pl-5 text-sm lg:text-base mt-[5px] -translate-x-7 rounded-full`}>
              {`${configData?.productsSold} product${configData?.productsSold === 1 ? ``:`s`} sold` }
            </div>
          </div>
        </div>
          


      </div>



    <div className='w-full lg:w-[80%] mt-10 flex flex-col'>
      <div className='w-full h-10 bg-black text-white text-center text-3xl'>{"Monthly Sales"}</div>
        <YearlySalesGraph monthlySales={configData?.monthlySales}/>
    </div>

  </div>
  )
}
