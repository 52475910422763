
import { shippingLocations } from "../../system/settings" 

export const ValidateProductData = (product) => {
    const errors = []

    if(product.name.length <= 0 || product.name.length > 64 ){
        errors.push("Invalid Input in field 'NAME'")
    }
    if(product.summary.length <= 0 || product.summary.length > 1500){
        errors.push("Product Summary can't be less than 0 characters or greater than 1500")
    }
    if(product.category.length <= 0){
        errors.push("Please select a valid category")
    }
    if(product.description.length <= 0 || product.description.length > 5000){
        errors.push("Product Description can't be less than 0 characters or greater than 5000")
    }
    if(product.ingredients.length <= 0 || product.ingredients.length > 5000){
        errors.push("Product Ingredients can't be less than 0 characters or greater than 5000")
    }
    product.options.map((option,index) => {
        if(option.size.length <= 0 || option.size.length > 20){
            errors.push(`Option #${index+1} must have a SIZE name less than 20 characters`)
        }
        if(option.dimensions.length <= 0 || option.dimensions.length > 15){
            errors.push(`Option #${index+1} must have a DIMENSION name less than 15 characters`)
        }
        if(option.price <= 0){
            errors.push(`Option #${index+1} must have a PRICE greater than 0`)
        }
        if(option.weight <= 0){
            errors.push(`Option #${index+1} must have a WEIGHT greater than 0`)
        }
        return null
    })

    const errorString = errors.reduce((final,error)=> final + `\n ${error}`,'')

    return errorString
}

export const Validate32CharString = (string) => {
    const error = []
    if(string.length < 1 || string.length > 32){
        error.push("Input must be a value less than 32 characters")
    } 
    return error
}

export const Validate64CharString = (string) => {
    const error = []
    if(string.length < 1 || string.length > 128){
        error.push("Input must be a value less than 128 characters")
    } 
    return error
}

export const Validate256CharString = (string) => {
    const error = []
    if(string.length < 1 || string.length > 256){
        error.push("Input must be a value less than 256 characters")
    } 
    return error
}

export const ValidateUserData = (user) => {
    const errors = []

    const testPassword = (pwString) => {
        var strength = 0;

     
        strength += /[A-Z]+/.test(pwString) ? 1 : 0;
        strength += /[a-z]+/.test(pwString) ? 1 : 0;
        strength += /[0-9]+/.test(pwString) ? 1 : 0;
        strength += /[\W]+/.test(pwString) ? 1 : 0;
    
        return strength
    }

    if(user.fname.value.length < 1 || user.fname.length > 32){
        errors.push("First Name must be a value less than 32 characters")
    } 
    if(user.lname.value.length < 1 || user.lname.length > 32){
        errors.push("Last Name be a value less than 32 characters")
    } 
    if(user.phone.value.length < 9 || user.phone.value.length > 15){
        errors.push("Please enter a valid phone number")
    }
    if(testPassword(user.password.value) < 3) {
        errors.push("Password is too weak. Passwords should be alphanumeric and have at least one special character.")
    }
    if(user.password.value !== user.passwordRepeat.value){
        errors.push("Passwords don't match.")
    }

    const errorString = errors.reduce((final,error)=> final + `\n ${error}`,'')
    return errorString
}

export const ValidateContactEmail = (contactForm) => {
    const errors = []

    if(contactForm.name.value.length < 1 || contactForm.name.value.length > 64){
        errors.push("Name must be a value less than 64 characters")
    }
    if(contactForm.subject.value.length < 1 || contactForm.name.value.length > 32){
        errors.push("Subject must be a value less than 32 characters")
    }
    if(contactForm.message.value.length < 1 || contactForm.name.value.length > 400){
        errors.push("Message must be a value less than 400 characters")
    }

    const errorString = errors.reduce((final,error)=> final + `\n ${error}`,'')
    return errorString
}

export const ValidateProductReview = (review) => {
    const errors = []
    
    if(review.rating > 5 || review.rating < 0){
        errors.push("Invalid Rating Value")
    }
    if(review.rating > 5 || review.rating < 0){
        errors.push("Invalid Rating Value")
    }
    if(review.name.length === 0 || review.name.length > 50){
        errors.push("Please Input A Valid Name Less Than 50 Characters")
    }
    if(review.review.length === 0 || review.review.length > 1000){
        errors.push("Please Input A Valid Review Less Than 1000 Characters")
    }
    
    const errorString = errors.reduce((final,error)=> final + `\n ${error}`,'')
    return errorString
}

export const ValidateContactData = (contact) => {
  
   const errors = []
    
   if(!contact.fname || !contact.lname || !contact.phone || !contact.email) return "Please Fill In All Necessary Contact Fields"
   if(contact.fname.length <= 0 || contact.fname.length > 32){
    errors.push("Please Input A Valid First Name Less Than 32 Characters")
   }
   if(contact.lname.length <= 0 || contact.lname.length > 32){
    errors.push("Please Input A Valid First Name Less Than 32 Characters")
   }
   if(contact.phone.length < 9 || contact.phone.length > 15){
    errors.push("Please Input A Valid Phone Number")
   }
    
    const errorString = errors.reduce((final,error)=> final + `\n ${error}`,'')
    return errorString

}

export const ValidateLocationData = (location,shipping) => {
  
    var errors = []
    if(!location.address || !location.city || !location.state || !location.country) return "Please Fill In All Necessary Delivery Fields"

    if(location.address.length <= 0 || location.address.length > 64) errors.push("Address must be a value less than 64 characters")
    // if(location.city.length <= 0 || location.city.length > 32) errors.push("City must be a value less than 32 characters")
    // if(location.state.length <= 0 || location.state.length > 32) errors.push("State must be a value less than 32 characters")
    

    const exists = shipping.find((item) => 
        item.city.toLowerCase() === location.city.toLowerCase() && 
        item.state.toLowerCase() === location.state.toLowerCase() &&
        item.country.toLowerCase() === location.country.toLowerCase()
        )

    if(!exists){
        return `Sorry We Do Not Deliver To ${location.city}, ${location.state}. Please view our shipping locations below`
    }
    
    var errorString = errors.reduce((final,error)=> final + `\n ${error}`,'')
    return errorString

}