import React, {useState,useEffect} from 'react'
import { useParams, Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import DataService from '../../../services/providers/data.service'
import {AiFillCloseCircle as Close} from 'react-icons/ai'
import {GrAdd as Add, GrSubtract as Subtract} from 'react-icons/gr'
import { TagComponent } from '../../../components/general-components/TagComponent'
import { ProgressBar } from '../../../components/general-components/ProgressBar'
import {slugify} from '../../../services/utilities/slugify'
import {useStateContext} from '../../../services/contexts/StateContext'
import { ConfirmationModal } from '../../../components/general-components/ConfirmationModal'
import { ValidateProductData } from '../../../services/utilities/errorHandler'



export const ProductsFormPage = () => {
  
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const {currentUser} = useStateContext()
  const {id} = useParams()
  const navigate = useNavigate()
  const [config, setConfig] = useState()
  const [product, setProduct] = useState({
    name:'',
    category:'',
    tags: [],
    image:[],
    rating: 0,
    reviews:[],
    summary:'',
    ingredients: '',
    usage: '',
    description: '',
    created:'',
    options: [
      {
        size: '',
        status: 0,
        discountRate: 0,
        price: 0,
        weight: 0,
        dimensions: '',
      }
    ],
    slug: '',
    updated: '',
    updates: [],
    hidden: false,
  })
  

  const [file, setFile] = useState(null);

  
  const handleFieldChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value.trim(),
    })
  }

  const handleOptionsFieldChange = (e,index) => {
    const {name,value} = e.target
    const list = product.options
    list[index][name] = value
    setProduct({
      ...product,
      options: list,
    })
  }

  const handleOptionStatusChange = (index,value) => {
    const list = product.options
    list[index]['status'] = value
    setProduct({
      ...product,
      options: list,
    })
  }

  const addOption = () => {
    product.options.push({
      size: '',
      status: 0,
      price: 0,
      discountRate: 0,
      weight: 0,
      dimensions: '',
    })
    setProduct({...product})

  }

  const removeOption = (index) => {
    product.options.splice(index,1)
    setProduct({...product})
  }

  const imageHandler = (e) => {
    let selected = e.target.files[0]

    if(selected){
      setFile(selected)
    }else{
      setFile(null)
    }
  }
  
  const removeImage = (index) => {
    const list = product.image
    list.splice(index,1)
    setProduct({...product, image:list})
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try{
      const slug = await slugify(product.name)
      const newProduct = {...product,slug: slug}
      let errors = ValidateProductData(newProduct)
      if(errors.length > 0){
        throw new Error(errors)
      }
      await DataService.addDocument('products',newProduct)
      toast.success(`Product ${product.name} has been successfully created`);
      navigate('/admin/products')
    } catch (err) {
      setOpenConfirmation(false)
      toast.error(err.message)
    }

  }

  const handleUpdate = async (e) => {
    e.preventDefault()
    try{
      const slug = await slugify(product.name)
      product.updates.push({admin:currentUser.id, date: new Date(), action: "Product Update"})
      const newProduct = {...product,slug: slug}
      let errors = ValidateProductData(newProduct)
      if(errors.length > 0){
        throw new Error(errors)
      }
      await DataService.updateDocument('products',id,newProduct)
      toast.success(`Product ${product.name} has been successfully updated`);
      navigate('/admin/products')
    } catch (err) {
      setOpenConfirmation(false)
      toast.error(err.message)
    }

  }

  useEffect(()=>{
    const getConfig = async () => {
      const data = await DataService.getDocument("config","settings")
      setConfig(data.data())
    }

    const getProduct = async () => {
      const data = await DataService.getDocument("products",id)
      setProduct(data.data())
    }

    getConfig()

    if(id){
      getProduct()
    }

  },[id])

  return (
    <div className='w-full min-h-[100vh] flex flex-col items-center bg-background pb-[25vh] text-black'>
      {openConfirmation ? <ConfirmationModal setOpen={setOpenConfirmation} runFunction={id ? handleUpdate : handleSubmit}/>:null}
      
      {/* TITLE AND CLOSE ICON */}

      <span className='text-white text-2xl md:text-5xl bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 px-10 py-5 mt-10 border-primary border-2 rounded-2xl text-center'>{id ? "Product Update Form" : "New Product Form" }</span>


      {/* CREATION DATE TAGS */}
      {(product?.created) &&
        <>
          <span className='my-5 bg-blue-100 text-black rounded-full text-sm text-center sm:text-lg px-5'>{`CREATION DATE :  ${product.created?.toDate().toUTCString()}`}</span>
        </>
      }
      {(product?.updated) &&
        <>
          <span className='my-5 bg-blue-100 text-black rounded-full text-sm text-center sm:text-lg px-5'>{`LAST UPDATED :  ${product.updated?.toDate().toUTCString()}`}</span>
        </>
      }

      {/* PRODUCT DATA FIELDS */}

      {/* NAME */}
      <span className='text-2xl sm:text-5xl text-white bg-gradient-to-b from-gray-600 via-gray-800 to-black px-10 py-3 my-10'>{"Product Name"}</span>
      <input type='text' name='name' required
        defaultValue = {product.name}
        onChange={handleFieldChange}
        className = 'w-64 md:w-80 rounded-lg text-center'
      />


      {/* CATEGORY */}
      <span className='text-2xl sm:text-5xl text-white bg-gradient-to-b from-gray-600 via-gray-800 to-black px-10 py-3 my-10'>{"Product Category"}</span>
      <div className='text-sm mb-2 flex justify-center items-center'>
        <select
        onChange = {(e) => {setProduct({...product,category:e.target.value})}}
        className='block text-sm mb-2 w-[200%]'
        required>
          <option value={null}>{"---------"}</option>
          {config?.categories.map((c,i)=>{
            return <option key={i} value={c} selected={product.category === c}>{c}</option>
          })}
        </select>
      </div>

      <span className='text-2xl sm:text-5xl text-white bg-gradient-to-b from-gray-600 via-gray-800 to-black px-10 py-3 my-10'>{"Status In Store"}</span>
      <div className='w-full flex flex-col sm:flex-row justify-center items-center text-xl text-white'>
        <label>
            <input name='status_radio' type='radio' className='hidden' />
            <div className={ `w-40 h-20 border-black border-2 flex items-center justify-center cursor-pointer ${!product.hidden ? `bg-gradient-to-t from-green-300 via-green-400 to-secondary`:`bg-slate-300 hover:bg-gradient-to-t hover:from-green-300 hover:via-green-400 hover:to-secondary`}`} 
              onClick={()=> setProduct({...product,hidden:false})}
              >Live</div>
        </label>
        <label>
            <input name='status_radio' type='radio' className='hidden'/>
            <div className={ `w-40 h-20 border-black border-2 flex items-center justify-center cursor-pointer ${product.hidden ? `bg-gradient-to-t from-red-300 via-red-400 to-accent-secondary`:`bg-slate-300 hover:bg-gradient-to-t hover:from-red-300 hover:via-red-400 hover:to-accent-secondary`}`} 
            onClick={()=> setProduct({...product,hidden:true})}
            >Hidden</div>
        </label>
      </div>

      {/* PRODUCT OPTIONS */}
      <span className='text-2xl sm:text-5xl text-white bg-gradient-to-b from-gray-600 via-gray-800 to-black px-10 py-3 my-10'>{"Product Options"}</span>
      {product?.options.map((option,i)=>(
            <div key={i} className=' w-[50%] flex-col flex items-center justify-center border-t-4 border-black'>
              <div className='flex justify-center items-center px-5 py-2 text-white bg-gradient-to-b from-gray-600 via-gray-800 to-black my-5 text-2xl '>
                {`OPTION #${i+1}`}
              </div>

              <div className='flex flex-col sm:flex-row items-center'>


              <div className='text-black pt-5 pl-5'>
                <label className='block text-sm mb-2'>Size</label>
                <input type='text' name='size' className='text-sm rounded-lg w-[80%] p-2 border-2 border-black text-black'
                defaultValue={option.size}
                onChange={(e) => handleOptionsFieldChange(e,i)}
                required/>
              </div>

              <div className='text-black pt-5 pl-5'>
                <label className='block text-sm mb-2'>Price</label>
                <input type='number' name='price' className='text-sm rounded-lg w-[80%] p-2 border-2 border-black text-black'
                // defaultValue={product.options[i].price}
                placeholder={option.price}
                onChange={(e) => handleOptionsFieldChange(e,i)}
                required/>
              </div>

              <div className='text-black pt-5 pl-5'>
                <label className='block text-sm mb-2'>Weight (grams)</label>
                <input type='number' name='weight' className='text-sm rounded-lg w-[80%] p-2 border-2 border-black text-black' 
                // defaultValue={option.weight}
                placeholder={option.weight}
                onChange={(e) => handleOptionsFieldChange(e,i)}
                required/>
              </div>

              <div className='text-black pt-5 pl-5'>
                <label className='block text-sm mb-2'>Dimenstions</label>
                <input type='text' name='dimensions' className='text-sm rounded-lg w-[80%] p-2 border-2 border-black text-black' 
                // defaultValue = {item ? `${item.dimensions}` : "" }
                defaultValue={option.dimensions}
                onChange={(e) => handleOptionsFieldChange(e,i)}
                required/>
              </div>

              <div className='text-black pt-5 pl-5'>
                <label className='block text-sm mb-2'>Discount (%)</label>
                <input type='text' name='discountRate' className='text-sm rounded-lg w-[80%] p-2 border-2 border-black text-black' 
                // defaultValue = {item ? `${item.dimensions}` : "" }
                placeholder={option.discountRate}
                onChange={(e) => handleOptionsFieldChange(e,i)}
                required/>
              </div>

            </div>

              {/* RADIO BUTTONS */}
          <div className='w-full h-20 flex justify-center items-center text-lg my-5 text-black '>
              AVAILABILITY
          </div>
          <div className='w-full flex flex-col sm:flex-row justify-center items-center text-xl text-white'>
            <label>
                <input name='status_radio' type='radio' className='hidden' />
                <div className={ `w-40 h-20 border-black border-2 flex items-center justify-center cursor-pointer ${option.status === 0 ? `bg-gradient-to-t from-red-300 via-red-400 to-accent-secondary`:`bg-slate-300 hover:bg-gradient-to-t hover:from-red-300 hover:via-red-400 hover:to-accent-secondary`}`} 
                  onClick={()=> handleOptionStatusChange(i,0)}
                  >Out Of Stock</div>
            </label>
            <label>
                <input name='status_radio' type='radio' className='hidden'/>
                <div className={ `w-40 h-20 border-black border-2 flex items-center justify-center cursor-pointer ${option.status === 1 ? `bg-gradient-to-t from-yellow-300 via-yellow-400 to-yellow-500`:`bg-slate-300 hover:bg-gradient-to-t hover:from-yellow-300 hover:via-yellow-400 hover:to-yellow-500`}`} 
                onClick={()=> handleOptionStatusChange(i,1)}
                >Low Stock</div>
            </label>
            <label>
                <input name='status_radio' type='radio' className='hidden' />
                <div className={ `w-40 h-20 border-black border-2 flex items-center justify-center cursor-pointer ${option.status === 2 ? `bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900`:`bg-slate-300 hover:bg-gradient-to-b hover:from-secondary hover:via-emerald-800 hover:to-emerald-900`}`} 
                onClick={()=> handleOptionStatusChange(i,2)}
                >In Stock</div>
            </label>

          </div>

              <div className='w-full h-24 my-5 flex justify-evenly items-center'>
                
                {product.options.length -1 === i && product.options.length < 4 &&
                  <Add size={60} className='bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 rounded-full p-1 cursor-pointer'
                  onClick={()=> addOption()}
                  />
                }
                {   
                  product.options.length -1 === i && product.options.length > 1  &&
                  <Subtract size={60} className='bg-gradient-to-t from-red-300 via-red-400 to-accent-secondary rounded-full p-1 cursor-pointer'
                  onClick={()=> removeOption(i)} />
                }

              </div>


            </div>
          ))}

      {/* PRODUCT TAGS */}

      <div className='px-5 py-2 text-white bg-gradient-to-b from-gray-600 via-gray-800 to-black my-5 text-2xl '>
        {"TAG SELECTOR"}
      </div>
      <div className='pt-5 border w-[75%] lg:w-[50%] flex flex-col items-center justify-center'>
        {config?.tags.map((tag,i)=>{
          return <TagComponent tag={tag} tags={config?.tags} key={i} product={product} setProduct={setProduct}/>
        })}
      </div>

      
      
      {/* PRODUCT IMAGES */}

      <div className='px-5 py-2 text-white bg-gradient-to-b from-gray-600 via-gray-800 to-black my-5 text-2xl '>
        {"IMAGE UPLOAD"}
      </div>
      <form>
        <input disabled={product.image.length < 5 ? false:true} className="my-5 ml-20 w-[50%]" type="file" 
        onChange={imageHandler}
        />
        { file && <ProgressBar file={file} setFile={setFile} product={product} setProduct={setProduct}/> }
      </form>

        {product.image.length > 0 ? 
          <div className='w-[75%] md:w-[50%] border-2 inline-grid grid-cols-5 gap-1'>
            {product.image.map((url,i) => {
              return (
                <div key={i} className='relative flex items-center justify-center'>
                  <img src={url} width='100px' height={'100px'} alt={''}/>
                  <Close 
                  onClick={()=>removeImage(i)}
                  className='text-black text-opacity-50 w-10 h-10 absolute cursor-pointer hover:text-gray-800 hover:text-opacity-75'/>
                </div>
            )
          })}
          </div>
          :
          <div className='w-full flex justify-center items-center text-center'>NO IMAGES HAVE BEEN UPLOADED</div>
        }
      
      
      
      {/* PRODUCT INFORMATION */}

      <div className='px-5 py-2 text-white bg-gradient-to-b from-gray-600 via-gray-800 to-black my-5 text-2xl '>
        {"PRODUCT SUMMARY"}
      </div>
      <textarea type='text' name='summary' rows={10} className='w-[75%] text-sm rounded-lg p-2 pb-40 border-2 border-black overflow-y-scroll' 
        defaultValue = {product.summary}
        onChange={handleFieldChange}
      required/>




      <div className='px-5 py-2 text-white bg-gradient-to-b from-gray-600 via-gray-800 to-black my-5 text-2xl '>
        {"PRODUCT DESCRIPTION"}
      </div>
      <textarea type='text' name='description' rows={10} className='text-sm rounded-lg w-[75%] p-2 pb-40 border-2 border-black overflow-y-scroll' 
        defaultValue = {product.description}
        onChange={handleFieldChange}
      required/>

      <div className='px-5 py-2 text-white bg-gradient-to-b from-gray-600 via-gray-800 to-black my-5 text-2xl '>
        {"PRODUCT USAGE"}
      </div>
      <textarea type='text' name='usage' rows={10} className='text-sm rounded-lg w-[75%] p-2 pb-40 border-2 border-black overflow-y-scroll' 
        defaultValue = {product.usage}
        onChange={handleFieldChange}
      required/>

      <div className='px-5 py-2 text-white bg-gradient-to-b from-gray-600 via-gray-800 to-black my-5 text-2xl '>
        {"PRODUCT INGREDIENTS"}
      </div>
      <textarea type='text' name='ingredients' rows={10} className='text-sm rounded-lg w-[75%] p-2 pb-40 border-2 border-black overflow-y-scroll' 
        defaultValue = {product.ingredients}
        onChange={handleFieldChange}
      required/>


      {/* FOOTER BUTTONS */}

      <div className="w-full flex items-center justify-evenly p-4 py-40">
        <Link to="/admin/products">
          <button
            className="bg-gradient-to-t from-red-300 via-red-400 to-accent-secondary text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            // onClick={() => setOpen(false)}
          >
            CANCEL
          </button>
        </Link>
        <button
          className={"bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"}
          type="button"
          onClick={()=> setOpenConfirmation((open)=>!open)}
          // disabled={confirmed}
        >
          { id ? "UPDATE" : "SAVE" }
        </button>
      </div>

    </div>
  )
}
