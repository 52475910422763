import React from 'react'
import {RiErrorWarningFill} from 'react-icons/ri'

export const DeleteModal = ({setOpen,runFunction}) => {
  return (
    <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-2xl h-[50%]">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 rounded-t">
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setOpen((open)=>!open)}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block">
                        X
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex flex-col items-center">
                    <h1 className='text-red-600'>
                      <RiErrorWarningFill size={100}/>
                    </h1>
                    <p className="my-4 text-slate-500 text-lg text-center leading-relaxed">
                        ARE YOU SURE YOU WANT TO DELETE THIS ITEM?
                    </p>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="bg-gray-600 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setOpen((open)=>!open)}
                    >
                      CANCEL
                    </button>
                    <button
                      className="bg-red-600 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={(e)=>runFunction(e)}
                    >
                      YES, I AM SURE
                    </button>
                  </div>
                </div>
              </div>
              </div>
            <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
          </>
  )
}
