import React,{useState,useEffect} from 'react'
import { useStateContext } from '../../services/contexts/StateContext'
import DataService from '../../services/providers/data.service'
import { shippingLocations } from '../../system/settings'
import {toast} from 'react-toastify'
import { ValidateLocationData, ValidateContactData } from '../../services/utilities/errorHandler'


export const UserProfilePage = () => {

  const {currentUser} = useStateContext()
  const [userData, setUserData] = useState(null)
  const [country, setCountry] = useState()


  useEffect(()=>{

    const getData = async () => {
      const data = await DataService.getDocument("users",currentUser.id)
      setUserData(data.data())
      setCountry(data.data().defaultAddress.country)
    }

    getData()


  },[currentUser])

  const contactInputHandler = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value.trim(),
    })
  }

  const deliveryInputHandler = (e) => {
    setUserData({
      ...userData,
      defaultAddress:{
        ...userData.defaultAddress,
        country: country,
        [e.target.name]: e.target.value.trim(),
      }
    })
  }

  const saveChanges = async (e) => {

    e.preventDefault()
    try{
      console.log(userData)
      if(!userData?.emailVerified) throw new Error("Please Verify Your Email. Check Your Email Or Contact Support")
      if(!userData?.status) throw new Error("Your Account Has Been Suspended. Please Contact Support For Details")

        let locErrors = ValidateLocationData({
          address: userData.defaultAddress.address,
          city: userData.defaultAddress.city,
          state: userData.defaultAddress.state,
          country: country,
        })

        let conErrors = ValidateContactData({
          fname: userData?.fname,
          lname: userData?.lname,
          phone: userData?.phone,
          email: userData?.email,
        })

        console.log(userData)
        if(locErrors.length > 0 || conErrors.length > 0){
            throw new Error(`${locErrors}\n\n${conErrors}`)
        }

      await DataService.updateUserDocument(currentUser.id,userData)
      .then(()=>{
        toast.success("Your Information Has Been Updated!")
      })
    }catch(error){
      toast.error(error.message)
    }
  }

  return (

    <div className='w-full min-h-screen flex flex-col items-center justify-center pb-[40vh]'>

      <div className='grid grid-cols-2 w-full lg:w-[75%] h-[40rem] lg:h-[45rem] mt-10'>
                      

        <form onSubmit={saveChanges} className='col-span-2 grid grid-cols-4 text-white text-xl md:text-2xl lg:text-3xl h-full'>
            
            <div className='col-span-4 h-10 bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white flex justify-center items-center text-xl md:text-2xl lg:text-3xl'>{"Contact Information"}</div>
            <input type='text' placeholder={userData?.fname} name='customerFName' className='col-span-2 border-2 rounded-lg h-10 text-black' onChange={contactInputHandler}/>
            <input type='text' placeholder={userData?.lname} name='customerLName' className='col-span-2 w-full border-2 rounded-lg h-10 text-black' onChange={contactInputHandler}/>
            <input type='email' placeholder={userData?.email} name='customerEmail' className='col-span-4 w-full border-2 rounded-lg h-10 pl-5 text-black' onChange={contactInputHandler}/>
            <input type='number' placeholder={userData?.phone} name='customerPhone' className='col-span-4 w-full border-2 rounded-lg h-10 pl-5 text-black' onChange={contactInputHandler}/>

            <div className='col-span-4 h-10 bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white flex justify-center items-center text-xl md:text-2xl lg:text-3xl'>{"Delivery Information"}</div>
            <input type='text' placeholder={userData?.defaultAddress.address} name='address' className='col-span-4 border-2 rounded-lg h-10 text-black' onChange={deliveryInputHandler}/>
            <input type='text' placeholder={userData?.defaultAddress.address_2} name='address_2' className='col-span-4 w-full border-2 rounded-lg h-10 text-black' onChange={deliveryInputHandler}/>
            <input type='text' placeholder={userData?.defaultAddress.city} name='city' className='col-span-2 w-full border-2 rounded-lg h-10 text-black' onChange={deliveryInputHandler}/>
            <input type='text' placeholder={userData?.defaultAddress.state} name='state' className='col-span-2 w-full border-2 rounded-lg h-10 text-black' onChange={deliveryInputHandler}/>
            <input type='text' placeholder={userData?.defaultAddress.postal_code} name='postal_code' className='col-span-2 w-full border-2 rounded-lg h-10 text-black' onChange={deliveryInputHandler}/>
            <select
            onChange = {(e) => {setCountry(e.target.value)}}
            className='col-span-2 w-full border-2 rounded-lg h-10 text-black'
            name='country'
            required>
            <option value={null}>{"---------"}</option>
            {shippingLocations.map((location) => location.country).map((c,i)=>{
                return <option key={i} value={c} selected={userData?.defaultAddress.country === c}>{c}</option>
            })}
            </select>
            <button type='submit' className="col-span-2 h-[50%] text-xl col-start-2 tonedButton bg-gradient-to-b from-green-400 via-green-600 to-green-800 mt-10"
            >
            SAVE CHANGES
            </button>

        </form>
      </div>

    </div>
  ) 
}
