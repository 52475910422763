import React, { useState,useEffect } from 'react'
import { FaStar } from 'react-icons/fa'

export const StarRating = ({size,value,disabled,handler}) => {

  const [rating, setRating] = useState(value ? value:null)
  const [hover, setHover] = useState(value ? value:null)
  
  const handleRating = (value) => {
    setRating(value)
    handler(value)
  }

  useEffect(()=>{
    setRating(value ? value:null)
  },[value])

  return (
    <div className='flex'>
        {[...Array(5)].map((star,index)=>{
            const ratingVal = index + 1
            return (
                <label key={index}>
                    <input type='radio' className='hidden' value={ratingVal} disabled={disabled}  onClick={()=> handleRating(ratingVal)}/>
                    <FaStar size={size} color={(hover || rating) >= ratingVal ? "#ffc107":"#e4e5e9"} onMouseEnter={disabled ? null: ()=> setHover(ratingVal)} onMouseLeave={disabled ? null: ()=> setHover(null)} className='cursor-pointer transition-color duration-150'/>
                </label>
            )
        })}
    </div>
  )
}

