import React, {useState,useEffect} from 'react'


export const TagComponent = ({tag,tags,product,setProduct}) => {

  const [selected,setSelected] = useState((product?.tags.includes(tag)) ? true:false)

  const handler = () =>{

    if(selected){
      setProduct({
        ...product,
        tags: product?.tags.filter((ele) => {
          return tags.includes(ele) && ele !== tag
        })
      })
    }else{
      product?.tags.push(tag)
      setProduct(product)
    }
    setSelected(!selected)

    cleanUp()
  }

  useEffect(()=>{
    setSelected((product?.tags.includes(tag)) ? true:false)
  },[product,tag])

  //remove deleted tags that have persisted
  const cleanUp = () => {
    if(!tags.includes(tag)){
          return ({
            ...product,
            tags: product?.tags.filter((ele) => {
              return tags.includes(ele)
            })
          }) 
        } 
  }

  return (
    <label className='w-full flex items-center justify-center'>
      <input type='radio' value={selected} className='hidden' onClick={()=> handler()}/>
      <div className={`${selected ? `bg-gradient-to-t from-accent-primary via-cyan-400 to-accent-primary text-white border-accent-primary border-2 w-[90%]`:`bg-gray-400 hover:bg-accent-primary hover:text-white text-black w-[70%] hover:scale-[130%]`} rounded-3xl text-center mb-2 cursor-pointer`}>{tag}</div>
    </label>
  )
}

