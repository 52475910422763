import React,{useState,useEffect} from 'react'
import { useStateContext } from '../../services/contexts/StateContext'
import DataService from '../../services/providers/data.service'
import {UsersOrder} from '../../components/user-components/UsersOrder'

export const UserOrdersPage = () => {

    const [data, setData] = useState()
    const {currentUser} = useStateContext()


    useEffect(()=>{

        const getData = async () => {
            const data = await DataService.getOrderByAttr("orders","userUID",currentUser?.id)
            setData(data.docs.map((item) => {return item.data() }))
        }

        getData()

    },[currentUser])

    return (
    <div className='w-full flex flex-col items-center pb-[40rem]'>
        <div className='w-full md:w-[80vw]'>
        <div className='w-full h-10 grid grid-cols-3 bg-gradient-to-b from-gray-600 via-gray-800 to-black border-b-2 border-gray-700 my-5'>
            <div className='flex items-center justify-center text-center border-r-2 border-gray-700'>{"Order Date"}</div>
            <div className={'flex items-center justify-center text-center border-r-2 border-gray-700'}>{"Order Status"}</div>
            <div className='flex items-center justify-center text-center'>{"Order Total"}</div>
        </div>
          {data?.map((order,i) => <UsersOrder key={i} order={order}/>)}
        </div>
    </div>
  )
}
