import React,{useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import {toast} from 'react-toastify'
import DataService from '../../../services/providers/data.service'
import { AdminUserOrdersComponent } from '../../../components/admin-components/AdminUserOrdersComponent'
import { CustomEmailModal } from '../../../components/admin-components/CustomEmailModal'
import { ConfirmationModal } from '../../../components/general-components/ConfirmationModal'
import { useStateContext } from '../../../services/contexts/StateContext'
import { sendCustomEmail, generateEmailVerification, generatePasswordReset } from '../../../services/serverRequest'


export const SingleUserPage = () => {

    const [item,setItem] = useState(null)
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [lock, setLock] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const {id} = useParams()
    const {currentUser} = useStateContext() 

  
    const date = item?.created?.toDate().toUTCString()
    
    const [orders,setOrders] = useState()

    const changeStatus = async (e) => {
        e.preventDefault()
        setLock((value) => !value)
        const user = {
        ...item,
        status: !item?.status,
        updates: [...item?.updates,{
            admin: currentUser.id,
            date: new Date(),
            action: item.status ? "Suspending User" : "Activating User"
        }],
        }

        try{
        await DataService.updateDocument('users',id,user)
        } catch (err) {
        console.log(err)
        } finally{
        setLoading(true)
        setOpenConfirmation(false)
        toast.success(`User has been sucessfully ${item?.status ? `SUSPENDED`:`ACTIVATED`}`);
        setLock((value) => !value)
        }


    }

    const sendPasswordReset = async (e) => {
        e.preventDefault()
        setLock((value) => !value)
        try{
            await generatePasswordReset(item?.email)
            .then((message) => {
                // console.log(message)
            })
            } catch (err) {
            console.log(err)
            }finally{
                toast.info(`Password Reset Email Has Been Succesfully Sent`);
                setLock((value) => !value)
            }
    }

    const sendCustomMail = async (e,subject,message) => {
        e.preventDefault()
        setLock((value) => !value)
        try{
            await sendCustomEmail(item?.email,subject,message)
            .then((message) => {
                setOpen(false)
            })
            } catch (err) {
            console.log(err)
            }finally{
                toast.info(`Custom Email Has Been Succesfully Sent`);
                setLock((value) => !value)
            }
    }

    const sendVerificationEmail = async (e) => {
        e.preventDefault()
        setLock((value) => !value)
        try{
            await generateEmailVerification(item?.email,item?.id)
            .then((message) => {
                // console.log(message)
            })
            } catch (err) {
            console.log(err)
            } finally{
                toast.info(`Verification Email Has Been Succesfully Sent`);
                setLock((value) => !value)
            }
    }
  
    useEffect(()=>{
      
      const getUser = async () => {
          const data = await DataService.getDocument("users",id)
          setItem(data.data())
      }
  
      const getData = async () => {
          const data = await DataService.ordersByAttrSorted('orders',"userUID",id)
          setOrders(data.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id
          })))
      }
      
      if(loading){
        getUser()
        getData()
        setLoading(false)
      }

    },[id,loading])


  return (
    <div className='min-h-[100vh] w-full flex flex-col items-center bg-background pb-[25vh]'>
      {open ? <CustomEmailModal setOpen={setOpen} runFunction={sendCustomMail}/>:null}
      {openConfirmation ? <ConfirmationModal setOpen={setOpenConfirmation} runFunction={changeStatus}/>:null}
      {/* page title */}
      <span className='text-white text-center text-xl md:text-3xl bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 px-2 py-2 mt-10 border-primary border-2 rounded-2xl'>{`USER# ${id}`}</span>
      <span className='mt-10 bg-blue-100 text-center text-black rounded-full text-lg px-5'>{`ORDER DATE :  ${date}`}</span>

    {/* CUSTOMER INFO SECTION */}
    <div className='w-full lg:max-w-[75vw] flex flex-col justify-between mt-10 items-center'>

    <div className='grid grid-col-4 w-full bg-gradient-to-t from-gray-700 via-gray-900 to-black text-base lg:text-2xl mb-10'>
        <div className='col-span-4 flex items-center justify-center text-center h-10 border'>{"USER INFO"}</div>
        <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"First Name :"}</div>
        <div className='col-span-3 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.fname}`}</div>
        <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Last Name :"}</div>
        <div className='col-span-3 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.lname}`}</div>
    </div>

    <div className='grid grid-col-4 w-full bg-gradient-to-t from-gray-700 via-gray-900 to-black text-base lg:text-2xl mb-10'>
        <div className='col-span-4 flex items-center justify-center text-center h-10 border'>{"CONTACT INFO"}</div>
        <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Phone :"}</div>
        <div className='col-span-3 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.phone}`}</div>
        <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Email :"}</div>
        <div className='col-span-3 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.email}`}</div>
    </div>

    <div className='h-full w-full lg:w-[50vw] mb-10 flex justify-center'>
        <div className='flex-col flex items-center'>

        <span className={`px-10 py-5 text-3xl mt-10 ${item?.status ? `bg-green-500 text-white`:`bg-red-500 border-4 border-white text-white`}`}>{item?.status ? "ACTIVE":"SUSPENDED"}</span>
        
        
        <button  className='w-[80%] h-10 mt-5 bg-gradient-to-b from-red-400 via-red-600 to-red-800 shadow-md hover:shadow-inner shadow-black text-white rounded-full'
        onClick={()=> setOpenConfirmation((open)=>!open)}
        disabled={lock}
        >{"CHANGE STATUS"}</button>

        
        <button  className={'px-5 h-10 mt-5 bg-gradient-to-b from-cyan-400 via-cyan-600 to-cyan-800 shadow-md hover:shadow-inner shadow-black text-white rounded-full'}
        onClick={(e)=> sendVerificationEmail(e)}
        disabled={lock}
        >{"SEND VERIFICATION EMAIL"}</button>

        <button  className={'px-5 h-10 mt-5 bg-gradient-to-b from-cyan-400 via-cyan-600 to-cyan-800 shadow-md hover:shadow-inner shadow-black text-white rounded-full'}
        onClick={(e)=> sendPasswordReset(e)}
        disabled={lock}
        >{"SEND PASSWORD RESET EMAIL"}</button>

        <button  className={'px-5 h-10 mt-5 bg-gradient-to-b from-cyan-400 via-cyan-600 to-cyan-800 shadow-md hover:shadow-inner shadow-black text-white rounded-full'}
        onClick={()=> setOpen(true)}
        disabled={lock}
        >{"SEND CUSTOM EMAIL"}</button>
        </div>
    </div>

    <div className='grid grid-col-4 w-full bg-gradient-to-t from-gray-700 via-gray-900 to-black text-xl lg:text-2xl'>
        <div className='col-span-4 flex items-center justify-center text-center h-10 border'>{"DELIVERY DETAILS"}</div>
        <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Street :"}</div>
        <div className='col-span-3 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.defaultAddress?.address}`}</div>
        <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Street 2:"}</div>
        <div className='col-span-3 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.defaultAddress?.address_2}`}</div>
        <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"City :"}</div>
        <div className='col-span-3 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.defaultAddress?.city}`}</div>
        <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"State :"}</div>
        <div className='col-span-3 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.defaultAddress?.city}`}</div>
        <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Postal Code :"}</div>
        <div className='col-span-3 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.defaultAddress?.postal_code}`}</div>
        <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Country :"}</div>
        <div className='col-span-3 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.defaultAddress?.country}`}</div>
    </div>

    </div>

        {/* ORDER ITEMS */}
        {/* <span className='mt-10 bg-black text-white text-2xl px-5 py-2 mb-10'>{"ALL ORDERS"}</span> */}

        {orders?.length > 0 ? 
        <>
        <div className='flex justify-start w-[80%] text-xl mt-10 '>
            <span className='mb-5 bg-gradient-to-t from-gray-700 via-gray-900 to-black text-white px-5 py-2'>{`SHOWING ORDERS  0 - ${orders?.length}`}</span>
        </div>



        <div className='h-12 w-full lg:w-[80vw] grid grid-flow-row-dense grid-cols-10 bg-white text-black text-xs md:text-base lg:text-lg border-t-2'>
            <div className='col-span-4 border-b border-x-2 flex items-center justify-center'>{"Purchase Date"}</div>
            <div className='col-span-3 border-b border-r-2 flex items-center justify-center'>{"Order ID"}</div>
            <div className='col-span-2 border-b border-r-2 flex items-center justify-center'>{"Status"}</div>
            <div className='col-span-1 border-b border-r-2 flex items-center justify-center'>{""}</div>
        </div>
        {orders.map((order,index) => (
            <AdminUserOrdersComponent order={order} key={index}/>
        ))}


        </>
        :
        <div className='h-12 w-full lg:w-[80vw] grid grid-flow-row-dense grid-cols-10 bg-white text-black text-xs md:text-base lg:text-lg border-t-2 mt-10'>
            <div className='col-span-10 border-b border-x-2 flex items-center text-center justify-center'>{"USER HAS NO ORDERS"}</div>
        </div>
        }
    </div>
  )
}
