import React,{useState,useEffect} from 'react'
import {toast} from 'react-toastify'
import { useSearchParams, useNavigate } from 'react-router-dom';
import { verifyPayment } from '../../services/serverRequest'
import { useStateContext } from '../../services/contexts/StateContext';
import success from '../../assets/paymentsuccess.gif'

export const OrderSuccessPage = () => {

  const [loading, setLoading] = useState(true)
  const [reference] = useSearchParams()
  const navigate = useNavigate();
  const {currentUser,reset} = useStateContext();
  const ref = reference.get('reference')
  
  
  useEffect(() => {
    const verify = async () => {
      
      try{
        const confirmation =  await verifyPayment(ref,currentUser)
        navigate(`/order-receipt/${confirmation.reference}`)
        reset()
        toast.success("Your Order Has Been Succesfully Placed")
      }catch(error){
        console.log(error)
      }
    }

    if(loading){
      verify()
      setLoading(!loading)
    }
  },[currentUser,ref,navigate,reset,loading])
  
  return (
    <div className=' h-[100vh] w-full text-center flex flex-col justify-center items-center text-2xl text-black bg-white'>
        <img src={success} alt='empty cart' className='w-[20rem] h-[20rem] bg-inherit mb-10'/>
        THANK YOU FOR YOUR PURCHASE!! :) 
        WAIT TO BE REDIRECTED
        OR CLICK
        <p className='w-full text-cyan-700 underline hover:text-cyan-500 cursor-pointer'
        onClick={()=> setLoading(true)}
        >
        HERE
        </p>
    </div>
  )
}
 