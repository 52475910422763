import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom'
import {BsFillArrowUpCircleFill as Plus, BsFillArrowDownCircleFill as Minus} from 'react-icons/bs'

export const OrderDropDown = ({product}) => {
  
    const [open,setOpen] = useState(true)
    const navigate = useNavigate()

    return ( 
    <>
    <div className='w-full lg:w-[80vw] grid grid-flow-row-dense grid-cols-8 bg-white text-black text-xs md:text-base lg:text-lg border-t-2'>
        <img src={product.image} alt={'img'} className='col-span-1 border-b border-x-2 flex items-center justify-center'/>
        <div className='col-span-3 border-b border-x-2 flex items-center justify-center text-center'>{product.name}</div>
        <div className='col-span-2 border-b border-r-2 flex items-center justify-center'>{product.size}</div>
        <div className='col-span-1 border-b border-r-2 flex items-center justify-center'>x{product.totalSold}</div>
        <div className='col-span-1 border-b border-r-2 flex items-center justify-center cursor-pointer text-2xl md:text-3xl' onClick={()=>setOpen(!open)}>{open ?  <Plus/>:<Minus/>}</div>
    </div>

    {open ? null :
    <div className='w-full lg:w-[80vw] grid grid-flow-row-dense grid-cols-2 bg-secondary text-white text-xs md:text-base lg:text-lg border-t-2'>
        <div className='col-span-1 border-b border-r-2 flex justify-center items-center'>{"Order ID"}</div>
        <div className='col-span-1 border-b border-r-2 flex justify-center items-center'>{"Qty Purchased"}</div>
    </div>
    }
    
    {open ? null :
    product?.orders?.map((item,i)=>{
        return <div key={i} className='w-full flex flex-col items-center text-center'>
            <div className='w-full lg:w-[80vw] grid grid-flow-row-dense grid-cols-2 bg-white text-black text-xs md:text-base lg:text-lg border-t-2'>
                <div className='col-span-1 border-b border-r-2 flex justify-center items-center cursor-pointer hover:underline hover:text-secondary' onClick={()=>item?.orderID ? navigate(`/admin/orders/${item?.orderID}`) : null}>{item?.orderID}</div>
                <div className='col-span-1 border-b border-r-2 flex justify-center items-center'>x{item?.qtySold}</div>
            </div>
        </div>
    })
    }
    </>
  )
}
