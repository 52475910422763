import {useState, useEffect} from 'react'


const useTestimonials = (products) => {

    const [configData, setConfigData] = useState()

    

    useEffect(()=> {
        
    const generateProductTestimonials = () => {
        const array = []
        products.map(product => {
            var name = product.name
            var images = product.image
            var revs = product.reviews
            revs.map( review => array.push({...review,productName:name,productImage: images}))
            return array
        })
        // randomize array here
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        //get only 10
       return array.slice(0,10)
    }
    
    setConfigData(generateProductTestimonials())

    },[products])

    return configData
}

export default useTestimonials;