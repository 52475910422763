import { auth } from '../firebaseConfig'
import {toast} from 'react-toastify'
import {
    createUserWithEmailAndPassword,
    signOut, signInWithEmailAndPassword,
    onAuthStateChanged, updatePassword,
    GoogleAuthProvider, signInWithPopup,
    deleteUser,

} from 'firebase/auth'
import {serverTimestamp} from 'firebase/firestore'
import DataService from './data.service'
import {generateEmailVerification, createUser, createSigninUser } from '../serverRequest'



class AuthService {

    // createAdmin = async (form) => {
    //     //create store data
    //     //create user
    //     //sign out user
    //     //set as admin
    // }

    createUser = async (form) => {

        const email = form.current.email.value
        const password = form.current.password.value
        const userData = {
            fname: form.current.fname.value,
            lname: form.current.lname.value,
            email: form.current.email.value,
            phone: form.current.phone.value,
            created: serverTimestamp(),
            lastUpdate: serverTimestamp(),
            emailVerified: false,
        }

        return createUserWithEmailAndPassword(auth,email,password)
        .then(async (cred) => {
            await createUser(cred.user.uid,userData)
            .then(({message})=>{
                toast.success(message)
                toast.success("Thanks for signing up! Please check your email to verify your account.")
              })
            .catch(err => toast.error(err))
            signOut(auth)
            generateEmailVerification(email,cred.user.uid)
            .then((message) => console.log(message))
            .catch((err) => toast.error(err))
        })
        .catch((err)=>{
            toast.error(err.code)
        })
    }

    logout = async () => {
        return signOut(auth)
        .then((cred)=>{
            toast("You're Logged Out",{
                position: toast.POSITION.BOTTOM_LEFT
            })
        })
        .catch((err)=>{
            toast.error(err.code,{
                position: toast.POSITION.BOTTOM_LEFT
            })
        })
    }

    loginWithMail = async (credentials) => {
        const email = credentials.current.email.value
        const password = credentials.current.password.value
        return signInWithEmailAndPassword(auth, email, password)
        .then((cred)=>{
            toast("You're Logged In",{
                position: toast.POSITION.BOTTOM_LEFT
            })
        })
        .catch((err)=>{
            toast.error(err.code,{
                position: toast.POSITION.BOTTOM_LEFT
            })
        })
    }

    loginWithGoogle = async () => {
        const provider = new GoogleAuthProvider()
        console.log(provider)
        return await signInWithPopup(auth, provider)
            .then(async (result) => {
                // const credentials = GoogleAuthProvider.credentialFromResult(result)
                // const token = credentials.accessToken;
                const user = result.user

                await createSigninUser(user)
                .then((cred)=>{
                    toast("You're Logged In",{
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                })
                .catch((err)=>{
                    toast.error(err.code,{
                        position: toast.POSITION.BOTTOM_LEFT
                    })
                })
                
            })
            .catch((error) => {
                // Handle Errors here.
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // // The email of the user's account used.
                // const email = error.customData.email;
                // // The AuthCredential type that was used.
                // const credential = GoogleAuthProvider.credentialFromError(error);
                console.log(error)
            })
        
    }

    deleteUser = () => {

        deleteUser(auth.currentUser)
        .then(()=>{

            toast.success("Your Account Has Been Successfully Deleted :(")
        })
        .catch((err) => toast.error(err.message))
    }

    updateAccountPassword = (newPassword,currentUser) => {
        try{
            if(!currentUser.emailVerified) throw new Error("Please Verify Your Email. Check Your Email Or Contact Support")
            if(!currentUser.status) throw new Error("Your Account Has Been Suspended. Please Contact Support For Details")
            if(newPassword.password !== newPassword.repeat) throw new Error("Passwords don't match")
            updatePassword(auth.currentUser,newPassword)
            .then(()=>{
                toast.success("Your Password Has Been Successfully Updated")
            })
            .catch((err) => toast.error(err.message))
        }catch(err){
            toast.error(err.message)
        }
    }


    //new
    userSubscription = (setCurrentUser) => {
        return onAuthStateChanged(auth, user => {
        
        
            if(user){
                user.getIdTokenResult().then( idTokenResult => {
                    user.admin = idTokenResult.claims.admin

                    //BUG HERE

                    // Because userdata is only updated when a user logs in
                    // updates to the db user document dont reflect until the
                    // page is refreshed

                
                    const setUser = async () => {
                        const data = await DataService.getDocument("users",user.uid)
                        // if(!data.data().emailVer) return new Error("")
                        setCurrentUser({
                            id: user.uid,
                            admin:user.admin,
                            status: data.data().status,
                            wishlist: data.data().wishlist,
                            reviewedProducts: data.data().reviewedProducts,
                            emailVerified: data.data().emailVerified,
                        })
                    }
                    const setAdmin = async () => {
                        const data = await DataService.getDocument("admin",user.uid)
                        
                        setCurrentUser({
                            id: user.uid,
                            admin: user.admin,
                            status: data.data()?.status,
                            username: data.data().name,
                            emailVerified: data.data().emailVerified,
                        })
                    }

                    if(user.admin === true){
                        setAdmin()
                    }else{
                        setUser()
                    }

                    })
                
            }else{
                setCurrentUser(null)
            }
           })
    }



}

export default new AuthService()