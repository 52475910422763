import carouselImage2 from '../assets/carousel-image2.jpg'
import carouselImage3alternate from '../assets/carousel-image3-alternate.JPG'
import carouselImage4 from '../assets/carousel-image4.jpg'
import promo1 from '../assets/promo1.JPG'
import promo2 from '../assets/promo2.JPG'
import simple from '../assets/simple.JPG'
import simplepng from '../assets/simple.png'
import clean from '../assets/clean.jpg'
import cleanpng from '../assets/clean.png'
import uncomplicated from '../assets/uncomplicated.jpeg'
import uncomplicatedpng from '../assets/uncomplicated.png'
import uncompromising from '../assets/uncompromising.jpg'
import uncompromisingpng from '../assets/uncompromising.png'
import founder from '../assets/founder.jpg'

//hero image carousel images
export const images = Object.freeze([
    
    {
      hero: carouselImage3alternate,
      tag1:"Healthy Glow",
      tag2:"From Head To Toe...",
      tag3:"Your Journey Starts Here",
      link:`shop`,
      alt: "HSF Naturals NG Soaps And Scrubs",
    },

    {
     hero: carouselImage2,
     tag1:"Discover 'PURE' Gold",
     tag2:"The West African",
     tag3:"Miracle Oil",
     link:`shop/pure`,
     alt: "HSF Naturals NG Pure Moringa Oil",
    },

    {
     hero: carouselImage4,
     tag1:"What's Your Flavor?",
     tag2:"Indulge In Our",
     tag3:"Silky Smooth Body Butters",
     link:`shop/product-category/butter`,
     alt: "HSF Naturals NG Body Butter Collection",
    },
  ])


export const adminLinks = Object.freeze([
    {
        name: 'Dashboard',
        url: "/admin/dashboard"
    },
    {
        name: 'Order Data',
        url: "/admin/orders"
    },
    {
        name: 'Product Data',
        url: "/admin/products"
    },
    {
        name: 'User Data',
        url: "/admin/users"
    },
    {
        name: 'Settings',
        url: "/admin/settings"
    },
])

export const userLinks = Object.freeze([
    {
        name: 'My Profile',
        url: "/account/profile"
    },
    {
        name: 'My Orders',
        url: "/account/orders"
    },
    {
        name: 'Settings',
        url: "/account/settings"
    },
])

 
//promo section images
export const promoImages = Object.freeze({
    promo1:{
        image: promo1,
        alt: "HSF Naturals NG Milk Moisturizing Soap Bar"
    },
    promo2:{
        image: promo2,
        alt: "HSF Naturals NG Reveal Glow Mask"
    },
    promo3:{
        image: founder,
        alt: "HSF Naturals NG Harmattan Set"
    },

})


//general company information
export const companyInfo = Object.freeze({
    name: "HSF Naturals NG",
    address: "Somewhere",
    "hours-of-operation": {
        weekdays: "9-5",
        weekends: "10-4",
        holidays: "Closed",
    },
    contact: {
        email: "info@hsfnaturalsng.com",
        whatsapp: "+234 (0) 8126794255",
        instagram: "@hsfnaturals.ng",
        facebook: "@hsfnaturals.ng",
        twitter: "@hsfnaturals.ng",
    }

})

export const companyGoals = Object.freeze({
    simple:{
        image: simple,
        icon: simplepng,
        alt: 'HSF Naturals NG Accessory Spoon'
    },
    clean:{
        image: clean,
        icon: cleanpng,
        alt: 'HSF Naturals NG Morina Oil'
    },
    uncomplicated:{
        image: uncomplicated,
        icon: uncomplicatedpng,
        alt: 'HSF Naturals NG Not Your Average Black Soap'
    },
    uncompromising:{
        image: uncompromising,
        icon: uncompromisingpng,
        alt: 'HSF Naturals NG Smokey Green Purifying Soap Bar'
    },
})


//shipping locations and phone codes
export const shippingLocations = Object.freeze([
    {
        country: "Nigeria",
        extension: {
            code: "+234",
            "alpha-2": "NGN",
            flag: "🇳🇬",
        },
        states: [
            "Aba",
            "Abakaliki",
            "Abeokuta",
            "Abuja",
            "Ado ekiti",
            "Akure",
            "Asaba",
            "Auchi",
            "Awka",
            "Bauchi",
            "Benin",
            "Bonny",
            "Calabar",
            "Dutse",
            "Eket",
            "Ekpoma",
            "Enugu",
            "Gwagwalada",
            "Ibadan",
            "Ife",
            "Ijebu-ode",
            "Ilorin",
            "Jos",
            "Kaduna",
            "Kano",
            "Katsina",
            "Lafia",
            "Lagos",
            "Lokoja",
            "Maiduguri",
            "Makurdi",
            "Minna",
            "Nnewi",
            "Nsukka",
            "Ogbomosho",
            "Onitsha",
            "Osogbo",
            "Owerri",
            "Port harcourt",
            "Sapele",
            "Sokoto",
            "Umuahia",
            "Uyo",
            "Warri",
            "Yenagoa",
            "Yola",
            "Zaria", 
        ]
    },
    {
        country: "United States",
        extension: {
            code: "+1",
            "alpha-2": "US",
            flag: "🇺🇸",
        },
        states: [ 
        "Alaska", 
        "Alabama", 
        "Arkansas", 
        "American Samoa", 
        "Arizona", 
        "California", 
        "Colorado", 
        "Connecticut", 
        "District of Columbia", 
        "Delaware", 
        "Florida", 
        "Georgia", 
        "Guam", 
        "Hawaii", 
        "Iowa", 
        "Idaho", 
        "Illinois", 
        "Indiana", 
        "Kansas", 
        "Kentucky", 
        "Louisiana", 
        "Massachusetts", 
        "Maryland", 
        "Maine", 
        "Michigan", 
        "Minnesota", 
        "Missouri", 
        "Mississippi", 
        "Montana", 
        "North Carolina", 
        "North Dakota", 
        "Nebraska", 
        "New Hampshire", 
        "New Jersey", 
        "New Mexico", 
        "Nevada", 
        "New York", 
        "Ohio", 
        "Oklahoma", 
        "Oregon", 
        "Pennsylvania", 
        "Puerto Rico", 
        "Rhode Island", 
        "South Carolina", 
        "South Dakota", 
        "Tennessee", 
        "Texas", 
        "Utah", 
        "Virginia", 
        "Virgin Islands", 
        "Vermont", 
        "Washington", 
        "Wisconsin", 
        "West Virginia", 
        "Wyoming"]
    },
    {
        country: "Canada",
        extension: {
            code: "+1",
            "alpha-2": "CA",
            flag: "🇨🇦",
        },
        states: [
            "Alberta",
            "British Columbia",
            "Manitoba",
            "New Brunswick",
            "Newfoundland",
            "Northwest Territories",
            "Nova Scotia",
            "Nunavut",
            "Ontario",
            "Prince Edward Island",
            "Quebec",
            "Saskatchewan",
            "Yukon",
        ]
    },
])