import React,{useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import DataService from '../../services/providers/data.service'


export const UserOrderPage = () => {


  const [item,setItem] = useState(null)
  const [loading,setLoading] = useState(true)
  const {orderid} = useParams()
  // const navigate = useNavigate()

  const date = item?.created?.toDate().toUTCString()



  useEffect(()=>{
    
    const getOrder = async () => {
      const data = await DataService.getDocument("orders",orderid)
      setItem(data.data())
      
    }
    
    if(loading){
      getOrder()
      setLoading(prev => !prev)
    }

  },[orderid,loading])

  return (
    <div className='min-h-[100vh] w-full flex flex-col items-center bg-background pb-[25vh]'>

      {/* page title */}
      <span className='text-white text-3xl md:text-5xl bg-secondary px-10 py-5 mt-10 border-primary border-2 rounded-2xl'>{`ORDER# ${orderid}`}</span>
          <span className='mt-10 bg-blue-100 text-black text-center rounded-full text-md md:text-lg px-5'>{`ORDER DATE :  ${date}`}</span>

          {/* CUSTOMER INFO SECTION */}
          <div className='w-full lg:max-w-[75vw] flex flex-col justify-between mt-10 items-center'>

          <div className='grid grid-col-2 w-full bg-gradient-to-b from-gray-600 via-gray-800 to-black text-base lg:text-2xl mb-10'>
            <div className='col-span-2 flex items-center justify-center text-center h-10 border'>{"CUSTOMER CONTACT INFO"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Name :"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.customerFName} ${item?.customerLName}`}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Phone :"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.customerPhone}`}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Email :"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.customerEmail}`}</div>
            {/* <div className={`col-span-1 flex items-center justify-center text-center h-10 border-b border-x ${item?.userUID ? ``:`hidden`}`}>{"UID :"}</div>
            <div className={`col-span-1 flex items-center justify-center text-center h-10 border-b border-x ${item?.userUID ? ``:`hidden`} hover:underline decoration-secondary cursor-pointer`}
            onClick={()=> navigate(`/admin/users/${item.userUID}`)}
            >{`${item?.userUID}`}</div> */}
          </div>

          <div className='grid grid-col-2 w-full bg-gradient-to-b from-gray-600 via-gray-800 to-black text-xl lg:text-2xl mb-10'>
            <div className='col-span-2 flex items-center justify-center text-center h-10 border'>{"ORDER DETAILS"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Subtotal :"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`N${item?.subtotal}`}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Shipping :"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`N${item?.shipping}`}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Total :"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`N${item?.total}`}</div>
          </div>

            <div className='grid grid-col-2 w-full bg-gradient-to-b from-gray-600 via-gray-800 to-black text-xl lg:text-2xl'>
                <div className='col-span-2 flex items-center justify-center text-center h-10 border'>{"DELIVERY DETAILS"}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Street :"}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.address}`}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Street 2:"}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.address_2 ? item?.address_2:""}`}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"City :"}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.city}`}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"State :"}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.state}`}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Postal Code :"}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.postal_code ? item?.postal_code:""}`}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Country :"}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.country}`}</div>
            </div>
          </div>

          {/* ORDER ITEMS */}
          <span className='mt-10 bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white text-2xl px-5 py-2'>{"ORDER DETAILS"}</span>
          <div className='w-[80%] flex-col mt-5'>
                  {item?.userCart.map((product,i) => (
                    <div key={i} className='w-full flex mt-5 flex-col lg:flex-row items-center'>
                      <img src={product?.image} className='w-40 h-40 mr-5 border-black border-2' alt=''/>
                      <div className='h-full flex flex-col lg:flex-row mt-5'>
                        <div className='h-full flex flex-col border-2 border-black bg-white text-black px-5 mr-1'>
                          <span className='text-center border-b-2 border-black'>Name</span>
                          <span className='text-center'>{product?.name}</span>
                        </div>
                        <div className='h-full flex flex-col border-2 border-black bg-white text-black px-5 mr-1'>
                          <span className='text-center border-b-2 border-black'>Size</span>
                          <span className='text-center'>{product?.size}</span>
                        </div>
                        <div className='h-full flex flex-col border-2 border-black bg-white text-black px-5 mr-1'>
                          <span className='text-center border-b-2 border-black'>QTY</span>
                          <span className='text-center'>{product?.qty}</span>
                        </div>
                        <div className='h-full flex flex-col border-2 border-black bg-white text-black px-5 mr-1'>
                          <span className='text-center border-b-2 border-black'>Sale Price</span>
                          <span className='text-center'>N{product?.price}</span>
                        </div>
                        <div className='h-full flex flex-col border-2 border-black bg-white text-black px-5 mr-1'>
                          <span className='text-center border-b-2 border-black'>Applied Discount</span>
                          <span className='text-center'>-{product?.discountRate}%</span>
                        </div>
                      </div>
                    </div>
                  ))}
          </div>

    </div>
  )
}