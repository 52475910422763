import React from 'react'
import {AiFillFacebook, AiFillInstagram,AiFillTwitterSquare} from 'react-icons/ai'
import logo from '../../assets/logo-white.png'

export const Footer = () => {

  const year = new Date().getFullYear()

  return (
    <div className='w-full min-h-[30vh] bg-primary flex flex-col items-center mt-20'>
        
        <div className='w-full h-[80%] flex flex-col md:flex-row'>
          
          {/* LOGO & SOCIALS SECTION */}
          <div className='w-full md:w-[50%] h-full flex flex-col items-center justify-center'>
            <img src={logo} width='300' height='300' alt='' className=''/>
            <div className='flex justify-evenly w-full md:w-[70%] lg:w-[50%]'>
              <a href='https://m.facebook.com/pg/hsfnaturals.ng/posts/'>
                <AiFillFacebook size={30} className='text-[#00acee]'/>
              </a>
              <a href='https://www.instagram.com/hsfnaturals.ng/'>
                <AiFillInstagram size={30} className='text-[#bc2a8d]'/>
              </a>
              <a href='https://twitter.com/hsfnaturals_ng'>
                <AiFillTwitterSquare size={30} className='text-[#4267B2]'/>
              </a>
            </div>
          </div>


          {/* HELP SECTION */}
          <div className='w-full md:w-[50%] h-full flex flex-col items-center justify-center text-center text-white my-10 font-thin'>
            <h3 className="w-full text-2xl mb-5 font-black">Customer Help</h3>
            <a href={`/`} className='hover:underline'>Home</a>
            <a href={`about-us`} className='hover:underline'>About</a>
            <a href={`faqs`} className='hover:underline'>FAQ's</a>
            <a href={`contactus`} className='hover:underline'>Contact Us</a>
          </div>

        </div>

        <div className='w-full flex justify-end'>
          <h4 className='text-gray-200 text-sm font-extralight text-center md:pr-5'>&copy;{year} HSF Naturals Ng | All Rights Reserved</h4>
        </div>

    </div>
  )
}
