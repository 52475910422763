import React,{useState} from 'react'
import { useStateContext } from '../../services/contexts/StateContext'
import { StarRating } from './StarRating'
import { calculateDiscountPrice } from '../../services/utilities/calculateDiscountPrice'

export const ProductComponentAlternate = ({product,index,reveal}) => {

  const {addToCart} = useStateContext()
  const [selectedIndex, setselectedIndex] = useState(0)



  return (
    <div key={index} className={`${reveal ? `opacity-100`:`opacity-0 translate-x-[5rem]`} 
                transition duration-500 w-[20rem] h-full inline-block
                hover:scale-[1.03] ease-linear
                `}>
                    <div className='grid w-full h-full grid-rows-7'>

                        <div className='row-span-6 relative'>
                        
                        <a href={`/shop/${product.slug}`}>
                            <img src={product?.image} alt={`HSF Naturals NG ${product?.name}`}
                            className='absolute object-cover cursor-pointer h-full w-full p-5'/>
                        </a>
                        
                        {/* IN STOCK TAG */}
                        {product.options[selectedIndex]?.status === 0 ? 
                        <div className='absolute text-xs bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white border border-black top-10 left-10 w-30 p-2 text-center rounded-full'>Back Soon</div>
                        : product.options[selectedIndex]?.status === 1 ?
                        <div className='absolute text-xs bg-gradient-to-b from-yellow-300 via-yellow-400 to-yellow-500 text-white border border-black top-10 left-10 w-30 p-2 text-center rounded-full'>Low Stock</div>
                        :null
                        }

                        {/* DISCOUNT FLAG */}
                        {product.options[selectedIndex]?.discountRate > 0 ? 
                        <div className='absolute text-xs bg-gradient-to-b from-red-300 via-red-400 to-accent-secondary text-white border border-black top-5 right-5 w-30 px-3 text-center rounded-full py-2'>-{product.options[selectedIndex]?.discountRate}%</div>
                        :null
                        }
                        <button className='flex items-center justify-center absolute top-[50%] left-[10%] opacity-40 hover:opacity-80 cursor-pointer bg-gray-800 rounded-full'
                            onClick={()=>{
                            const item = Object.freeze({
                                id: product.id,
                                name: product.name,
                                image: product.image,
                                size: product.options[selectedIndex].size,
                                price: product.options[selectedIndex].price,
                                weight: product.options[selectedIndex].weight,
                                discountRate: product.options[selectedIndex].discountRate,
                                qty: 1,
                            })
                            addToCart(item,1)

                            }}
                            disabled = {product.options[selectedIndex]?.status === 0}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="currentColor" className={`text-white m-10 ${product.options[selectedIndex]?.status === 0 ? ` hidden`:``}`} viewBox="0 0 16 16">
                                <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" fill="white"></path>
                                <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" fill="white">
                            </path>
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="currentColor" className={`text-white m-10 ${product.options[selectedIndex]?.status === 0 ? ``:`hidden`}`} viewBox="0 0 16 16">
                                <path d="M7.354 5.646a.5.5 0 1 0-.708.708L7.793 7.5 6.646 8.646a.5.5 0 1 0 .708.708L8.5 8.207l1.146 1.147a.5.5 0 0 0 .708-.708L9.207 7.5l1.147-1.146a.5.5 0 0 0-.708-.708L8.5 6.793 7.354 5.646z"/>
                                <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                        </button>

                        <div className='absolute bottom-0 right-0 flex flex-col items-end justify-end p-5'>
                            {product.options.map((option,index)=>{
                            return (
                            <label key={index}>
                                <input type='radio' className='hidden' value={index} onClick={()=> setselectedIndex(index)}/>
                                <div className={`${selectedIndex === index ?`opacity-90 bg-gradient-to-b from-gray-600 via-gray-800 to-black hover:opacity-100 text-white`:`bg-gradient-to-b from-gray-600 via-gray-800 to-black opacity-50 hover:opacity-60 border-white border`} h-10 mb-5 cursor-pointer  pl-5 pr-6 duration-100 transition-transform hover:scale-[1.1] text-center flex items-center justify-center text-xs lg:text-sm`}>{option.size}</div>
                            </label>
                            )})
                            }
                        </div>


                        </div>
                        <div className='w-full h-[75px] grid grid-cols-2 grid-rows-2 bg-white text-primary pt-2 z-10'>
                            <div className='col-span-1 row-span-1 flex items-center justify-center text-center whitespace-normal '>{product.name}</div>
                            <div className='col-span-1 row-span-1 flex items-center justify-center'><StarRating size={20} value={product.rating} disabled={true}/></div>
                            <div className={`${parseInt(product.options[selectedIndex].discountRate) > 0 ? `col-span-1 line-through decoration-4 decoration-red-500`:`col-span-2`} row-span-1 flex items-center justify-center text-2xl m-10`}>&#8358;{product.options[selectedIndex].price}</div>
                            <div className={`${parseInt(product.options[selectedIndex].discountRate) > 0 ? `col-span-1`:`hidden`} row-span-1 flex items-center justify-center text-2xl m-10`}>
                                &#8358;{calculateDiscountPrice(product.options[selectedIndex]?.price,product.options[selectedIndex]?.discountRate)}
                            </div>
                        </div>
                        
                    </div>
                </div>
  )
}
