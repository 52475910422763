import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'

export const ProductImageCarousel = ({images}) => {
  
  const [selected, setSelected] = useState(images && images[0])
  const {slug} = useParams()

  useEffect(()=>{
    setSelected(images && images[0])
  },[images])

  return (
    <div className='w-full h-full flex flex-col'>
        <div className='w-full h-full relative flex flex-col items-center'>
          <img src={selected} alt={`HSF Naturals NG ${slug}`} className='h-full w-full p-10 sm:w-[90%] md:w-[70%] lg:w-full'/>
          <div className='w-full h-[15%] items-center justify-center flex bg-transparent absolute bottom-[-15%] border-2'>
              {images?.map((image,index) => (
                  <img key={index} src={image} alt='' className='cursor-pointer w-[100px] h-full hover:border-blue-300 border-2' onClick={()=> setSelected(images[index])}/>
                  ))}
          </div>
        </div>
    </div>
  )
}
