import React from 'react'

const nonCurrentDayClasses = `
  w-8
  sm:w-[50px]
  bg-gradient-to-t from-cyan-300 via-cyan-400 to-accent-primary
  rounded-sm
  border-2 border-black
`;

const currentDayClasses = `
  w-8
  sm:w-[50px]
  bg-gradient-to-t from-red-300 via-red-400 to-accent-secondary
  rounded-sm
  border-2 border-black
`;

export const Bar = ({ height, isCurrentDay }) => {
  return (
    <div
      className={isCurrentDay ? currentDayClasses : nonCurrentDayClasses}
      style={{ height: `${height}px` }}></div>
  )
}
