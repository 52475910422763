
import { ProductComponentAlternate } from './ProductComponentAlternate'


export const ProductScrollBar = ({productList,reveal}) => {

  return (
    <div className='absolute w-full left-[1%] top-[0%] h-full overflow-y-hidden overflow-x-scroll whitespace-nowrap mt-5'>
        {
        productList?.map((product,index) => 
            <ProductComponentAlternate product={product} reveal={reveal} index={index} key={index}/>
            )}
    </div>
  )
}
