import React,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { FAQComponent } from '../../components/general-components/FAQComponent'
import DataService from '../../services/providers/data.service'
import { LoadingPage } from './LoadingPage'


export const FAQsPage = () => {

  const [config, setConfig] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(()=>{

    const getConfig = async () => {

        const d = await  DataService.getDocument("config","settings")
        const data = d.data()
        setConfig(data)
    }

    if(loading){
        getConfig()
        setLoading(false)
    }

  },[loading])

  return (
    <div className='min-h-[100vh] w-full flex flex-col items-center'>
      {!config ? <LoadingPage/> : null }
        <div className='w-full h-[19rem] bg-black text-white flex-col flex text-center justify-center items-center'>
            <h3 className='text-5xl font-medium pb-10'>FAQ's</h3>
            <h5 className='text-2xl text font-light'>Here are some of our most frequently asked questions!</h5>
        </div>
        <div className='container w-full flex-col py-10'>
            {config?.faqData.map((item,i)=>{
                return <FAQComponent item={item} key={i}/>
            })}
        </div>
        <div className='w-full min-h-[15rem] bg-primary flex justify-center items-center'>
          <div className='flex h-[50%] w-[70%] flex-col lg:flex-row justify-around items-center'>
            <div className='text-4xl font-light flex-col justify-center items-center'>
              <h6 className='text-center w-full text-xl lg:text-3xl'>Still have questions?</h6>
              <button className='bg-black w-full hover:bg-gray-900 h-16 text-white my-5'
              onClick={()=> navigate('/contactus')}
              >
                  Contact Us
              </button>
              <h6 className='text-center text-sm'>Or send an email to info@hsfnaturalsng.com</h6>
            </div>
          </div>
        </div>
    </div>
  )
}
