import React,{useRef} from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../../services/contexts/StateContext'
import AuthService from '../../services/providers/auth.service'
import {adminLinks, userLinks} from '../../system/settings'

export const LoginSidebar = ({open,setOpen}) => {
  
  const navigate = useNavigate()
  const credentials = useRef() 
  const {currentUser} = useStateContext()


  const mailLogin = async (e) => {
   
    e.preventDefault()
    try{
        await AuthService.loginWithMail(credentials)
    }catch(error){
       console.log(error)
    }
}

  const googleLogin = async () => {
      
      try{
        await AuthService.loginWithGoogle()
        
      }catch(error){
        console.log(error)
      }
  }

  const signOut = async (e) => {
    e.preventDefault()
    try{
        await AuthService.logout()
    }catch(error){
        console.log(error)
    }
  }

  return (
    <>
     <div className={`fixed top-0 right-[-0vw] h-[100vh] overflow-y-hidden w-full md:w-[50vw] z-50 flex-col bg-gradient-to-b from-background via-gray-50 to-gray-100  ${open ? `-translate-x-0`:`translate-x-full`} ease-in-out duration-300`}>
            <div className='flex w-full h-[10vh] justify-between items-center pb-10 text-white bg-gradient-to-b from-gray-600 via-gray-800 to-black'>
                {/* user svg */}
                <span className='cursor-pointer pr-5'>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    onClick={()=> setOpen(!open)}
                    className="h-12 w-12 ml-5 mt-10" 
                    viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                    </svg>
                </span>
                {/* close svg */}
                <span className='cursor-pointer pr-5'>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    onClick={()=> setOpen(!open)}
                    className="h-12 w-12 mr-5 mt-10" 
                    viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </span>
            </div>

            {/* main body */}
            {currentUser ? 
                
                <div className='w-full flex flex-col items-center text-2xl justify-center pt-20'>
                    
                    {currentUser?.admin === true ? 
                    adminLinks.map((link,index) =>  <button className='w-full text-black flex items-center justify-center border-y-2 py-3 hover:shadow-inner shadow-black'
                    key={index}
                    onClick={() => {
                        setOpen(!open)
                        navigate(`${link.url}`)
                    } }
                    >{link.name}</button>)
                    :
                    userLinks.map((link,index) =>  <button className='w-full text-black flex items-center justify-center border-y-2 py-3 hover:shadow-inner shadow-black'
                    key={index}
                    onClick={() => {
                        setOpen(!open)
                        navigate(`${link.url}`)
                    } }
                    >{link.name}</button>)
                    }

                    <button className='bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 hover:shadow-inner hover:shadow-black rounded-full px-7 py-2 text-white shadow-lg mt-32'
                    onClick={signOut}>
                        SIGNOUT
                    </button>

                </div>
                :
                <div className='w-full h-full flex flex-col items-center text-white  overflow-hidden'>
                
                {/* title */}
                
                <span className='w-[75vw] text-5xl border-b-2 border-secondary text-center text-primary'>Log In</span>
                <div className='w-[75vw] text-lg text-center flex flex-col justify-center items-center mt-5 text-primary'>
                    Don't have an account?
                    {/* navigate to sign up button */}
                    <button className='bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 hover:shadow-inner hover:shadow-black rounded-full px-7 py-2 text-white mt-5 shadow-lg'
                    onClick={() => {
                        setOpen(!open)
                        navigate("/registration")
                    } }
                    >Create Account</button>
                </div>
                
                {/* sign in form inputs */}
                <div className='w-full flex flex-col items-center justify-center mt-10 text-lg text-black'>
                    <form ref={credentials} onSubmit={mailLogin} className='w-full h-full flex flex-col items-center justify-center'> 
                        <input type='email' name='email' required placeholder='Email Address' className='w-[90%] lg:w-[50%] p-3 m-2 border border-black'/>
                        <input type='password' name='password' required placeholder='Password' className='w-[90%] lg:w-[50%] p-3 m-2 border border-black'/>
                        <button type='submit' className='bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 hover:shadow-inner hover:shadow-black rounded-full px-16 py-2 text-white mt-5 shadow-lg'>Log In</button>
                    </form>
                </div>

                <div className='w-[75vw] text-lg text-center flex flex-col justify-center items-center mt-5'>
                    <span className='text-primary'
                    >Forgot your password?</span>
                    <span className='text-black hover:text-accent-primary border-b-2 hover:border-accent-primary cursor-pointer border-black'
                    onClick={()=> {
                    setOpen(!open)
                    navigate('/recovery')}}
                    >Click Here</span>
                </div>


                <div type="button" className="bg-white text-2xl border-black border-2 cursor-pointer text-black flex justify-center items-center rounded-full mt-5 px-5 py-2 shadow-sm shadow-gray-300"
                onClick={()=> googleLogin()}
                >
                    <svg viewBox="0 0 24 24" className='w-10 h-10 mr-3' xmlns="http://www.w3.org/2000/svg">
                        <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                            <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
                            <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
                            <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
                            <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
                        </g>
                    </svg>
                    Google Sign In
                </div>

                </div>
            }
        </div>
        <div className={`opacity-60 fixed right-[-0vw] w-full h-[100vh] overflow-y-hidden inset-0 z-40 bg-black ${open ? `-translate-x-0`:`translate-x-full`}`} onClick={()=> setOpen(false)}></div>
   </>
  )
}
