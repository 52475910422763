import {useState, useEffect} from 'react'


const useYearlyStats = (orders) => {

    const [configData, setConfigData] = useState()
    
    
    useEffect(()=> {
          
      const yearlySalesData = () => {
        var months = [...Array(12)].map(()=>[])
        var sales = 0
        var numSold = 0

        orders?.forEach((item) => {
          let month =  new Date(item.created.seconds * 1000).getMonth()

          sales += parseInt(item.subtotal)


            if(months[month].length > 0){
              months[month][0].totalSales += parseInt(item.subtotal)
                months[month][0].orders.push({
                  orderTotal: parseInt(item.subtotal),
                  created: item.created,
                  order: item.id,
                })
            }else{
              months[month] = [{
                totalSales: parseInt(item.subtotal),
                orders:[
                {
                  orderTotal: parseInt(item.subtotal),
                  created: item.created,
                  order: item.id,
                }
              ]
              }]
            }

            item.userCart.forEach((product) => {
              numSold += parseInt(product.qty)
            })
            
          return []

        })


        const bestMonth = () => {
          var index = {index:0,value:0}
          months.forEach((month,i) => {
            if(parseInt(month[0]?.totalSales) > index.value){
              index.index = i
              index.value = parseInt(month[0]?.totalSales)
            }
          })
          return {...months[index.index][0], index: index.index}
        }

        return {months,sales,numSold,bestMonth:bestMonth()}
      }


      
      setConfigData({
        netSales: yearlySalesData().sales,
        numOrders: orders?.length,
        monthlySales: yearlySalesData().months,
        productsSold: yearlySalesData().numSold,
        bestMonth: yearlySalesData().bestMonth
      })

    },[orders])

    return {configData}
}

export default useYearlyStats;