import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import { useStateContext } from '../../services/contexts/StateContext'
import { CartProductComponent } from './CartProductComponent'
import empty from '../../assets/empty.gif'

export const CartSidebar = () => {
  
    const {totalPrice,showCart, setShowCart, cartItems, totalQty} = useStateContext()
    
    const [cart,setCart] = useState(cartItems)

   
    useEffect(()=>{
        setCart(cartItems)
    },[cartItems])

  return (
    <>
    <div className={`fixed top-0 right-[-0vw] h-[100vh] w-full md:w-[50vw] z-50 flex-col bg-gray-50  ${showCart ? `-translate-x-0`:`translate-x-full`} ease-in-out duration-300`}>
            <div className='flex w-full h-[10vh] bg-gradient-to-b from-gray-600 via-gray-800 to-black justify-between items-center mb-5 text-white'>
                {/* cart svg */}
                <span className='pr-5 ml-10 relative mb-7'>
                    <svg xmlns="http://www.w3.org/2000/svg"
                    className="h-12 w-12 mr-5 mt-10" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                    </svg>
                    <div className='rounded-full bg-white text-black flex justify-center items-center w-6 h-6 absolute top-[40%] left-[30%] border-secondary border-2'>{totalQty}</div>
                </span>
                {/* close svg */}
                <span className='cursor-pointer pr-5 mb-7'>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    onClick={()=> setShowCart(false)}
                    className="h-12 w-12 mr-5 mt-10" 
                    viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </span>
            </div>
            
            {/* main body */}
            <div className='w-full h-full flex flex-col items-center text-white  overflow-hidden'>
                
                {/* title */}
                <span className='w-[75vw] text-5xl border-b-2 text-primary border-primary text-center'>Your Cart</span>

                {cart.length > 0 ? 
                
                <div className='w-full h-full items-center flex flex-col mt-5 text-white'>
                    <div className='w-full max-h-[50vh] overflow-y-scroll flex flex-col items-center lg:mb-5'>
                    {
                    cart.map((item,i) => (<CartProductComponent key={i} item={item} index={i}/>))
                    }  
                    </div>
                    <div className='flex-col'>
                    <div className='w-full h-14 bg-gradient-to-b from-gray-600 via-gray-800 to-black text-xl mt-2 border-white border-2 flex justify-end items-center px-14'>
                        <h4>Subtotal:  N{totalPrice} </h4>
                    </div>

                    <Link to={'/cart'}>
                        <div className='flex justify-center items-center lg:mt-5 mt-2'>
                                <div className='h-12 w-[80%] bg-gradient-to-b from-gray-600 via-gray-800 to-black border-2 hover:shadow-inner hover:shadow-black flex rounded-full justify-center items-center text-white' onClick={()=> setShowCart(false)}>Checkout</div>
                        </div>
                    </Link>
                    
                </div>
                </div>
                :
                <div className='w-full h-full justify-center items-center flex flex-col text-primary bg-inherit text-xl'>
                    {"YOUR CART IS EMPTY 😢"}
                    <img src={empty} alt='empty cart' className='w-[20rem] h-[20rem] bg-inherit'/>
                </div>
                }

            </div>
            


        </div>
        <div className={`opacity-60 fixed right-[-0vw] w-full h-[100vh] overflow-y-hidden inset-0 z-40 bg-black ${showCart ? `-translate-x-0`:`translate-x-full`}`} onClick={()=> setShowCart(false)}></div>
   </>
  )
}
