import React from 'react'
import { Bar } from './Bar'
import {Link} from 'react-router-dom'

export const MonthlySalesGraph = ({monthlySales}) => {

    const currentMonth = new Date().getMonth()

    const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
    
    const sales = monthlySales?.map((month) => month.reduce((total,subtotal) => total + parseInt(subtotal),0))

    const heightCalculator = (sale) => {

        const MAX_AMOUNT = 200000
        const height = (sale * 300) / MAX_AMOUNT
        return Math.ceil(height)
    }

    const thisYear = new Date().getFullYear()

  return (

    <div className="flex justify-between items-end pt-16 border-4 border-black px-5 overflow-scroll bg-white">
      {sales?.map((month,index) => (
        <Link to={`/admin/orders/sales-data/${thisYear}/${index+1}`} key={index}>
        <div className="flex flex-col items-center gap-3 sm:gap-2 group cursor-pointer relative mr-10">
          <div className="bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white text-card-white p-2 rounded-[5px] font-bold opacity-100 absolute -top-12 ">
            {month}
          </div>
            <Bar
              height={heightCalculator(month)}
              isCurrentDay={currentMonth === index}
              />
          <span className='text-black'>{months[index]}</span>
        </div>
        </Link>
      ))}
    </div>
  )
}
