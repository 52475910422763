import React, {useState,useEffect} from 'react'
import DataService from '../../services/providers/data.service'
import { useStateContext } from '../../services/contexts/StateContext'
import {toast} from 'react-toastify'
import AuthService from '../../services/providers/auth.service'
import { DeleteModal } from '../../components/general-components/DeleteModal'
import { useNavigate } from 'react-router-dom'


export const UserSettingsPage = () => {

  const {currentUser} = useStateContext()
  const [openDelete, setOpenDelete] = useState(false)
  const navigate = useNavigate()

  const handlePasswordChange = (e) => {
    e.preventDefault()

    try{

      const password = {
        password: e.target.password.value,
        repeat: e.target.passwordRepeat.value,
      }
      AuthService.updateAccountPassword(password,currentUser)
    }catch(err){
      toast.error(err.message)
    }

    

  }

  const handleDelete = (e) => {
    e.preventDefault()
    AuthService.deleteUser()
    navigate('/')
  }


  return (
    <div className='w-full min-h-screen flex flex-col items-center pb-[40vh]'>
      {openDelete ? <DeleteModal setOpen={setOpenDelete} runFunction={handleDelete}/>:null}
    <span className='w-full bg-gradient-to-b from-gray-600 via-gray-800 to-black shadow-md hover:shadow-inner hover:shadow-black mb-10 text-white text-center text-2xl py-5'>
      {"Change Password"}
    </span>

    <form onSubmit={handlePasswordChange} className='w-full lg:w-[50vw] grid grid-cols-4 h-40'>
      <div className='col-span-1 h-10 bg-gradient-to-b from-gray-600 via-gray-800 to-black shadow-md hover:shadow-inner hover:shadow-black text-white flex justify-center items-center text-base md:text-lg lg:text-xl'>{"Password"}</div>
      <input type='text' name='password' className='col-span-3 border-2 rounded-tr-lg rounded-br-lg h-10 text-black'/>
      <div className='col-span-1 h-10 bg-gradient-to-b from-gray-600 via-gray-800 to-black shadow-md hover:shadow-inner hover:shadow-black text-white flex justify-center items-center text-base md:text-lg lg:text-3l'>{"Repeat Password"}</div>
      <input type='text' name='passwordRepeat' className='col-span-3 border-2 rounded-tr-lg rounded-br-lg h-10 text-black'/>
      <button type='submit' className='col-span-2 bg-gradient-to-b from-gray-600 via-gray-800 to-black shadow-md hover:shadow-inner hover:shadow-black text-white col-start-2 rounded-lg h-10'>Change Password</button>
    </form>

    <span className='w-full bg-gradient-to-b from-gray-600 via-gray-800 to-black shadow-md hover:shadow-inner hover:shadow-black mb-10 text-white text-center text-2xl py-5'>
      {"Delete Account"}
    </span>

    <div className='w-full flex justify-center items-center'>
      <button className='px-10 py-5 text-white rounded-full bg-gradient-to-b from-red-400 via-red-600 to-red-800 shadow-md hover:shadow-inner hover:shadow-black'
      onClick={()=> setOpenDelete((open)=>!open)}
      >{"DELETE ACCOUNT"}</button>
    </div>

    </div>
  )
}
