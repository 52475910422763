import React, {useState, useEffect} from 'react'
import DataService from '../../services/providers/data.service'
import { MonthlySalesGraph } from '../../components/admin-components/MonthlySalesGraph'
import useDashboardStats from '../../hooks/useDashboardStats'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../../services/contexts/StateContext'

export const Dashboard = () => {

  const navigate = useNavigate()
  const {currentUser} = useStateContext()
  // const [configData, setConfigData] = useState()
  const [orders, setOrders] = useState()
  const [radioValue, setRadioValue] = useState(3)
  const {configData} = useDashboardStats(orders)
  const thisYear = new Date().getFullYear()
  const [date, setDate] = useState({
    day: 1,
    month: 1,
    year: thisYear
  })
  // console.log(orders)

  const handleInputs = (e) => {
    setDate({
      ...date,
      [e.target.name]: parseInt(e.target.value)
    })
  }

  const handleRedirect = () => {
    switch(radioValue){
      case 1: navigate(`/admin/orders/sales-data/${date.year}`)
      break;
      case 2: navigate(`/admin/orders/sales-data/${date.year}/${date.month}`)
      break;
      case 3: navigate(`/admin/orders/sales-data/${date.year}/${date.month}/${date.day}`)
      break;
      default: navigate('/admin/dashboard')
    }
  }

  useEffect(()=>{

    const lastDayPrevYear = new Date(parseInt(thisYear),0,1)
    const firstDayNewYear = new Date(parseInt(thisYear)+1,0)

    const getData = async () => {
      const d = await DataService.allOrdersByDateSorted(lastDayPrevYear,firstDayNewYear)
      const data = d.docs.map((doc) => ({...doc.data(),id:doc.id}))
      setOrders(data)
    }

      getData()

    return 
    
  },[thisYear])


  // console.log(configData)

  return (
    <div className='md:w-[88%] w-full min-h-[100vh] flex flex-col items-center pb-[25vh]'>
      
      {/* page title */}
      <span className='text-white mx-1 text-2xl md:text-3xl bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 px-10 py-5 mt-10 border-primary border-2 rounded-2xl text-center mb-10'>{`Welcome Back ${currentUser.username}`}</span>


      <div className='w-full flex flex-col lg:flex-row lg:items-center justify-evenly md:ml-5 ml-2'>

        <div className='w-full lg:w-[35rem] flex flex-row items-center justify-evenly relative'>
          <div className='bg-gradient-to-r from-gray-800 via-black to-gray-800 w-40 z-10 h-40 lg:mt-0 mt-10 border-4 border-black flex items-center justify-center rounded-full'>
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" className="m-5 p-1 text-green-600" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"/>
           </svg>
          </div>
          <div className='w-full h-full text-right flex flex-col'>
            <div className={`${true ? ``:`hidden`} w-full h-8 flex items-center justify-center whitespace-no-wrap text-center bg-emerald-600 translate-y-5 md:translate-y-0 pl-5 text-sm lg:text-base mt-[5px] -translate-x-7 rounded-full`}>
              {`Sales Today - N${configData?.salesToday}`}
            </div>
            <div className={`${true ? ``:`hidden`} w-full h-8 flex items-center justify-center whitespace-no-wrap text-center bg-emerald-700 translate-y-5 md:translate-y-0 pl-5 text-sm lg:text-base mt-[5px] -translate-x-7 rounded-full`}>
              {`Sales this Month - N${configData?.salesThisMonth}`}
            </div>
            <div className={`${true ? ``:`hidden`} w-full h-8 flex items-center justify-center whitespace-no-wrap text-center bg-emerald-800 translate-y-5 md:translate-y-0 pl-5 text-sm lg:text-base mt-[5px] -translate-x-7 rounded-full`}>
              {`Sales this Year - N${configData?.netSales}`}
            </div>
          </div>
        </div>

        <div className='w-full lg:w-[35rem] flex flex-row items-center justify-evenly relative'>
          <div className='bg-gradient-to-r from-gray-800 via-black to-gray-800 w-40 z-10 h-40 lg:mt-0 mt-10 border-4 border-black flex items-center justify-center rounded-full'>
            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="currentColor" className="text-red-500 m-5" viewBox="0 0 16 16">
              <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z"/>
              <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z"/>
            </svg>
          </div>
          <div className='w-full h-full text-right flex flex-col'>
            <div className={`${configData?.pendingOrders > 0 ? ``:`hidden`} w-full h-10 flex items-center justify-center whitespace-no-wrap text-center bg-orange-500 translate-y-5 md:translate-y-0 pl-5 text-sm lg:text-base mb-[5px] -translate-x-7 rounded-full`}>
              {`You have ${configData?.pendingOrders} order${configData?.pendingOrders === 1 ? ``:`s`} pending confirmation`}
            </div>
            <div className={`${configData?.unviewedOrders > 0 ? ``:`hidden`} w-full h-10 flex items-center justify-center whitespace-no-wrap text-center bg-yellow-500 translate-y-5 md:translate-y-0 pl-5 text-sm lg:text-base mt-[5px] -translate-x-7 rounded-full`}>
              {`You have ${configData?.unviewedOrders} unviewed order${configData?.unviewedOrders === 1 ? ``:`s`}`}
            </div>
          </div>
        </div>
          


      </div>

      <div className='w-80 lg:w-[50vw] border-2 border-black flex flex-col items-center justify-center my-10 bg-gray-400 shadow-md shadow-black'>
        <div className='w-full h-[10vh] bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white flex items-center justify-center text-center mb-10 lg:text-xl'>
          {"Search Sales Data By Date"}
        </div>
        <div className='grid grid-cols-3 w-[75%] mb-10 gap-2'>
          <div className={`lg:col-span-1 col-span-3 h-10 flex items-center justify-center cursor-pointer rounded-full ${radioValue === 3 ? `bg-gradient-to-b from-cyan-600 via-cyan-800 to-cyan-900 shadow-md hover:shadow-inner hover:shadow-black`:`bg-gradient-to-b from-gray-600 via-gray-800 hover:to-black hover:shadow-inner hover:shadow-black text-white hover:bg-gradient-to-b hover:from-cyan-600 hover:via-cyan-800 to-cyan-900 shadow-md`}`}onClick={()=> setRadioValue(3)}>DD/MM/YYYY</div>
          <div className={`lg:col-span-1 col-span-3 h-10 flex items-center justify-center cursor-pointer rounded-full ${radioValue === 2 ? `bg-gradient-to-b from-cyan-600 via-cyan-800 to-cyan-900 shadow-md hover:shadow-inner hover:shadow-black`:`bg-gradient-to-b from-gray-600 via-gray-800 hover:to-black hover:shadow-inner hover:shadow-black text-white hover:bg-gradient-to-b hover:from-cyan-600 hover:via-cyan-800 to-cyan-900 shadow-md`}`}onClick={()=> setRadioValue(2)}>MM/YYYY</div>
          <div className={`lg:col-span-1 col-span-3 h-10 flex items-center justify-center cursor-pointer rounded-full ${radioValue === 1 ? `bg-gradient-to-b from-cyan-600 via-cyan-800 to-cyan-900 shadow-md hover:shadow-inner hover:shadow-black`:`bg-gradient-to-b from-gray-600 via-gray-800 hover:to-black hover:shadow-inner hover:shadow-black text-white hover:bg-gradient-to-b hover:from-cyan-600 hover:via-cyan-800 to-cyan-900 shadow-md`}`}onClick={()=> setRadioValue(1)}>YYYY</div>
        </div>

        <div className='w-full h-[50%] flex flex-col items-center justify-center'>
          <div className='w-[50%] h-[30%] flex items-center justify-evenly text-black'>
            
            {radioValue === 3 &&
              <input type='number' name='day' placeholder='DD' className='w-20 h-10' onChange={handleInputs}/>
            }

            {radioValue > 1 &&
              <input type='number' name='month' placeholder='MM' className='w-20 h-10' onChange={handleInputs}/>
            }

            {radioValue >= 1 &&
              <input type='number' name='year' placeholder='YYYY' className='w-20 h-10' onChange={handleInputs}/>
            }

          </div>
          <button className='rounded-full w-[50%] py-3 my-5 bg-gradient-to-b from-gray-600 via-gray-800 to-black shadow-md hover:shadow-inner hover:shadow-black text-white' onClick={handleRedirect}>SEARCH</button>
        </div>

      </div>

      <div className='w-full lg:w-[80%] mt-10 flex flex-col'>
        <div className='h-20 bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white text-center text-3xl flex items-center justify-center'>{"Monthly Sales For " + thisYear}</div>
        <MonthlySalesGraph monthlySales={configData?.months}/>
      </div>

    </div>
  )
}
