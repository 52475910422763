import {useState} from 'react'

const SkinGuideComponent = ({image,description}) => {

  const [open, setOpen] = useState(false)

  return (
    <div className={`w-[90vw] md:w-[80vw] lg:w-[70vw] border-2 border-black my-5 cursor-pointer relative`} onClick={()=> setOpen(!open)}>
        <img src={image} alt={description} className={`${!open ? `h-[310px]`:`h-auto`} object-cover object-top w-full`}/>
        {!open && <button className='absolute left-[50%] translate-x-[-50%] translate-y-[-150%] opacity-80 hover:opacity-100 px-10 py-2 bg-black text-white'>Tap To Expand</button>}
    </div>
  )
}

export default SkinGuideComponent