import React from 'react'

export const ProductDescription = ({products}) => {
  return (
    <div className='grid grid-cols-1 gap-5 mt-10 min-h-[30rem] w-full'>

      <div className='col-span-1 grid grid-rows-5 f'>
        <div className='h-[5rem] row-span-1 flex items-center justify-center bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white py-3 text-lg'>
          {"Description"}
        </div>
        <div className='row-span-4 flex items-start justify-center p-10 text-black bg-white whitespace-pre-wrap'>
          {products?.description}
        </div>
      </div>


    </div>

  )
}
