import React from 'react'
import { ReviewFormComponent } from '../../components/general-components/ReviewFormComponent'
import ReviewComponent from './ReviewComponent'

export const ProductReviews = ({products,setLoading}) => {

  return (
    <div className='w-full mt-5 flex flex-col'>
        
        {products?.reviews.length > 0 ? 
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 w-full p-1'>
            {products.reviews?.map((review,i)=>{
            return(
              <ReviewComponent review={review} products={products} setLoading={setLoading} index={i} key={i}/>
            )})
            }

            </div>

        :

            <div className='flex flex-col items-center text-primary text-center'>
            <h5 className='text-md text-center'>Sorry! There are no reviews for this product yet</h5>
            <h5 className='text-md font-bold my-7'>Be the first to leave a review for our {products?.name}</h5>
            {/* <ReviewFormComponent/> */}
            </div>

        }
        <ReviewFormComponent product={products} setLoading={setLoading}/>
    </div>
  )
}
