import React from 'react'
import {MdOutlineArrowForwardIos,MdHome} from 'react-icons/md'
import { slugify} from '../../services/utilities/slugify'


export const BreadcrumbComponent = ({item}) => {

  const cat = item?.category
  const baseurl = process.env.REACT_APP_PRODUCTION_URL
  const slug = item ? slugify(cat): ''

  return (


    <div className='grid grid-cols-5 w-[95%] text-xs lg:text-base lg:max-w-[30rem] bg-gradient-to-b from-gray-600 via-gray-800 to-black px-10 py-2'>
        <div className='col-span-1 flex items-center justify-center text-white text-center hover:underline decoration-secondary'>
            <MdHome size={20}/>
            <a href={`${baseurl}/shop`} 
            // <a href={`https://${baseurl}/shop`} 
            className='mr-2'>{"Home"}</a>
            <MdOutlineArrowForwardIos/>
        </div>
        <div className='col-span-2 flex items-center justify-center text-white text-center hover:underline decoration-secondary'>
            <a href={`${baseurl}/shop/product-category/${slug}`} 
            // <a href={`https://${baseurl}/shop/product-category/${slug}`} 
            className='mr-2'>{cat}</a>
            <MdOutlineArrowForwardIos/>
        </div>
        <div className='col-span-2 flex items-center justify-center text-white text-center'>
            {item?.name}
        </div>
    </div>
  )
}
