import React, {useState} from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useStateContext } from '../../services/contexts/StateContext'
import { UserSidebar } from '../../components/user-components/UserSidebar'

export const UserPage = () => {

  const {currentUser} = useStateContext()
  const [selected, setSelected] = useState()

  return (
    currentUser ?
    <div className='w-[100vw] flex flex-col-reverse md:flex-row'>
      <UserSidebar selected={selected} setSelected={setSelected}/>
      <Outlet/>
    </div>
    :
    <Navigate to='/'/>
  )
}
