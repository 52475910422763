import React,{useEffect,useState} from 'react'
import { useParams } from 'react-router-dom'
import DataService from '../../services/providers/data.service'
import { PaymentInstructionsComponent } from '../../components/general-components/PaymentInstructionsComponent'
import { generateOrderInvoice } from '../../services/serverRequest'


export const OrderReceiptPage = () => {
  
  const companyName = `${process.env.REACT_APP_COMPANY_NAME}`
  const {orderid} = useParams()
  const [order,setOrder] = useState(null)
  const timestamp = order?.created?.toDate().toDateString()

  const generateInvoicePDF = async () => {
    var transferButton = document.getElementById("invoice-button")
    transferButton.classList.add("spinning")
    const invoice = await generateOrderInvoice(order,timestamp)
    const linkSource = `data:application/pdf;base64,${invoice}`;
    const downloadLink = document.createElement("a");
    const fileName = `${companyName}-${order.id}-invoice.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;

    downloadLink.click()
    transferButton.classList.remove("spinning")
  }

  useEffect(()=>{
    const getData = async () => {
        const data = await DataService.getDocument('orders',orderid)
        setOrder({
          ...data.data(),
        })
        }

    getData()
  },[orderid])


  return (
    <div className='max-w-[100vw] min-h-[100vh] flex flex-col items-center pb-[25vh] bg-background'>
        
        <h2 className='text-white text-3xl lg:text-5xl text-center bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 px-10 py-5 mt-10 border-primary border-2'>Order Confirmed!</h2>

        <h4 className='text-xl text-center my-5 bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 text-white border-2 border-black mx-1 md:px-8 lg:py-2 lg:px-4'>Thank you! Your order has been successfully placed!</h4>
        


        <h4 className={`text-6xl underline px-10 py-5 bg-gradient-to-b from-gray-600 via-gray-800 to-black ${order?.status ? `decoration-secondary text-secondary`:`text-accent-secondary decoration-accent-secondary`}`}>
            {order?.status ? "PAID":"UNPAID"}
        </h4>

        <h5 className='text-white text-3xl lg:text-5xl text-center bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 px-10 py-5 mt-10 border-primary border-2'>Order Information</h5>
        
        <div className='w-[95%] lg:w-[75%] h-48 bg-white text-primary my-10 flex-col flex justify-evenly items-center'>
            <div className='flex justify-evenly w-full text-center border-b-2 pb-2'>
                <h5 className='text-md lg:text-lg w-[50%]'>ORDER NUMBER: </h5>
                <h5 className='text-md lg:text-lg font-bold  w-[50%]'>{order?.orderID}</h5>
            </div>
            <div className='flex justify-evenly w-full text-center border-b-2 pb-2'>
                <h5 className='text-md lg:text-lg  w-[50%]'>ORDER DATE: </h5>
                <h5 className='text-md lg:text-lg w-[50%]'>{timestamp}</h5>
            </div>
            <div className='flex justify-evenly w-full text-center border-b-2 pb-2'>
                <h5 className='text-md lg:text-lg  w-[50%]'>CUSTOMER EMAIL: </h5>
                <h5 className='text-md lg:text-lg w-[50%]'>{order?.customerEmail}</h5>
            </div>
            <div className='flex justify-between w-full text-center'>
                <h5 className='text-md lg:text-lg  w-[50%]'>ORDER TOTAL: </h5>
                <h5 className='text-md lg:text-lg w-[50%]'>N {order?.total}</h5>
            </div>
        </div>

        <button onClick={() => generateInvoicePDF()} id='invoice-button' className='w-72 rounded-2xl py-5 text-lg tonedButton bg-gradient-to-b from-red-300 via-red-400 to-accent-secondary my-10'>Download Invoice</button>
        
        {!order?.status && <PaymentInstructionsComponent/>}
       

        <h5 className='text-white text-3xl lg:text-5xl text-center bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 px-10 py-5 mt-10 border-primary border-2'>Order Details</h5>

        <div className='w-[95%] lg:w-[75%] mb-10 flex  flex-col my-10'>
        

            <div className='w-full grid grid-flow-row-dense grid-cols-5 bg-gradient-to-b from-gray-600 via-gray-800 to-black text-xs md:text-base lg:text-lg border-t-2'>
                <div className='col-span-2 border-b border-x-2 text-center'>{"Product Name"}</div>
                <div className=' border-b border-r-2 text-center'>{"Quantity"}</div>
                <div className=' border-b border-r-2 text-center'>{"Applied Discount"}</div>
                <div className=' border-b border-r-2 text-center'>{"Discounted Price"}</div>
                {order?.userCart?.map((item,i)=>{
                return <div key={i} className='w-full col-span-5 grid grid-flow-row-dense grid-cols-5 bg-white text-primary border-b-2 border-gray-700  text-xs md:text-base lg:text-lg'>
                        <div className='col-span-2 text-center border-x-2 border-b'>{`${item?.name} - ${item?.size}`}</div>
                        <div className=' text-center border-r-2 border-b'>x{item?.qty}</div>
                        <div className=' text-center border-r-2 border-b'>-{item?.discountRate >= 0 ? item?.discountRate:0}%</div>
                        <div className=' text-center border-r-2 border-b'>N{item?.price - parseInt(item?.discountRate >= 0 ? item?.discountRate:0)/100 * parseInt(item?.price)}</div>
                    </div>
            })}
            </div>


            <div className='w-full h-40 bg-white text-primary flex-col border border-t-0 flex justify-evenly'>
                <div className='flex justify-evenly w-full text-center border-b-2 pb-2'>
                    <h5 className='text-md lg:text-lg  w-[50%]'>SUBTOTAL: </h5>
                    <h5 className='text-md lg:text-lg  w-[50%]'>N {order?.subtotal}</h5>
                </div>
                <div className='flex justify-between w-full text-center border-b-2 pb-2'>
                    <h5 className='text-md lg:text-lg  w-[50%] '>SHIPPING: </h5>
                    <h5 className='text-md lg:text-lg  w-[50%]'>N {order?.shipping}</h5>
                </div>
                <div className='flex justify-evenly w-full text-center'>
                    <h5 className='text-md lg:text-lg  w-[50%] '>TOTAL: </h5>
                    <h5 className='text-md lg:text-lg  w-[50%]'>N {order?.total}</h5>
                </div>
            </div>


        </div>

        <div className='col-span-2 grid w-[95%] lg:w-[75%] grid-cols-4 text-xl md:text-2xl lg:text-3xl h-full'>     
            <div className='col-span-4 h-10 bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white flex justify-center items-center text-lg md:text-2xl lg:text-3xl'>{"Contact Information"}</div>
            <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"First Name:"}</div>
            <div className='col-span-2 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order?.customerFName}</div>
            <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"Last Name:"}</div>
            <div className='col-span-2 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order?.customerLName}</div>
            <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"Email Address:"}</div>
            <div className='col-span-2 h-10 bg-white text-black flex justify-center items-center text-sm md:text-xl lg:text-2xl'>{order?.customerEmail}</div>
            <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"Phone Number:"}</div>
            <div className='col-span-2 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order?.customerPhone}</div>
            <div className='col-span-4 h-10 bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white flex justify-center items-center text-lg md:text-2xl lg:text-3xl mt-5'>{"Delivery Information"}</div>
            <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"Address:"}</div>
            <div className='col-span-2 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order?.address}</div>
            <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"Address 2:"}</div>
            <div className='col-span-2 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order?.address_2}</div>
            <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"City:"}</div>
            <div className='col-span-2 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order?.city}</div>
            <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"State:"}</div>
            <div className='col-span-2 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order?.state}</div>
            <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"Postal Code:"}</div>
            <div className='col-span-2 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order?.postal_code}</div>
            <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"Country:"}</div>
            <div className='col-span-2 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order?.country}</div>
        </div>


    </div>
  )
}
 