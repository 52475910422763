import {useState, useEffect} from 'react'
import { storage, store, timeStamp } from '../services/firebaseConfig-old-version'

const useStorage = (file,collection) => {

    const [progress, setProgress] = useState(0)
    const [error, setError] = useState(null)
    const [url, setUrl] = useState(null)

    useEffect(()=>{
        const storageRef = storage.ref(file.name)

        const collectionRef = store.collection(collection)

        storageRef.put(file).on('state_changed',(snap)=>{
            let percentage = (snap.bytesTransferred/ snap.totalBytes) * 100
            setProgress(percentage)
        }, (err) => {
            setError(err)
        }, async () => {
            const url = await storageRef.getDownloadURL()
            collectionRef.add({
                url,
                createdAt: timeStamp(),
                name: file.name,
            })
            setUrl(url)
        })
    },[file,collection])
  return {progress,error,url}
}

export default useStorage;