import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Bar } from './Bar'

export const YearlySalesGraph = ({monthlySales}) => {

    const currentMonth = new Date().getMonth()

    const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
    
    const navigate = useNavigate()


    const heightCalculator = (sale) => {

        const MAX_AMOUNT = 200000
        const height = (sale ? sale * 300 : 0) / MAX_AMOUNT
        return Math.ceil(height)
    }




  return (

    <div className="flex justify-between items-end pt-16 border-4 border-black px-5 overflow-scroll bg-white">
      {monthlySales?.map((month,index) => (
        <div
          key={index}
          onClick={()=> navigate(`${index+1}`)}
          className="flex flex-col items-center gap-3 sm:gap-2 group cursor-pointer relative mr-10">
          <div className="bg-black text-white text-card-white p-2 rounded-[5px] font-bold opacity-100 absolute -top-12 ">
            {/* class below allows for hover effect */}
          {/* <div className="bg-black text-white text-card-white p-2 rounded-[5px] font-bold group-hover:opacity-100 opacity-0 absolute -top-12 transition-opacity"> */}
            {month[0]?.totalSales}
          </div>
          <Bar
            height={heightCalculator(month[0]?.totalSales)}
            isCurrentDay={currentMonth === index}
          />
          <span>{months[index]}</span>
        </div>
      ))}
    </div>
  )
}
