import { db } from '../firebaseConfig'
import { 
    collection, addDoc, deleteDoc, doc,
    query, where ,getDocs, updateDoc,
    setDoc, getDoc, orderBy, limit,
    serverTimestamp, startAfter } from 'firebase/firestore'
import { toast } from 'react-toastify'

class DataService {

    //IN USE
    
    updateDocument = (collection, id, updatedDocument) => {
        const docRef = doc(db, `${collection}`, id)
        // console.log(updatedDocument)
        const document = {...updatedDocument, updated: serverTimestamp() }
        return updateDoc(docRef, document)
    }
    
    updateUserDocument = (id, updatedDocument) => {
        const docRef = doc(db, "users", id)
        // console.log(updatedDocument)
        const document = {
            ...updatedDocument, 
            updated: serverTimestamp(),
            updates: [
                ...updatedDocument.updates,
                {user:id, date: new Date(), action: "Profile Info Update"}
            ] }
            return updateDoc(docRef, document)
        }
        
        addReview = async (id,item,currentUser) => {
            const docRef = doc(db, "products", id)
            
            const rating = item.reviews.reduce((total,review) => total += parseInt(review.rating),0)
            
            const averageRating = Math.round(rating/(item.reviews.length))
            
            const timestamp = serverTimestamp()
            
            try{
                
            await updateDoc(docRef, {
                rating: averageRating,
                reviews: item.reviews,
                updates: [...item.updates,{user:currentUser?.id, date: new Date(), action: "Product Review Creation"}],
                updated: timestamp,
            })
            toast.success("Review Has Been Sucessfully Added!")
        }catch (err){
            toast.error("Please Login To Post a Review")

        }
    }
    
    
    deleteReview = async (id,item,currentUser) => {
        const docRef = doc(db, "products", id)
        
        const rating = item.reviews.reduce((total,review) => total += parseInt(review.rating),0)
        
        const averageRating = Math.round(rating/(item.reviews.length))
        
        const timestamp = serverTimestamp()
        
        try{
            
            await updateDoc(docRef, {
                rating: averageRating,
                reviews: item.reviews,
                updates: [...item.updates,{user:currentUser?.id, date: new Date(), action: "Product Review Delete"}],
                updated: timestamp,
            })
        }catch (err){
            toast.error("Please Login To Post a Review")
            
        }
    }
    
    deleteOrder = async (id,order) => {
        const colRef = collection(db,"deleted-orders")
        await addDoc(colRef, order)
        const docRef = doc(db, 'orders', id)
        return deleteDoc(docRef)
    }
    
    
    
    getAllProductsByDate = async () => {
        const colRef = collection(db,"products")
        const q = query(colRef, orderBy("created","desc"))
        return getDocs(q)
    }
    
    getProductsByCategory = async (category,sort) =>{
        const colRef = collection(db,'products')
        const q = query(colRef, where("category", "==", category), where("hidden", "==", false))
        return getDocs(q)
    }
    
    getProductsByTags = async (tags,sort) => {
        const colRef = collection(db,'products')
        const q = query(colRef, where("tags","array-contains-any",tags.slice(0,10)), where("hidden", "==", false))
        return getDocs(q)
    }
    
    getProductsByCategoryAndTags = async (category,tags) => {
        const colRef = collection(db,"products")
        const q = query(colRef, where("category","==",category), where("tags","array-contains-any",tags.slice(0,10)), where("hidden", "==", false))
        return getDocs(q)
    }
    
    getTopFiveProducts = async () => {
        const colRef = collection(db,'products')
        const q = query(colRef, orderBy("rating","desc"), where("hidden", "==", false), limit(5))
        return getDocs(q)
    }

    getRelatedProducts = async (tags,slug) => {
        const colRef = collection(db,"products")
        const q = query(colRef, limit(5), where("tags","array-contains-any",tags.slice(0,10)), where("slug","!=",`${slug}`), where("hidden", "==", false))
        return getDocs(q)
    }
    
    getLatestFiveProducts = async () => {
        const colRef = collection(db,'products')
        const q = query(colRef, orderBy("created","desc"), where("hidden", "==", false), limit(5))
        return getDocs(q)
    }
    
    allOrdersByDateSorted = (value1,value2) => {
        const colRef = collection(db,"orders")
        const q = query(colRef, where("created", ">=", value1), where("created", "<", value2), orderBy("created"))
        // const q = query(colRef, where("created", range1, value1), orderBy("created","desc"))
        return getDocs(q)
    }
    
    getAllDocuments = (colName) => {
        const colRef = collection(db,colName)
        return getDocs(colRef);
    }

    getAllShipping = () => {
        const colRef = collection(db,"shipping")
        const q = query(colRef, orderBy("city"))
        return getDocs(q);
    }
    
    
    
    deleteDocument = (collection, id) => {
        const docRef = doc(db, `${collection}`, id)
        return deleteDoc(docRef)
    }
    
    getDocument = (collection, id) => {
        const docRef = doc(db, `${collection}`, id)
        return getDoc(docRef)
    }
    
    getDocumentByAttr = (colName, attr, value ) => {
        const colRef = collection(db,colName)
        const q = query(colRef, where(`${attr}`, '==', `${value}`))
        return getDocs(q)
    }
    
    getOrderByAttr = (colName, attr, value ) => {
        const colRef = collection(db,colName)
        const q = query(colRef, where(`${attr}`, '==', value), orderBy("created","desc"))
        return getDocs(q)
    }
    
    ordersByAttrSorted = (colName, attr, value ) => {
        const colRef = collection(db,colName)
        const q = query(colRef, where(`${attr}`, '==', `${value}`), orderBy("created","desc"))
        return getDocs(q)
    }
    
    ordersByDateSorted = (value1,value2) => {
        const colRef = collection(db,"orders")
        const q = query(colRef, where("created", ">=", value1), where("created", "<", value2),where("status","==",true), orderBy("created"))
        // const q = query(colRef, where("created", range1, value1), orderBy("created","desc"))
        return getDocs(q)
    }
    
    getLimitedProducts = (limitNum,lastDoc,sort) => {
        const colRef = collection(db,"products")
        if(lastDoc){
            const q = query(colRef, limit(limitNum), startAfter(lastDoc ? lastDoc : 0), where("hidden", "==", false))
            return getDocs(q)
        }
        const q = query(colRef, limit(limitNum),where("hidden", "==", false))
        return getDocs(q)
    }
    
    getLimitedDocuments = (colName,limitNum,lastDoc) => {
        const colRef = collection(db,colName)
        if(lastDoc){
            const q = query(colRef, orderBy("created","desc"), limit(limitNum), startAfter(lastDoc ? lastDoc : 0))
            return getDocs(q)
        }
        const q = query(colRef, limit(limitNum), orderBy("created","desc"))
        return getDocs(q)
    }
    
    addDocument = async (colName, newProduct) => {
        const colRef = collection(db,colName)
        const product = {...newProduct, created: serverTimestamp() }
        return await addDoc(colRef, product)
    }
    

    addDocumentByID = (collection, id, newOrder) => {
        const docRef = doc(db, `${collection}`, id)
        const order = {...newOrder, created: serverTimestamp() }
        return setDoc(docRef, order)
    }
    
    //TO BE DELETED?
    
    
    
    
    // getAllDocumentsByOrder = (colName,orderAttribute,direction) => {
    //     const colRef = collection(db,colName)
    //     // const q = query(colRef, orderBy(orderAttribute,direction), limit(5))
    //     const q = query(colRef, orderBy(orderAttribute,direction))
    //     return getDocs(q);
    // }
    
}

export default new DataService();