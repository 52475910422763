import { StarRating } from './StarRating'
import {BsFillTrashFill} from 'react-icons/bs'
import { useStateContext } from '../../services/contexts/StateContext'
import { useNavigate } from 'react-router-dom'
import DataService from '../../services/providers/data.service'
import { toast } from 'react-toastify'




export const ReviewComponent = ({review,products,setLoading,index}) => {

  const emotes = [

    // DEAD EMOTE (0)
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className='text-purple-400' viewBox="0 0 16 16"> 
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM4.146 5.146a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 1 1 .708.708l-.647.646.647.646a.5.5 0 1 1-.708.708L5.5 7.207l-.646.647a.5.5 0 1 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 0-.708zm5 0a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 1 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 0-.708zM8 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/> 
    </svg>,
    // ANGRY EMOTE (1)
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className='text-red-600' viewBox="0 0 16 16"> 
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM4.053 4.276a.5.5 0 0 1 .67-.223l2 1a.5.5 0 0 1 .166.76c.071.206.111.44.111.687C7 7.328 6.552 8 6 8s-1-.672-1-1.5c0-.408.109-.778.285-1.049l-1.009-.504a.5.5 0 0 1-.223-.67zm.232 8.157a.5.5 0 0 1-.183-.683A4.498 4.498 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 1 1-.866.5A3.498 3.498 0 0 0 8 10.5a3.498 3.498 0 0 0-3.032 1.75.5.5 0 0 1-.683.183zM10 8c-.552 0-1-.672-1-1.5 0-.247.04-.48.11-.686a.502.502 0 0 1 .166-.761l2-1a.5.5 0 1 1 .448.894l-1.009.504c.176.27.285.64.285 1.049 0 .828-.448 1.5-1 1.5z"/>
    </svg>,
    // FROWN EMOTE (2)
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className='text-blue-300' viewBox="0 0 16 16">
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm-2.715 5.933a.5.5 0 0 1-.183-.683A4.498 4.498 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 0 1-.866.5A3.498 3.498 0 0 0 8 10.5a3.498 3.498 0 0 0-3.032 1.75.5.5 0 0 1-.683.183zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z"/>
    </svg>,
    // SMILING EMOTE (3)
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className='text-yellow-400' viewBox="0 0 16 16"> 
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z"/>
    </svg>,
    // EXCITED EMOTE (4)
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className='text-orange-400' viewBox="0 0 16 16">
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5c0 .501-.164.396-.415.235C6.42 6.629 6.218 6.5 6 6.5c-.218 0-.42.13-.585.235C5.164 6.896 5 7 5 6.5 5 5.672 5.448 5 6 5s1 .672 1 1.5zm5.331 3a1 1 0 0 1 0 1A4.998 4.998 0 0 1 8 13a4.998 4.998 0 0 1-4.33-2.5A1 1 0 0 1 4.535 9h6.93a1 1 0 0 1 .866.5zm-1.746-2.765C10.42 6.629 10.218 6.5 10 6.5c-.218 0-.42.13-.585.235C9.164 6.896 9 7 9 6.5c0-.828.448-1.5 1-1.5s1 .672 1 1.5c0 .501-.164.396-.415.235z"/>
    </svg>,
    // HEART EYES EMOTE (5)
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className='text-emerald-600' viewBox="0 0 16 16"> 
    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM4.756 4.566c.763-1.424 4.02-.12.952 3.434-4.496-1.596-2.35-4.298-.952-3.434zm6.559 5.448a.5.5 0 0 1 .548.736A4.498 4.498 0 0 1 7.965 13a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .548-.736h.005l.017.005.067.015.252.055c.215.046.515.108.857.169.693.124 1.522.242 2.152.242.63 0 1.46-.118 2.152-.242a26.58 26.58 0 0 0 1.109-.224l.067-.015.017-.004.005-.002zm-.07-5.448c1.397-.864 3.543 1.838-.953 3.434-3.067-3.554.19-4.858.952-3.434z"/>
    </svg>,
  ]

  const {currentUser} = useStateContext()
  const navigate = useNavigate()


  const deleteReview = async (e,index) => {
    e.preventDefault()

    products?.reviews.splice(index,1)


    try{
      if(currentUser.status === false) throw new Error("Your Account Has Been Suspended. Please Contact Support")
      await DataService.deleteReview(products?.id,products,currentUser)
      } catch (err) {
        toast.error(err.message)
      } finally{
          setLoading(true)
          toast.success("Review Successfully Removed")
      }
  }



  return (
    <div className='bg-gray-100 w-full min-h-[300px] mt-10 flex flex-col relative'>

      <button className={`${currentUser?.admin === true || currentUser?.id === review?.userUID ? `absolute z-20 cursor-pointer`:`hidden`} bottom-1 right-1 text-red-600 opacity-30 hover:opacity-80`}
      onClick={(e)=> deleteReview(e,index)}
      disabled={currentUser?.admin !== true || currentUser?.id !== review?.userUID}
      >
        <BsFillTrashFill size={50}/>
      </button>

      <div className='w-full h-[60px] flex items-center justify-center z-10 translate-y-[-30%]'>
        {emotes[review.rating]}
      </div>

      <div className='w-full h-[15%] flex items-center justify-center translate-y-[-30%]'>
          <StarRating size={30} value={review.rating} disabled={true}/>
      </div>

      <span className='w-full min-h-[150px] grid grid-cols-5 text-gray-700 translate-y-[-10%]'>
          <section className={`${currentUser?.admin ? `cursor-pointer underline decoration-emerald-700`:``} col-span-3 flex items-center justify-center`}
          onClick={() => {
            if(currentUser?.admin){
              navigate(`/admin/users/${review.userUID}`)
            }
          }}
          >
              -{review.name}
          </section>
          <section className='col-span-2 flex items-center justify-center'>
              {review.date}
          </section>
          <section className='col-span-5 flex items-center justify-center'>
            <p className='w-[90%] break-normal inline-block text-center whitespace-normal'>
              {review.review}
            </p>
          </section>
      </span>

    </div>
  )
}

export default ReviewComponent