import React from 'react'
import {companyGoals} from '../../system/settings'
  

export const AboutUsPage = () => {

  const sections = document.getElementsByName("section")

  const revealBoxes = () => {
    const triggerBottom = window.innerHeight / 5 * 4
    sections.forEach( section => {
      const sectionTop = section.getBoundingClientRect().top
      if(sectionTop < triggerBottom ){
        section.classList.remove("translate-x-[200%]")
      }else{
        section.classList.add("translate-x-[200%]")
      }
    })
  }

  window.addEventListener("scroll", revealBoxes)


  return (
    <div className='min-h-[100vh] flex flex-col items-center overflow-hidden bg-background'>

      <span className='w-full flex flex-col items-center text-center text-background bg-primary'>
        <h1 className='text-4xl my-10 decoration-secondary decoration-2 underline-offset-8 underline font-semibold'>
          A Little About Us...
        </h1>
        <p className='text-center w-[75vw] lg:w-[50vw] text-lg'>
        H.S.F Naturals is a natural beauty brand founded in 2019 by three sisters. The first initials of their names make up the name of the Company, with ’S’ as the CEO.
        </p>
        <p className='text-center w-[75vw] lg:w-[50vw] text-lg pt-5 pb-10'>
        We specialise in handmade skin and hair care essentials using plant based indigenous ingredients, ethically sourced from various parts of Nigeria and West Africa for their quality and effectiveness.
        </p>
      </span>

      <div className='w-full bg-background flex flex-col items-center text-primary font-semibold'>
        
        <h1 className='text-4xl my-10 decoration-secondary decoration-2 underline-offset-8 underline font-semibold'>
          Our Goals
        </h1>

        <div name='section' className=' grid grid-cols-3 grid-rows-3 transform translate-x-[200%] ease-linear duration-300 w-[90vw] h-[90vh] lg:w-[75vw] lg:h-[50vh] mb-10'>
          <div className='relative col-span-3 lg:col-span-1 row-span-1 lg:row-span-3 w-full h-full'>
            <img className='object-cover absolute mix-blend-overlay w-full h-full' src={companyGoals.simple.image} alt={companyGoals.simple.alt}/>
          </div>
          <div className='col-span-3 lg:col-span-2 row-span-2 lg:row-span-3 w-full h-full flex flex-col items-center justify-center text-sm lg:text-base'>
            <img className='w-16 h-16' alt={companyGoals.simple.alt} src={companyGoals.simple.icon}/>
            <p className='flex items-center justify-center text-center w-full text-2xl pt-5 font-bold decoration-secondary underline underline-offset-8 mb-2'>
              {`Simple Formulations That Go A Long Way`}
            </p>
            <p className='flex items-center justify-center text-center w-[75%]'>
              {`We handcraft our products in small batches using few elements to ensure freshness and quality of each and every one. No need to fill that drawer or clutter that sink with so many items, our brand provides simple essentials for regular skincare routines. A little goes a long way.`}
            </p>
            <p className='flex items-center justify-center text-center w-[75%] mt-7'>
              {`‘Healthy glow from Head to Toe’ is our motto for we believe in natural beauty and the great benefits of plant derived ingredients, each one with its own unique attributes to achieve good looking skin and hair without the need of harmful chemicals. `}
            </p>
          </div>
        </div>

        <div name='section' className='grid grid-cols-3 grid-rows-3 transform translate-x-[200%] ease-linear duration-300 w-[90vw] h-[90vh] lg:w-[75vw] lg:h-[50vh] mb-10'>
          <div className='relative col-span-3 lg:col-span-1 row-span-1 lg:row-span-3 w-full h-full'>
            <img className='object-cover absolute mix-blend-overlay w-full h-full' src={companyGoals.clean.image} alt={companyGoals.simple.alt}/>
          </div>
          <div className='col-span-3 lg:col-span-2 row-span-2 lg:row-span-3 w-full h-full flex flex-col items-center justify-center text-sm lg:text-base'>
            <img className='w-24 h-24' alt={companyGoals.simple.alt} src={companyGoals.clean.icon}/>
            <p className='flex items-center justify-center text-center w-full text-2xl pt-5 font-bold decoration-secondary underline underline-offset-8 mb-2'>
              {`Clean Packaging`}
            </p>
            <p className='flex items-center justify-center text-center w-[75%]'>
              {`H.S.F naturals offers a ‘no-fuss’ lifestyle to taking care of your body, this philosophy reflects in our clean and simple packaging, which allows our products to shine through.`}
            </p>
            <p className='flex items-center justify-center text-center w-[75%]'>
              {`While our containers may be plastic, all jars and bottles are recyclable, reusable PET and 100% food safe. We also encourage reuse of containers and offer rewards for returned ones.`}
            </p>
          </div>
        </div>

        <div name='section' className='grid grid-cols-3 grid-rows-3 transform translate-x-[200%] ease-linear duration-300 w-[90vw] h-[90vh] lg:w-[75vw] lg:h-[50vh] mb-10'>
          <div className='relative col-span-3 lg:col-span-1 row-span-1 lg:row-span-3 w-full h-full'>
            <img className='object-cover absolute mix-blend-overlay w-full h-full' src={companyGoals.uncompromising.image} alt={companyGoals.simple.alt}/>
          </div>
          <div className='col-span-3 lg:col-span-2 row-span-2 lg:row-span-3 w-full h-full flex flex-col items-center justify-center text-sm lg:text-base'>
            <img className='w-16 h-16' alt={companyGoals.simple.alt} src={companyGoals.uncompromising.icon}/>
            <p className='flex items-center justify-center text-center w-full text-2xl pt-5 font-bold decoration-secondary underline underline-offset-8 mb-2'>
              {`Uncompromising Skincare`}
            </p>
            <p className='flex items-center justify-center text-center w-[75%]'>
              {`The body absorbs 60% of what gets put on it, so as a natural skin and hair care brand, our products are made without toxic elements, harmful synthetic ingredients, and unnatural preservatives that may be harmful to your body in the long run.`}
            </p>
            <p className='flex items-center justify-center text-center w-[75%]'>
              {`We are strongly committed to encouraging a natural lifestyle, and embracing ones natural skin tone. We help bring out and maintain the best of you, just the way you are.`}
            </p>
          </div>
        </div>

        <div name='section' className='grid grid-cols-3 grid-rows-3 transform translate-x-[200%] ease-linear duration-300 w-[90vw] h-[90vh] lg:w-[75vw] lg:h-[50vh] mb-10'>
          <div className='relative col-span-3 lg:col-span-1 row-span-1 lg:row-span-3 w-full h-full'>
            <img className='object-cover absolute mix-blend-overlay w-full h-full' src={companyGoals.uncomplicated.image} alt={companyGoals.simple.alt}/>
          </div>
          <div className='col-span-3 lg:col-span-2 row-span-2 lg:row-span-3 w-full h-full flex flex-col items-center justify-center text-sm lg:text-base'>
            <img className='w-32 h-32' alt={companyGoals.simple.alt} src={companyGoals.uncomplicated.icon}/>
            <p className='flex items-center justify-center text-center w-full text-2xl pt-5 font-bold decoration-secondary underline underline-offset-8 mb-2'>
              {`Uncomplicated Ingredients`}
            </p>
            <p className='flex items-center justify-center text-center w-[75%]'>
              {`We believe the body doesn't require complexity to look good, which is why our products are formulated with locally produced ingredients that have been used for centuries with positive results. Each ingredient has been carefully chosen for a specific purpose.`}
            </p>
            <p className='flex items-center justify-center text-center w-[75%] mt-4'>
              {`Our brand is dedicated to boosting immense focus and awareness around locally sourced plant based ingredients, that way everyone gets to know.`}
            </p>
          </div>
        </div>

      </div>
    </div>
  )
}
