import React, {useState,useRef} from 'react'
import {AiFillTwitterSquare,AiFillFacebook} from 'react-icons/ai'
import {FaInstagramSquare} from 'react-icons/fa'
import {RiWhatsappFill} from 'react-icons/ri'
import {MdMail} from 'react-icons/md'
import {companyInfo} from '../../system/settings'
import {toast} from 'react-toastify'
import {ValidateContactEmail} from '../../services/utilities/errorHandler'
import { sendContactEmail } from '../../services/serverRequest'


export const ContactUsPage = () => {

  const formEl = useRef()
  const [lock, setLock] = useState(false)

  const sendEmail = async (e) => {
    e.preventDefault()
    setLock(true)
    try{

        let errors = ValidateContactEmail(formEl.current)

        if(errors.length > 0){
            throw new Error(errors)
        }

        await sendContactEmail(formEl.current.name.value,formEl.current.subject.value,formEl.current.email.value,formEl.current.message.value)
        .then(({message}) => {
            toast.success(message)
            document.getElementById("contact-form").reset()
        })


        }catch(err){
          toast.error(err.message)
    }
    setLock(false)
  }



  return (
    <div className='w-[100vw] flex flex-col text-primary'>
        {/* HERO SECTION */}
        <div className='w-full h-[15rem] bg-black text-white flex justify-center items-center flex-col'>
            <h3 className='text-4xl font-bold text-center pb-5'>Contact Us</h3>
            <h5 className='text-xl font-light text-center'>Let us know if you have any questions or concerns!</h5>
        </div>

        {/* ICON BLOCKS */}
        <div className='w-full flex flex-col lg:flex-row justify-evenly items-center my-10'>
            <div className='h-full w-[33%] flex-col flex justify-center items-center mt-5'>
                <MdMail className='text-8xl pb-3 text-gray-600'/>
                <h2 className='text-3xl py-3 font-light'>E-mail</h2>
                <h4 className='text-xl font-semibold'>{companyInfo.contact.email}</h4>
            </div>
            <div className='h-full w-[33%] flex-col flex justify-center items-center mt-5'>
                <RiWhatsappFill className='text-8xl pb-3 text-[#25D366]'/>
                <h2 className='text-3xl py-3 font-light'>Whatsapp</h2>
                <h4 className='text-xl font-semibold'>{companyInfo.contact.whatsapp}</h4>
            </div>
            <div className='h-full w-[33%] flex-col flex justify-center items-center mt-5 text-center'>
                <section className='w-full flex justify-center items-center'>
                    <a href='https://www.instagram.com/hsfnaturals.ng/' alt='HSF NG Instagram Page'>
                        <FaInstagramSquare className='text-8xl pb-3 text-[#bc2a8d]'/>
                    </a>
                    <a href='https://m.facebook.com/pg/hsfnaturals.ng/posts/' alt='HSF NG Facebook Page'>
                    <AiFillFacebook className='text-8xl pb-3 text-[#4267B2]'/>
                    </a>
                    <a href='https://twitter.com/hsfnaturals_ng' alt='HSF NG Twitter Page'>
                    <AiFillTwitterSquare className='text-8xl pb-3 text-[#00acee]'/>
                    </a>
                </section>
                <h2 className='w-full text-3xl py-3 font-light'>Our Socials</h2>
                <h4 className='w-full text-xl font-semibold'>{companyInfo.contact.instagram}</h4>
            </div>
        </div>

        {/* CONTACT BLOCKS */}

        <div className='grid grid-cols-2 w-full'>
            <div className='col-span-2 lg:col-span-1 flex flex-col items-center justify-center'>
                <h5 className='text-3xl font-bold py-8 text-center'>Our hours of operation</h5>
                <h5 className='text-xl font-light py-3 text-center'>Monday - Friday: {companyInfo['hours-of-operation'].weekdays}pm</h5>
                <h5 className='text-xl font-light py-3 text-center'>Saturday & Sunday: {companyInfo['hours-of-operation'].weekends}pm</h5>
                <h5 className='text-xl font-light py-3 text-center'>Public Holidays: {companyInfo['hours-of-operation'].holidays}</h5>
            </div>

            <div className='col-span-2 lg:col-span-1 flex flex-col items-center justify-center'>
                <h5 className='w-[70%] text-3xl font-bold py-8 text-center'>Let us know if you have any questions or concerns!</h5>

                <form id={'contact-form'} ref={formEl} onSubmit={sendEmail} className='w-full h-full flex flex-col items-center justify-center text-black'>
                    <input type='text' name='name' required placeholder='Name' className='w-[90%] lg:w-[70%] p-3 m-2 rounded-2xl border-2 border-secondary'/>
                    <input type='text' name='subject' required placeholder='Subject' className='w-[90%] lg:w-[70%] p-3 m-2 rounded-2xl border-2 border-secondary'/>
                    <input type='email' name='email' required placeholder='Email Address' className='w-[90%] lg:w-[70%] p-3 m-2 rounded-2xl border-2 border-secondary'/>
                    <textarea type='text' name='message' rows={10} required={true} className='w-[90%] text-black lg:w-[70%] border-2'/>
                    <button type='submit' disabled={lock} className='w-[50%] p-3 m-2 bg-gradient-to-b from-gray-600 via-gray-800 to-black shadow-md hover:shadow-inner hover:shadow-black text-white px-5 rounded-2xl border-2'>SEND</button>
                </form>
            </div>
        </div>
       
       
        </div>
  )
}
