import React from 'react'
import {ImUsers} from 'react-icons/im'
import {TiDocumentText} from 'react-icons/ti'
import {FaStoreAlt} from 'react-icons/fa'
import {HiOutlineLogout} from 'react-icons/hi'
import {BsFillBasket2Fill} from 'react-icons/bs'
import {AiTwotoneSetting} from 'react-icons/ai'
import AuthService from '../../services/providers/auth.service'
import { useNavigate } from 'react-router-dom'

const buttons = [
    {
        path: 'dashboard',
        icon: <FaStoreAlt/>,
    },
    {
        path: 'orders',
        icon: <TiDocumentText/>,
    },
    {
        path: 'products',
        icon: <BsFillBasket2Fill/>,
    },
    {
        path: 'users',
        icon: <ImUsers/>,
    },
    {
        path: 'settings',
        icon: <AiTwotoneSetting/>,
    },
]



export const AdminSidebar = ({selected,setSelected}) => {
  

  const navigate = useNavigate()
    
  const signOut = async (e) => {
        e.preventDefault()
        try{
            await AuthService.logout().then(()=>{
                console.log("User has been successfully logged out!")
            })
        }catch(error){
            console.log(error)
        }
   }

  return (
    <div className='sticky z-20 left-0 bottom-0 md:left-0 md:top-[145px] bg-gradient-to-r from-background via-gray-50 to-gray-100  w-[100vw] h-[20vw] md:w-[15vw] lg:w-[10vw] md:h-[87vh] flex md:flex-col items-center overflow-scroll md:overflow-auto md:pt-10'>
        {buttons.map((item,i) => (
        <div key={i} className={`relative flex items-center justify-center
        my-2 mx-auto shadow-lg text-xl md:text-2xl lg:text-3xl
        hover:from-white hover:to-white hover:text-secondary
        hover:rounded-full rounded-lg transition-all duration-100
        ease-linear cursor-pointer p-5 sm:p-7 ${selected === item.path ? `bg-white text-secondary rounded-full`:`bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 text-white`}`}
        onClick={()=>{
            setSelected(item.path)
            navigate(`${item.path}`)
        }}
        >
            {item.icon}
        </div>))}
        <div className='relative flex items-center justify-center
        my-2 mx-auto shadow-lg text-xl md:text-2xl lg:text-3xl
        text-white  hover:from-white hover:to-white hover:text-secondary
        hover:rounded-full rounded-lg transition-all duration-100
        ease-linear cursor-pointer p-5 sm:p-7
        bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900'
        onClick={(e)=>signOut(e)}
        >
            <HiOutlineLogout/>
        </div>
    </div>
  )
}
