import React,{useState} from 'react'
import { generatePasswordReset } from '../../services/serverRequest'
import {toast} from 'react-toastify'
import image from '../../assets/yellow.jpg'

//fix look

export const PasswordRecoveryPage = () => {

  const [email, setEmail] = useState()
  const [submitMessage, setSubmitMessage] = useState(null)

  const handleSubmit = async () => {
    await generatePasswordReset(email)
    .then((message) => {
        toast.success(message.message)
        setSubmitMessage(true)
    })
    .catch((err)=>{
      toast.error(err.message)
    })
  }

  return (
    <div className='w-full min-h-[70vh] flex flex-col items-center text-primary'>
      
      <h2 className='text-3xl mt-10 '>
        Forgotten Password
      </h2>

      <img src={image} alt='' className='w-[80vw] h-[20vh] object-cover mt-10'/>

      {!submitMessage ? 
      <h3 className='text-center w-[70%] lg:w-[50%] mt-10 text-xl'>
        Lost your password? Please enter your email address. You will receive a link to create a new password via email.
      </h3>
      :
      <h3 className='text-center w-[70%] lg:w-[50%] mt-10 text-xl'>
        An email has been successfully sent to the provided email
      </h3>
      }
      <input id='email' type='email' placeholder='Email Address' required className='w-[90%] lg:w-[30%] h-10 my-5 text-black' onChange={(e)=> setEmail(e.target.value)}/>

      <button className='w-[90%] lg:w-[20%] h-14 bg-black text-white mb-10'
            onClick={() => handleSubmit()}
            >SEND RESET LINK</button>
    </div>
  )
}
