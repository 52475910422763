import React,{useState,useContext, useEffect} from 'react'
import AuthService from '../providers/auth.service'
import useLocalStorage from '../../hooks/useLocalStorage'
import { calculateDiscountPrice } from '../utilities/calculateDiscountPrice';
import { CartSidebar } from '../../components/general-components/CartSidebar';


const StateContext = React.createContext();

export function useStateContext(){
    return useContext(StateContext)
}

export function StateContextProvider({children}){

    //SECURE THE USER CRED BETTER (HASHING OR WHATEVER)
    const [currentUser, setCurrentUser] = useLocalStorage('HSFUSERCRED',null)
    // const [admin, setAdmin] = useLocalStorage('HSFADMINSTATUS',false)


    const [showCart, setShowCart] = useState(false)
    const [showRegistration, setShowRegistration] = useState(false)
    const [loadingScreen,setLoadingScreen] = useState(false)



    // const [cartItems, setCartItems] = useState();
    const [cartItems, setCartItems] = useLocalStorage('HSFCARTITEMS',[]);
    const [totalPrice, setTotalPrice] = useLocalStorage('HSFTOTALPRICE',0);
    const [totalQty, setTotalQty] = useLocalStorage('HSFCARTQTY',0);

    // const persistCart = (cartItems) => {
    //     const cart = cartItems.length > 0 ? cartItems : []
    //     localStorage.setItem("HSFCART", JSON.stringify(cart))
    //     console.log("WORKING")
    // }

    const reset = () => {
        setCartItems([])
        setTotalPrice(0)
        setTotalQty(0)
        // localStorage.removeItem("HSFCART")
    }

    const addToCart = (product, qty) => {
        const productInCartCheck = cartItems.find((item) => item.id === product.id)
        const productSizeCheck = cartItems.find((item) => item.id === product.id && item.size === product.size)

        console.log(qty,product)

        setTotalPrice((prev) => prev + (calculateDiscountPrice(product.price,product.discountRate)) * qty)
        setTotalQty((prev) => prev + qty)


        if(productInCartCheck && productSizeCheck){

            const updatedCart = cartItems.map((cartItem)=> {
                if(cartItem.id === product.id && cartItem.size === product.size) return {
                    ...cartItem, qty: parseFloat(cartItem.qty) + parseFloat(qty)
                } 
                else{
                    return cartItem
                }
            })

            setCartItems(updatedCart)
            // persistCart(updatedCart)


        } else {

            setCartItems([...cartItems,{...product, qty: qty}])
            // persistCart([...cartItems,{...product, qty: qty}])
        }

        setShowCart(true)
    }

    const changeQuantity = (product, qty) => {
        

        const updatedCart = cartItems.map((cartItem) => {
            if(cartItem.id === product.id && cartItem.size === product.size){
                return { ...cartItem, qty: qty }
            }else{
                return cartItem
            }
        })

        setCartItems(updatedCart)
        setTotalPrice(updatedCart.reduce((total, item) => total + (calculateDiscountPrice(item.price,item.discountRate)) * parseInt(item.qty),0))
        setTotalQty(updatedCart.reduce((total,item) => total + parseInt(item.qty),0))

    }

    
    const removeItem = (index, item) => {
        if(index < 0 || index > cartItems.length){
            return
        }
        cartItems.splice(index,1)
        setCartItems(cartItems)
        setTotalPrice((prev) => prev - (calculateDiscountPrice(item.price,item.discountRate)) * item.qty)
        setTotalQty((prev) => prev - item.qty)
    }

    //initial
    // useEffect(()=>{
    //     const unsubscribe =  () => AuthService.userSubscription(setCurrentUser)

    //     unsubscribe()
        
    //     return unsubscribe

    //     // infinite loop here if i add dependencies
    // },[])

    //secondary
    useEffect(()=>{
       const unsubscribe = AuthService.userSubscription(setCurrentUser)

       return () => unsubscribe
    },[])


    const value = {
        addToCart,
        showCart,
        setShowCart,
        showRegistration,
        setShowRegistration,
        totalPrice,
        totalQty,
        cartItems,
        changeQuantity,
        removeItem,
        reset,
        currentUser,
        loadingScreen,
        setLoadingScreen
    }

    return (
        <StateContext.Provider value={value}>
            <CartSidebar/>
            {children}
        </StateContext.Provider>
    )
}