import React, {useEffect} from 'react'
import useStorage from '../../hooks/useStorage'

export const ProgressBar = ({file,setFile,product,setProduct}) => {

  const { url, progress } = useStorage(file,'product-images')
  
  useEffect(()=>{
    if(url){
        // setProduct({...product,image:url})
        setProduct({...product,image:[...product.image, url]})
        setFile(null)
    }
  },[url,setFile,product,setProduct])

  return (
    <div className={'h-1 bg-green-500 my-2'} style={{ width: progress + '%' }}>

    </div>
  )
}
