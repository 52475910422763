import React, {useState} from 'react'
import { StarRating } from './StarRating'
import { useStateContext } from '../../services/contexts/StateContext'
import { calculateDiscountPrice } from '../../services/utilities/calculateDiscountPrice'



export const ProductComponent = ({item}) => {

  const {addToCart} = useStateContext()
  const [selectedIndex, setselectedIndex] = useState(0)

  return (
    <div className='animate-fade-in-up p-5 w-[90vw] sm:w-[400px] h-[500px] flex flex-col mb-14'>
      
      <div className='w-full h-[425px] rounded-tl-2xl rounded-tr-2xl relative'>

        <a href={`/shop/${item.slug}`}>
        {/* <a href={`https://${baseurl}/shop/${item.slug}`}> */}
          <img className=" w-full h-full cursor-pointer ease-linear" src={item.image} alt={`HSF Naturals NG ${item.name}`}/>
        </a>
        
        
        {/* IN STOCK TAG */}
        {item.options[selectedIndex]?.status === 0 ? 
        <div className='absolute text-xs bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white border border-black top-5 left-5 w-30 p-2 text-center rounded-full'>Back Soon</div>
        : item.options[selectedIndex]?.status === 1 ?
        <div className='absolute text-xs bg-gradient-to-b from-yellow-300 via-yellow-400 to-yellow-500 text-white border border-black top-5 left-5 w-30 p-2 text-center rounded-full'>Low Stock</div>
        :null
        }

        {/* DISCOUNT FLAG */}
        {item.options[selectedIndex]?.discountRate > 0 ? 
        <div className='absolute text-xs bg-gradient-to-b from-red-300 via-red-400 to-accent-secondary text-white border border-black top-5 right-5 w-30 px-3 text-center rounded-full py-2'>-{item.options[selectedIndex]?.discountRate}%</div>
        :null
        }

        <div className='absolute bottom-0 right-0 flex flex-col items-end justify-end'>
          {item.options.map((option,index)=>{
          return (
            <label key={index}>
              <input type='radio' className='hidden' value={index} onClick={()=> setselectedIndex(index)}/>
              <div className={`${selectedIndex === index ?`opacity-90 bg-gradient-to-b from-gray-600 via-gray-800 to-black hover:opacity-100 text-white`:`bg-gradient-to-b from-gray-600 via-gray-800 to-black opacity-50 hover:opacity-60 border border-white`} h-10 mb-10  cursor-pointer  pl-5 pr-5 duration-100 transition-transform hover:scale-[1.1] text-center flex items-center justify-center text-xs lg:text-sm`}>{option.size}</div>
            </label>
            )})
          }
        </div>

        <button className='flex items-center justify-center absolute top-[50%] lg:top-[60%] left-[5%] lg:left-[10%] opacity-40 hover:opacity-80 cursor-pointer bg-gradient-to-b from-gray-600 via-gray-800 to-black rounded-full'
        onClick={()=>{
          const product = Object.freeze({
            id: item.id,
            name: item.name,
            image: item.image,
            size: item.options[selectedIndex].size,
            price: item.options[selectedIndex].price,
            weight: item.options[selectedIndex].weight,
            discountRate: item.options[selectedIndex].discountRate,
            qty: 1,
          })
          addToCart(product,1)

          }}
          disabled = {item.options[selectedIndex]?.status === 0}
        >

        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="currentColor" className={`text-white m-10 ${item.options[selectedIndex]?.status === 0 ? ` hidden`:``}`} viewBox="0 0 16 16">
          <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" fill="white"></path>
          <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" fill="white">
          </path>
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="currentColor" className={`text-white m-10 ${item.options[selectedIndex]?.status === 0 ? ``:`hidden`}`} viewBox="0 0 16 16">
          <path d="M7.354 5.646a.5.5 0 1 0-.708.708L7.793 7.5 6.646 8.646a.5.5 0 1 0 .708.708L8.5 8.207l1.146 1.147a.5.5 0 0 0 .708-.708L9.207 7.5l1.147-1.146a.5.5 0 0 0-.708-.708L8.5 6.793 7.354 5.646z"/>
          <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>

        </button>
      </div>
      
      
      
      <div className='w-full bg-white min-h-[75px] grid grid-cols-2 grid-rows-2 text-primary pt-10 z-10'>
        <div className='col-span-1 row-span-1 flex items-center justify-center text-center'>{item.name}</div>
        <div className='col-span-1 row-span-1 flex items-center justify-center'><StarRating size={20} value={item.rating} disabled={true}/></div>
        <div className={`${parseInt(item.options[selectedIndex].discountRate) > 0 ? `col-span-1 line-through decoration-4 decoration-red-500`:`col-span-2`} row-span-1 flex items-center justify-center text-2xl mt-10 h-full`}>&#8358;{item.options[selectedIndex].price}</div>
        <div className={`${parseInt(item.options[selectedIndex].discountRate) > 0 ? `col-span-1`:`hidden`} row-span-1 flex items-center justify-center text-2xl mt-10 h-full`}>
          &#8358;{calculateDiscountPrice(item.options[selectedIndex]?.price,item.options[selectedIndex]?.discountRate)}
        </div>
      </div>


    </div>
  )
}

