import React from 'react'
import { useNavigate } from 'react-router-dom'

export const UsersOrder = ({order}) => {

  const date = order?.created?.toDate().toDateString()
  const navigate = useNavigate()

  return (
    <div className='w-full flex flex-col  cursor-pointer' onClick={()=>navigate(`${order?.id}`)}>
        <div className='w-full grid grid-cols-3 bg-gradient-to-b from-gray-600 via-gray-800 to-black border-b-2 border-gray-700 hover:bg-gray-500'>
            <div className='flex items-center justify-center text-center'>{date}</div>
            <div className={order.status ? 'flex items-center justify-center  bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 md:py-2':'bg-gradient-to-t from-red-300 via-red-400 to-accent-secondary flex items-center justify-center md:py-2'}>{order.status ? "PAID":"UNPAID"}</div>
            <div className='flex items-center justify-center'>N{order?.total}</div>
        </div>
    </div>
  )
}
