import {useState, useEffect} from 'react'


const useDashboardStats = (orders) => {

    const [configData, setConfigData] = useState()
    const [months, setMonths] = useState([
        [],[],[],[],[],[],[],[],[],[],[],[]
    ])
    

    useEffect(()=> {

      const dashboardData = () => {
        var months = [...Array(12)].map(()=>[])
        var dailySales = 0
        var monthlySales = 0
        var yearlySales = 0
        var pendingSales = 0
        var newSales = 0

        const thisDay = new Date().getDay()
        const thisMonth = new Date().getMonth()
        
        
        orders?.forEach( order => {

          let thatDay = new Date(order.created.seconds * 1000).getDay()
          let thatMonth = new Date(order.created.seconds * 1000).getMonth()

          if(!order.viewed){
            newSales++
          }
          if(!order.status){
            pendingSales++
            return
          }

          if(thisDay === thatDay && thisMonth === thatMonth ) {

            dailySales += parseInt(order.subtotal)
            monthlySales += parseInt(order.subtotal)
            yearlySales += parseInt(order.subtotal)

          } else if (thisMonth === thatMonth ) {

            monthlySales += parseInt(order.subtotal)
            yearlySales += parseInt(order.subtotal)

          }else{
            yearlySales += parseInt(order.subtotal)
          }

          switch(thatMonth+1){
            case 1:
                months[thatMonth].push(parseInt(order.subtotal))
            break;
            case 2:
                months[thatMonth].push(parseInt(order.subtotal))
            break;
            case 3:
                months[thatMonth].push(parseInt(order.subtotal))
            break;
            case 4:
                months[thatMonth].push(parseInt(order.subtotal))
            break;
            case 5:
                months[thatMonth].push(parseInt(order.subtotal))
            break;
            case 6: 
                months[thatMonth].push(parseInt(order.subtotal))
            break;
            case 7:
                months[thatMonth].push(parseInt(order.subtotal))
              break;
            case 8:
                months[thatMonth].push(parseInt(order.subtotal))
              break;
            case 9:
                months[thatMonth].push(parseInt(order.subtotal))
              break;
            case 10:
                months[thatMonth].push(parseInt(order.subtotal))
              break;
            case 11:
                months[thatMonth].push(parseInt(order.subtotal))
              break;
            case 12:
                months[thatMonth].push(parseInt(order.subtotal))
              break;
            default:
              return
          }
          
        })

        return {months, dailySales, monthlySales,yearlySales,pendingSales,newSales}
      }
      
      setConfigData({
        netSales: dashboardData().yearlySales,
        unviewedOrders: dashboardData().newSales,
        pendingOrders: dashboardData().pendingSales,
        months: dashboardData().months,
        salesThisMonth: dashboardData().monthlySales,
        salesToday: dashboardData().dailySales,
      })

    },[orders,months])

    return {configData}
}

export default useDashboardStats;