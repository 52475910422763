import React,{useState} from 'react'
import { Outlet,Navigate} from 'react-router-dom'
import { useStateContext } from '../../services/contexts/StateContext'
import { AdminSidebar } from '../../components/admin-components/AdminSidebar'

export const AdminPage = () => {

  const {currentUser} = useStateContext()  
  const [selected, setSelected] = useState()

  return (
    currentUser?.admin === true ?
    <div className='w-[100vw] flex flex-col-reverse md:flex-row'>
        <AdminSidebar selected={selected} setSelected={setSelected}/>
        <Outlet/>
    </div>
    :
    <Navigate to='/'/>
  )
}
