import React from 'react'
import { useNavigate } from 'react-router-dom'

export const AdminUserComponent = ({item}) => {

  const navigate = useNavigate()


  return (
    <>
    <tr onClick={()=> navigate(`/admin/users/${item.id}`)} className={`text-justify border-b border-gray-700 ${(item.viewed && item.status) ? `bg-gray-800 hover:bg-gray-600`:`bg-gray-600 hover:bg-gray-500`} text-white cursor-pointer`}>
        <td className="order-row-item lg:flex justify-between text-xs hidden">
            {item.id}
        </td>
        <td className="order-row-item font-medium text-white whitespace-nowrap">
            {`${item.fname} ${item.lname}`}
        </td>
        <td className="hidden order-row-item font-medium text-white whitespace-nowrap lg:inline-table">
            {item.email}
        </td>
        <td className="order-row-item">
            {item.status ? 
            <span className='bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 text-white px-2 py-1'>ACTIVE</span>
            :<span className='bg-gradient-to-t from-red-300 via-red-400 to-accent-secondary text-white px-2 py-1'>SUSPENDED</span>
            }
        </td>
        <td  className="hidden order-row-item font-medium text-white whitespace-nowrap lg:inline-table">
            {item.phone}
        </td>
    </tr>
    </>
  )
}
