import React,{useEffect,useState,useRef} from 'react'
import DataService from '../../../services/providers/data.service'
import {SearchUsers} from '../../../services/utilities/arraySearch'
import {AdminUserComponent} from '../../../components/admin-components/AdminUserComponent'


export const UsersPage = () => {
    
    const [users, setUsers] = useState([])
    const [hide, setHide] = useState(false)
    const [loading, setLoading] = useState(true)
    const [filter, setFilter] = useState(false)
  
    var latestDoc = useRef(null)


  useEffect(()=>{
    
    const getData = async () => {
      const data = await DataService.getLimitedDocuments('users',10,latestDoc.current)
      setUsers((o)=> {
          return [...o,...data.docs.map((doc) => ({...doc.data(),id: doc.id}) )]
      })
      latestDoc.current = data.docs[data.docs.length - 1]

      if(data.empty){
          setHide(true)
      }
    }

    if(loading && !filter){
        getData()
        setLoading(!loading)
    }

  },[loading,filter])


  const handleFilter = async (e) => {
    //get orders by created date
  const p = await DataService.getAllDocuments("users")
  setFilter(true)
  setHide(true)
  let data = p.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id
      }))

  const q = SearchUsers(data,e.target.value)
  if(e.target.value === '' || e.target.value === null){
      setUsers(data)
      setLoading(true)
  }else{
      setUsers(q)
  }
}
  
  return (
    <div className='w-full min-h-[100vh] flex flex-col items-center bg-background pb-[25vh]'>
       <span className='text-white text-5xl bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 px-10 py-5 mt-10 border-primary border-2 rounded-2xl'>Users</span>

        {/* search bar => should include button? */}
        <input type='text' placeholder='Search...' className='text-black w-[80%] sm:w-[20rem] bg-white mt-10' onChange={handleFilter}/>

        {/* DYNAMIC CONTENT CONTAINER */}
     <div className='container flex felx-col flex-wrap my-10'>
                
                <div className='w-[100%] pb-[5rem] overflow-hidden'>
                    <table className="w-[100%] text-sm text-left text-white">
                        <thead className="text-xs text-white uppercase bg-black">
                            <tr>
                                <th scope="col" className="order-row-item hidden lg:inline-block">
                                    User ID
                                </th>
                                <th scope="col" className="order-row-item">
                                    Customer Name
                                </th>
                                <th scope="col" className="order-row-item hidden lg:inline-block">
                                    User Email
                                </th>
                                <th scope="col" className="order-row-item pr-20 md:pr-0">
                                    Status
                                </th>
                                <th scope="col" className="order-row-item hidden lg:inline-table">
                                    Phone Number
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* INDIVIDUAL ITEM */}
                            {users?.map((item,index)=>(
                                <AdminUserComponent key={index} index={index} item={item} />
                            ))}
                        </tbody>
                    </table>
                    {!hide &&  <button className='text-center my-3 bg-gradient-to-b from-gray-700 via-gray-900 to-black shadow-md hover:shadow-inner hover:shadow-black w-[100%] h-10 rounded-full text-white' onClick={()=> setLoading(!loading)}>LOAD MORE</button>}
                      <div className='min-h-[20rem]'> </div>
              </div>
            </div>
                {/* TABLE ENDS HERE */}
            
    </div>
  )
}
