import React, {useState,useEffect} from 'react'

// can only go up to z-20

export const CarouselComponent = ({initialStack}) => {

  const stack = initialStack
  const [pos, setPos] = useState(0)
  const [current, setCurrent] = useState(pos)

  const shiftNext = () => {
    if(pos < (stack.length - 1)){
        setPos(prevState => prevState + 1)
    }

    if(pos === (stack.length - 1)){
        setPos(0)
    }
  }

  const shiftPrev = () => {
    if(pos > 0){
        setPos(prevState => prevState - 1)
    }

    if(pos === 0){
        setPos(stack.length - 1)
    }

  }

  useEffect(()=>{
    setCurrent(pos)
  },[pos])

  useEffect(() => {
    const interval = setInterval(() => {
      if(pos < stack.length-1){
          setPos(pos+1)
      }else if(pos === stack.length-1){
          setPos(0)
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [stack.length,pos]);


  return (
    <div className='relative h-0 pb-[100%] lg:pb-[45%]'>

        {/* image overlay */}
        <img className='absolute inset-0 w-full h-full object-cover'
        src={stack[current].hero}
        alt={stack[current].alt}/>

        {/* shift left chevron */}
        <div className='absolute flex items-center justify-center cursor-pointer w-14 lg:w-24 opacity-10 hover:opacity-30 top-0 left-0 bg-black h-full'
        onClick={()=> shiftPrev()}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className="hover:opacity-100 " viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                <path fillRule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/> 
            </svg>
        </div>

        {/* shift right chevron */}
        <div className='absolute flex items-center justify-center cursor-pointer w-14 lg:w-24 opacity-10 hover:opacity-30 top-0 right-0 bg-black h-full'
        onClick={()=> shiftNext()}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className="hover:opacity-100 " viewBox="0 0 16 16"> 
                <path fillRule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/> 
                <path fillRule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/> 
            </svg>
        </div>

        <div className='absolute bottom-[10%] left-[50%] translate-x-[-50%] bg-white text-primary w-[75%] lg:w-[50%] opacity-80'>
            <div className='w-full flex flex-col items-center text-xl lg:text-2xl'>
                <h3 className='w-[90%] text-center'>
                    {stack[current].tag1}
                </h3>
                <h3 className='w-[90%] text-center'>
                    {stack[current].tag2}
                </h3>
                <h3 className='w-[90%] text-center'>
                    {stack[current].tag3}
                </h3>
                <a href={stack[current].link} className='w-[50%] opacity-100 text-base cursor-pointer lg:text-xl text-white bg-black  my-3 px-5 py-1 flex items-center justify-center text-center'>
                        {"Shop Now"}
                </a>
            </div>
        </div>
        {/* <div className='absolute bottom-[10%] lg:bottom-[40%] md:bottom-[30%] md:right-[20%] right-[10%] lg:right-[20%]'>
            <div className='flex flex-col items-center'>
                <div className='text-xl md:text-3xl lg:text-5xl text-white bg-[rgba(255,255,255,0.4)] shadow-[_0px_2px_5px_rgba(0,0,0,0.3)] drop-shadow-[_2px_2px_#000000]  mb-2 px-5 py-1 flex items-center justify-center text-center'>
                    {stack[current].tag1}
                </div>
                <div className='text-xl md:text-3xl lg:text-5xl text-white bg-[rgba(255,255,255,0.4)] shadow-[_0px_2px_5px_rgba(0,0,0,0.3)] drop-shadow-[_2px_2px_#000000]  mb-2 px-5 py-1 flex items-center justify-center text-center'>
                    {stack[current].tag2}
                </div>
                <div className='text-xl md:text-3xl lg:text-5xl text-white bg-[rgba(255,255,255,0.4)] shadow-[_0px_2px_5px_rgba(0,0,0,0.3)] drop-shadow-[_2px_2px_#000000]  mb-2 px-5 py-1 flex items-center justify-center text-center'>
                    {stack[current].tag3}
                </div>
                <a href={stack[current].link} className='w-[15rem] text-base cursor-pointer border-2 border-background bg-[rgba(255,255,255,0.4)] shadow-[_0px_2px_5px_rgba(0,0,0,0.3)] drop-shadow-[_2px_2px_#000000] hover:shadow-inner hover:shadow-black lg:text-2xl text-white bg-black rounded-full  mb-2 px-5 py-1 flex items-center justify-center text-center'>
                    {"Shop Now"}
                </a>
                
            </div>
        </div> */}

    </div>
  )
}
