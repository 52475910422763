import React, {useState} from 'react'


export const CustomEmailModal = ({setOpen,runFunction}) => {

  const [email, setEmail] = useState({
    subject: "",
    message: "",
  })

  const handleChange = (e) => {
    setEmail({
        ...email,
        [e.target.name]: e.target.value,
    })
  }


  return (
    <>
            <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-full h-full">
                
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none">
                  
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 rounded-t">
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setOpen((open)=>!open)}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block">
                        X
                      </span>
                    </button>
                  </div>


                  {/*body*/}
                  <div className="relative p-6 flex flex-col h-[80%] items-center justify-center text-black">
                    
                    <input type='text' className='w-full h-10' name='subject' onChange={handleChange} placeholder='Subject'/>
                    <textarea className='w-full' rows={10} name='message' onChange={handleChange}/>

                  </div>


                  {/*footer*/}
                  <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="bg-gray-600 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setOpen((open)=>!open)}
                    >
                      CANCEL
                    </button>
                    <button
                      className="bg-teal-600 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={(e)=>runFunction(e,email.subject,email.message)}
                    >
                      SEND MAIL
                    </button>
                  </div>


                </div>
              </div>
              </div>
            <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
          </>
  )
}
