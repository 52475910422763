import React from 'react'
import { useNavigate } from 'react-router-dom'

export const AdminOrderComponent = ({setOpen,item,setLoadIndex,index}) => {

  const timestamp = item?.created.seconds
  const navigate = useNavigate()
  
  const getDate = () => {
    const date = new Date(timestamp*1000)
      return date.getDate()+
      "/"+(date.getMonth()+1)+
      "/"+date.getFullYear()+
      " "+date.getHours()+
      ":"+date.getMinutes()+
      ":"+date.getSeconds()
  }

 

  return (
    <>
    <tr onClick={()=> navigate(`/admin/orders/${item.orderID}`)} className={`text-justify border-b border-gray-700 ${(item.viewed && item.status) ? `bg-gray-800 hover:bg-gray-600`:`bg-gray-600 hover:bg-gray-500`} text-white cursor-pointer`}>
        <td className="order-row-item flex justify-between">
            {getDate()}
            <span className={item.viewed ? 'hidden':'text-yellow-500 font-semibold'}>NEW</span>
        </td>
        <td className="order-row-item font-medium text-white whitespace-nowrap">
            {item.orderID}
        </td>
        <td className="hidden order-row-item font-medium text-white whitespace-nowrap lg:inline-table">
            {item?.customerFName} {item?.customerLName}
        </td>
        <td className="order-row-item">
            {item.status ? 
            <span className='bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 text-white px-2 py-1'>PAID</span>
            :<span className='bg-gradient-to-t from-red-300 via-red-400 to-accent-secondary text-white px-2 py-1'>UNPAID</span>
            }
        </td>
        <td  className="hidden order-row-item font-medium text-white whitespace-nowrap lg:inline-table">
            N{item.total}
        </td>

    </tr>
    </>
  )
}
