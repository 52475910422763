import React from 'react'
import { StarRating } from './StarRating'
import {slugify} from '../../services/utilities/slugify'


export const TestimonialScrollBar = ({testimonials,reveal}) => {

  
  const baseurl = process.env.REACT_APP_PRODUCTION_URL


  return (
    <div className='absolute w-full left-[1%] top-[0%] h-full overflow-x-scroll whitespace-nowrap pl-5 pb-5 mt-5 overflow-y-visible'>
        {testimonials?.map((review,index) => (
            <div key={index}
            className={`w-[95%] md:w-[30rem] h-full mr-[30px] inline-block text-black`}
            >
            <div className={`${reveal ? `opacity-100`:`opacity-0 translate-y-[10rem]`} transition duration-300 ease-linear flex flex-col h-full w-full items-center py-2 relative overflow-visible`}>
                
                <div className='w-full h-[15%] flex items-center justify-center'>
                    <a 
                    href={`${baseurl}/shop/${slugify(review.productName)}`}
                    className='w-full h-[70%] flex justify-center items-center cursor-pointer'>
                        <img src={review.productImage} className='w-32 h-32 z-10 translate-y-[30%] mx-1 border-black border-2 rounded-full' alt={`HSF Naturals NG ${review.productName}`}/>
                    </a>
                </div>

                <div className='w-full h-[70%] bg-gray-100 flex items-end justify-center'>

                    <div className='w-full h-[80%] flex flex-col items-center pt-5'>
                       <span className='w-full h-[20%] text-center'>
                        <span className='w-full h-[50%]'>
                            <a 
                            href={`${baseurl}/shop/${slugify(review.productName)}`}
                            className='text-xl translate-y-[-10%] break-normal inline-block text-center whitespace-normal'>
                                {review.productName}
                            </a>
                        </span>
                        <span className='w-full h-[50%] grid grid-cols-5 text-gray-700'>
                            <section className='col-span-3'>
                                -{review.name}
                            </section>
                            <section className='col-span-2'>
                                {review.date}
                            </section>
                        </span>
                       </span>
                       <span className='w-full h-[80%] flex items-center justify-center mt-3 z-10'>
                        <p className='w-[90%] break-normal inline-block text-center whitespace-normal limit-text'>
                            {review.review}
                        </p>
                       </span>
                    </div>

                </div>

                <div className='w-full h-[15%] flex items-center justify-center bg-gray-100'>
                    <StarRating size={30} value={review.rating} disabled={true}/>
                </div>

            </div>
            
            </div>
        ))}
    </div>
  )
}

