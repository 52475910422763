import React from 'react'
import {MdCancel} from 'react-icons/md'
import { useStateContext } from '../../services/contexts/StateContext'


export const CartProductComponent = ({item,index}) => {
    
    const {changeQuantity, removeItem} = useStateContext()
    
    const valueHandler = (e) =>{
        changeQuantity(item,e.target.value)
    }
    const deleteHandler = () => {
        removeItem(index,item)
    }

  return (
    // {size==='lg' ? '':''}
    <div className='w-full lg:w-[90%] mb-3 flex flex-row border-white border-2'>
        {/* IMAGE THUMBNAIL */}

        <img src={item?.image} alt='' className='lg:max-w-[200px] max-w-[150px] lg:max-h-[200x] max-h-[150x]'/>

        <div className='w-full flex border-t border-b border-black'>
            <div className='w-[80%] h-full flex flex-col items-center justify-center bg-white'>
                <h4 className={'w-[75%] text-center text-lg mb-3 text-black underline bg-white px-2 rounded-full mt-3'}>{item?.name}</h4>
                <h4 className={'text-center text-lg mb-5 text-black bg-white px-2'}>{item?.size}</h4>
                <div className='w-full flex flex-row justify-center'>
                    <h4 className='w-full h-10 text-center text-lg text-black'>&#8358;{item?.price}</h4>
                    {item?.discountRate > 0 ? <div className='w-full h-10 text-center text-lg text-black'>
                        <span className={'px-5 py-1 bg-gradient-to-b from-red-300 via-red-400 to-accent-secondary text-white rounded-full'}>-{item?.discountRate}%</span>
                    </div>: null}
                    
                </div>
                <div className='border-2 border-gray-200 text-black mb-2 flex w-20'>
                    <select id='Qty' className='w-full px-5 focus:outline-none' value={item?.qty} onChange={(e)=> valueHandler(e)}>
                        {[...Array(30)].map((val,i)=>{
                            let value = i + 1
                            return <option key={i} value={value}>{value}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className='w-[20%] h-full flex items-center justify-center bg-gradient-to-b from-gray-600 via-gray-800 to-black'>
                <MdCancel size={25} onClick={deleteHandler} className={'w-[50%] h-[50%] hover:text-red-600 cursor-pointer'}/>
            </div>
        </div>
    </div>
  )
}
