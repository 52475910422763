import React,{useEffect,useState,useRef} from 'react'
import { Link } from 'react-router-dom'
import DataService from '../../../services/providers/data.service'
import {SearchProducts} from '../../../services/utilities/arraySearch'
import {AdminProductComponent} from '../../../components/admin-components/AdminProductComponent'
// import { deleteField } from 'firebase/firestore'

export const ProductsPage = () => {

  const [products, setProducts] = useState([])
  const [hide, setHide] = useState(false)
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState(false)


  var latestDoc = useRef(null)

  useEffect(()=>{
    
    const getData = async () => {
      const data = await DataService.getLimitedDocuments('products',9,latestDoc.current)
      setProducts((o)=> {
          return [...o,...data.docs.map((doc) => ({...doc.data(),id: doc.id}) )]
      })
      latestDoc.current = data.docs[data.docs.length - 1]

      if(data.empty){
          setHide(true)
      }
    }

    if(loading && !filter){
        getData()
        setLoading(!loading)
    }

  },[loading,filter])

  const handleFilter = async (e) => {
    //get orders by created date
  
    const p = await DataService.getAllDocuments("products")
    setFilter(true)
    setHide(true)
    let data = p.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
        }))

    const q = SearchProducts(data,e.target.value)
    if(e.target.value === '' || e.target.value === null){
        setProducts(data)
        setLoading(true)
    }else{
        setProducts(q)
    }
  }


  return ( 
    <div className='w-full min-h-[100vh] flex flex-col items-center bg-background pb-[25vh]'>

      <span className='text-white text-5xl bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 px-10 py-5 mt-10 border-primary border-2 rounded-2xl'>Products</span>
      {/* search bar => should include button? */}
      <input type='text' placeholder='Search...' className='text-black w-[80%] sm:w-[20rem] bg-white mt-10' onChange={handleFilter}/>

      <div className='w-full inline-flex justify-evenly items-center'>
        {/* more buttons can be added in  */}
        <Link to={'create-product'} >
          <button className='bg-gradient-to-b from-emerald-400 via-emerald-600 to-emerald-800 hover:shadow-inner shadow-black rounded-full text-xl border-2 border-black px-10 py-1 shadow-md mt-10'>ADD PRODUCT</button>
        </Link>
      </div>

      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 mt-10'>
        {/* INDIVIDUAL ITEM */}
        {products.map((item,index)=>(<AdminProductComponent key={index} index={index} item={item} setProducts={setProducts} products={products}/>))}

      </div>
      {!hide &&  <button className='text-center my-3 bg-gradient-to-b from-gray-700 via-gray-900 to-black shadow-md hover:shadow-inner hover:shadow-black w-[75%] h-10 rounded-full text-white' onClick={()=> setLoading(!loading)}>LOAD MORE</button>}
    </div>
  )
}
