import {useState, useEffect} from 'react'


const useDailyStats = (orders) => {

    const [configData, setConfigData] = useState()

    

    useEffect(()=> {
        
    const dailySalesData = () => {
        
        var sales = 0
        var numSold = 0
        var numOrders = orders?.length
        var dailyProductsStats = []
        
        orders?.forEach( order => {
            
            sales += parseInt(order.subtotal)
            
            order.userCart.forEach( product => {
                numSold += parseInt(product.qty)
                
                const itemInList = dailyProductsStats.find((item) => product.id === item.id)
                const itemSizeInList = dailyProductsStats.find((item) => product.id === item.id && item.size === product.size)
                
                if(itemInList && itemSizeInList){
                    dailyProductsStats.forEach((p,i) => {
                        if(p.id === product.id && p.size === product.size){
                            dailyProductsStats[i].totalSold += parseInt(product.qty)
                            dailyProductsStats[i].orders.push({qtySold: parseInt(product.qty), orderID: order.orderID})
                        }
                    })
                }else{
                    dailyProductsStats.push({
                        id: product.id,
                        name: product.name,
                        totalSold: parseInt(product.qty),
                        image: product.image,
                        size: product.size,
                        orders: [
                            {
                                qtySold: parseInt(product.qty),
                                orderID: order.orderID,
                            }
                        ]
                    })
                }
            })
        })
        var stats = dailyProductsStats.sort((a,b) => b.totalSold-a.totalSold)
        return {sales,numOrders,numSold,stats}
    }
    
    setConfigData({
        thisDaySales: dailySalesData().sales,
        numOrders: dailySalesData().numOrders,
        totalItemsSold: dailySalesData().numSold,
        productStats: dailySalesData().stats,

    })

    },[orders])

    return {configData}
}

export default useDailyStats;