import React, {useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import DataService from '../../services/providers/data.service'
import {AiFillCloseCircle as Close} from 'react-icons/ai'
import { Validate32CharString,Validate256CharString } from '../../services/utilities/errorHandler'
import {BsFillTrashFill} from 'react-icons/bs'
import ShippingUpdateComponent from '../../components/general-components/ShippingUpdateComponent'
import { ConfirmationModal } from '../../components/general-components/ConfirmationModal'




export const SettingsPage = () => {

  const [loading,setLoading] = useState(true)
  const [config, setConfig] = useState()
  const [shipping, setShipping] = useState()
  const [faqCatIndex, setFaqCatIndex] = useState(null)

  

  useEffect(()=>{
    
    const getConfig = async () => {
      const data = await DataService.getDocument("config","settings")
      setConfig(data.data())
    }

    getConfig()

  },[])

  useEffect(() => {

    const getShipping = async () => {
      const data = await DataService.getAllShipping()
      setShipping([...data.docs.map(item => ({id:item.id, ...item.data()}))])
    }

    if(loading){
      getShipping()
      setLoading(false)
    }

  },[loading])


  const handleUpdateBanner = async (e) => {
    e.preventDefault()
    try{
      let errors = Validate256CharString(config.messageBanner.message)
      if(errors.length > 0){
        throw new Error(errors)
      }
      await DataService.addDocumentByID('config','settings',config)
      toast.success(`Banner Has Been Successfully Updated`);
    } 
    catch(err){
      toast.error(err.message)
    }
  }

  const handleMessageChange = (e) => {
    setConfig({
      ...config,
      messageBanner: {
        ...config.messageBanner,
        message: e.target.value.trim()
      }
    })
  }

  const addCategory = async (e) => {
    e.preventDefault()
    const name = e.target.name.value.trim()
    config?.categories.push(name)
    
    try{
      let errors = Validate32CharString(name)
      if(errors.length > 0){
        config?.categories.pop()
        throw new Error(errors)
      }
      await DataService.addDocumentByID('config','settings',config)
      .then(() => {
        setConfig({...config})
      })
      document.getElementById("categoryForm").reset()
      toast.success(`Category "${name}" Successfully Created`);
    }catch(err){
      toast.error(err.message)
    }

  }

  const addTag = async (e) => {
    e.preventDefault()
    const name = e.target.name.value.trim()
    config?.tags.push(name)
    try{
      let errors = Validate32CharString(name)
      if(errors.length > 0){
        config?.tags.pop()
        throw new Error(errors)
      }
      await DataService.addDocumentByID('config','settings',config)
      .then(() => {
        setConfig({...config})
      })
      document.getElementById("tagForm").reset()
      toast.success(`Tag "${name}" Successfully Created`);
    }catch(err){
      toast.error(err.message)
    }
  }

  const handleDeleteCategory = async (index) => {
    const catName = config?.categories[index]
    config?.categories.splice(index,1)
    try{
      await DataService.addDocumentByID('config','settings',config)
      .then(() => {
        setConfig({...config})
      })
    }catch(err){
      toast.error(err.message)
    }finally{
      toast.success(`Category "${catName}" Deleted`);
      }
  }

  const handleDeleteTag = async (index) => {
    const tagName = config?.tags[index]
    config?.tags.splice(index,1)
    try{
      await DataService.addDocumentByID('config','settings',config)
      .then(() => {
        setConfig({...config})
      })
    }catch(err){
      toast.error(err.message)
    }finally{
      toast.success(`Tag "${tagName}" Deleted`);
      }
  }

  const addFaqCat = async (e) => {
    e.preventDefault()
    const name = e.target.name.value.trim()
    config?.faqData.push({
      category: name,
      faqs: []
    })

    try{
      let errors = Validate32CharString(name)
      if(errors.length > 0){
        config?.faqData.pop()
        throw new Error(errors)
      }
      await DataService.addDocumentByID('config','settings',config)
      .then(() => {
        setConfig({...config})
      })
      document.getElementById("faqCatForm").reset()
      toast.success(`FAQ Category "${name}" Successfully Created`);
    }catch(err){
      toast.error(err.message)
    }

  }

  const handleDeleteFaqCat = async (index) => {
    const catName = config?.faqData[index].category
    config?.faqData.splice(index,1)

    try{
      await DataService.addDocumentByID('config','settings',config)
      .then(() => {

        //problem with the css itll invoke the second part of the product faq's
        //but with an empty index location because it was just deleted
        setFaqCatIndex(null)
        setConfig({...config})
      })
    }catch(err){
      toast.error(err.message)
    }finally{
      toast.success(`FAQ Category "${catName}" Successfully Deleted`);
    }
  }

  const addNewFaq = async (e) => {
    e.preventDefault()

    config?.faqData[faqCatIndex]?.faqs.push({
      question: e.target.question.value.trim(),
      answer: e.target.answer.value.trim(),
    })

    try{
      let error1 = Validate256CharString(e.target.question.value.trim())
      if(error1.length > 0){
        config?.faqData[faqCatIndex].faqs.pop()
        throw new Error(`FAQ Question ${error1}`)
      }
      let error2 = Validate256CharString(e.target.answer.value.trim())
      if(error2.length > 0){
        config?.faqData[faqCatIndex].faqs.pop()
        throw new Error(`FAQ Answer ${error2}`)
        
      }
      await DataService.addDocumentByID('config','settings',config)
      .then(() => {
        setConfig({...config})
      })
      document.getElementById("faqForm").reset()
      toast.success(`New FAQ Added To "${config?.faqData[faqCatIndex]?.category}" FAQ Category`);
    }catch(err){
      toast.error(err.message)
    }

  }

  const handleDeleteFaq = async (index) => {
    config?.faqData[faqCatIndex].faqs.splice(index,1)

    try{
      await DataService.addDocumentByID('config','settings',config)
      .then(() => {
        setConfig({...config})
      })
    }catch(err){
      toast.error(err.message)
    }finally{
      toast.success(`FAQ #${index} from "${config?.faqData[faqCatIndex]?.category}" FAQ Category Deleted`);
    }
  }

  const addLocation = async (e) => {
    e.preventDefault()


    try{

      const newLocation = {
        city: e.target.city.value.trim().toLowerCase(),
        state: e.target.state.value.trim().toLowerCase(),
        country: e.target.country.value.trim().toLowerCase(),
        rate: parseInt(e.target.rate.value.trim()),
      }

      await DataService.addDocument("shipping", newLocation)
      .then(()=>{
        toast.success(`Location ${newLocation.city.toUpperCase()}, ${newLocation.state.toUpperCase()}, ${newLocation.country.toUpperCase()} has been sucessfully added`)
      })
      setLoading(true)
      document.getElementById("shippingForm").reset()
      
    }catch(err){
      toast.error(err.message)
    }
  }

  const removeLocation = async (id,index) => {
    try{
      await DataService.deleteDocument("shipping",id)
      .then(() => {
        toast.success(`Location has been sucessfully removed`)
      })
      setLoading(true)
    }catch(err){
      toast.error(err.message)
    }

  }
  
  const handleFilter = async (e) => {
    //get orders by created date
    const p = await DataService.getAllShipping()
    let data = p.docs.map(item => ({id:item.id, ...item.data()}))
    
    const SearchShipping = (array,keyword) => {
      //might not need one of the lines below for slug match
      if(!array) return
      return array.filter(value => {
          return value.city?.toLowerCase().match(new RegExp(keyword,'g')) ||
          value.city?.match(new RegExp(keyword, "g")) ||
          value.id?.toLowerCase().match(new RegExp(keyword, "g")) ||
          value.id?.match(new RegExp(keyword, "g")) ||
          value.state?.toLowerCase().match(new RegExp(keyword, "g")) ||
          value.state?.match(new RegExp(keyword, "g")) ||
          value.country?.toLowerCase().match(new RegExp(keyword, "g")) ||
          value.country?.match(new RegExp(keyword, "g"))
      })
    }

    const q = SearchShipping(data,e.target.value)
    if(e.target.value === '' || e.target.value === null){
        setShipping(data)
        setLoading(true)
    }else{
        setShipping(q)
    }
  }
  


  return (

    <div className='min-h-[100vh] w-full flex flex-col items-center bg-background pb-[25vh] text-black'>
      {/* MESSAGE BANNER */}
       
      <span className='w-full bg-gradient-to-b from-gray-700 via-gray-900 to-black mb-10 text-white text-center text-2xl py-5'>
        {"HOMEPAGE MESSAGE BANNER"}
      </span>


      {/* MESSAGE BANNER HANDLER */}
      <div className='w-full lg:w-[50vw] h-[40vh] bg-gradient-to-t from-gray-700 via-gray-900 to-black flex flex-col justify-center items-center mb-10 py-10 border-black border-4'>
        <div className='w-[75%] lg:w-[30%] flex justify-center items-center mb-5'>
          <div className={config?.messageBanner.show ? 'w-[40%] h-10 bg-gradient-to-b from-emerald-400 via-emerald-600 to-emerald-800 flex items-center justify-center text-white cursor-pointer':'w-[40%] h-10 bg-slate-500 flex items-center justify-center text-black cursor-pointer'} onClick={()=> setConfig({...config,messageBanner: {...config.messageBanner, show:true}})}>ON</div>
          <div className={config?.messageBanner.show ? 'w-[40%] h-10 bg-slate-500 flex items-center justify-center text-white cursor-pointer':'w-[40%] h-10 bg-gradient-to-b from-red-400 via-red-600 to-red-800 flex items-center justify-center text-black cursor-pointer'} onClick={()=> setConfig({...config,messageBanner: {...config.messageBanner, show:false}})}>OFF</div>
        </div>
        <textarea rows={5} className='w-full lg:w-[70%] mb-5 text-center text-black' defaultValue = {config?.messageBanner.message} onChange={handleMessageChange}/>
        <button className='px-8 py-2 bg-gradient-to-b from-emerald-400 via-emerald-600 to-emerald-800 shadow-md hover:shadow-inner shadow-black text-white' onClick={handleUpdateBanner}>SAVE</button>
      </div>

      {/* DISPLAY AND INPUT FOR CATEGORIES */}
      <span className='w-full bg-gradient-to-b from-gray-700 via-gray-900 to-black mb-10 text-white text-center text-2xl py-5'>
      {"PRODUCT CATEGORY SETTINGS"}
      </span>

      <div className='w-full lg:w-[50vw]  bg-gradient-to-t from-gray-700 via-gray-900 to-black flex flex-col lg:flex-row justify-evenly items-center mb-10 py-10 border-black border-4'>
        <div className='w-[80%] lg:w-[45%] h-[13vh] overflow-y-scroll border-black border-4'>
          {config?.categories.map((cat,i) => (
            <div key={i} className='w-full bg-white border-b border-black'>
              <div className='w-full hover:bg-slate-300 cursor-pointer'>
                <div className='w-full inline-flex items-center justify-between'>
                  <div className='w-[80%]'>{cat}</div>
                  <div className='w-[15%] inline-flex items-center'>
                    <Close size={20} className='hover:text-red-700' onClick={()=>handleDeleteCategory(i)}/>
                  </div>
                </div>
              </div> 
            </div>
          ))}
        </div>

        <form id='categoryForm' className='w-[75%] lg:w-[45%] h-full flex flex-col items-center justify-center mt-2' onSubmit={addCategory}>
          <input name="name" type='text' className='text-center text-sm rounded-lg w-full p-2 border-2 border-black' />
          <button className='w-[70%] rounded-lg bg-gradient-to-t from-red-300 via-red-400 to-accent-secondary shadow-md hover:shadow-inner shadow-black text-white mt-5 py-2'>ADD</button>
        </form>
      </div>


        

      {/* DISPLAY AND INPUT FOR TAGS */}
      <span className='w-full bg-gradient-to-b from-gray-700 via-gray-900 to-black mb-10 text-white text-center text-2xl py-5'>
      {"PRODUCT TAG SETTINGS"}
      </span>

      <div className='w-full lg:w-[50vw] bg-gradient-to-t from-gray-700 via-gray-900 to-black flex flex-col lg:flex-row justify-evenly items-center mb-10 py-10 border-black border-4'>
        <div className='w-[80%] lg:w-[45%] h-[13vh] overflow-y-scroll border-black border-4'>
          {config?.tags.map((tag,i) => (
            <div key={i} className='w-full bg-white border-b border-black'>
              <div className='w-full hover:bg-slate-300 cursor-pointer'>
                <div className='w-full inline-flex items-center justify-between'>
                  <div className='w-[80%]'>{tag}</div>
                  <div className='w-[15%] inline-flex items-center'>
                    <Close size={20} className='hover:text-red-700' onClick={()=>handleDeleteTag(i)}/>
                  </div>
                </div>
              </div> 
            </div>
          ))}
        </div>

        <form id='tagForm' className='mt-2 w-[75%] lg:w-[45%] h-full flex flex-col items-center justify-center' onSubmit={addTag}>
          <input name="name" type='text' className='text-center text-sm rounded-lg w-full p-2 border-2 border-black' />
          <button className='w-[70%] rounded-lg bg-gradient-to-t from-red-300 via-red-400 to-accent-secondary shadow-md hover:shadow-inner shadow-black text-white mt-5 py-2'>ADD</button>
        </form>
      </div>
      
      <span className='w-full bg-gradient-to-b from-gray-700 via-gray-900 to-black mb-10 text-white text-center text-2xl py-5'>
      {"FAQ SETTINGS"}
      </span>
      
      {/* FAQCAT NAME CRUD HANDLER */}
      <div className='w-full lg:w-[50vw] bg-gradient-to-t from-gray-700 via-gray-900 to-black flex flex-col lg:flex-row justify-evenly items-center mb-10 py-10 border-black border-4'>
        <div className='w-[80%] lg:w-[45%] h-[13vh] overflow-y-scroll border-black border-4'>
          {config?.faqData.map((faqCat,i) => (
            <div key={i} className='w-full bg-white border-b border-black' onClick={()=> setFaqCatIndex(i)}>
              <div className={faqCatIndex === i ? 'w-full bg-slate-300 cursor-pointer':'w-full hover:bg-slate-300 cursor-pointer'}>
                <div className='w-full inline-flex items-center justify-between'>
                  <div className='w-[80%]'>{faqCat?.category}</div>
                  <div className='w-[15%] inline-flex items-center'>
                    <Close size={20} className='hover:text-red-700 z-10' onClick={()=>handleDeleteFaqCat(i)}/>
                  </div>
                </div>
              </div> 
            </div>
          ))}
        </div>

        <form id='faqCatForm' className='mt-2 w-[75%] lg:w-[45%] h-full flex flex-col items-center justify-center' onSubmit={addFaqCat}>
          <input name="name" type='text' className='text-center text-sm rounded-lg w-full p-2 border-2 border-black' />
          <button type='submit' className='w-[70%] rounded-lg bg-gradient-to-t from-red-300 via-red-400 to-accent-secondary shadow-md hover:shadow-inner shadow-black text-white mt-5 py-2'>ADD</button>
        </form>
      </div>

      {faqCatIndex !== null && 
        <div className='w-full lg:w-[50vw] h-[80vh] bg-gradient-to-t from-gray-700 via-gray-900 to-black flex flex-col justify-evenly items-center mb-10 py-10 border-black border-4'>
          <div className='w-[75%] h-[50%] overflow-y-scroll border-black border-4 bg-gray-400'>
          {config?.faqData[faqCatIndex]?.faqs?.map((faqCat,i) => (
            <div key={i} className='w-full bg-white border-b border-black'>
              <div className={'w-full hover:bg-slate-300 cursor-pointer'}>
                <div className='w-full inline-flex items-center justify-between'>
                  <div className='w-[80%]'>{faqCat?.question}</div>
                  <div className='w-[15%] inline-flex items-center'>
                    <Close size={20} className='hover:text-red-700' onClick={()=>handleDeleteFaq(i)}/>
                  </div>
                </div>
              </div> 
            </div>
          ))}
        </div>
        <form id='faqForm' className=' w-[75%] lg:w-[full] flex flex-col items-center justify-center mt-5' onSubmit={addNewFaq}>
          <label className='w-full text-white'>
          FAQ Question:
            <input name="question" type='text' className='text-primary text-center text-sm rounded-lg w-full p-2 border-2 border-black' />
          </label>
          <label className='w-full text-white'>
            FAQ Answer:
            <textarea name="answer" rows={8} type='text' className='text-primary text-sm rounded-lg w-full p-2 border-2 border-black' />
          </label>
          <button className='w-[50%] rounded-lg bg-gradient-to-t from-red-300 via-red-400 to-accent-secondary shadow-md hover:shadow-inner shadow-black text-white mt-5 py-2'>ADD</button>
        </form>
        </div>
      }


    <span className='w-full bg-gradient-to-b from-gray-700 via-gray-900 to-black mb-10 text-white text-center text-2xl py-5'>
      {"SHIPPING SETTINGS"}
    </span>
    

    <div className='w-full lg:w-[50vw] bg-gradient-to-t from-gray-700 via-gray-900 to-black flex flex-col lg:flex-row justify-evenly items-center mb-10 py-10 border-black border-4'>
      <div className='w-[100%] h-[70vh] overflow-y-scroll border-black border-4'>
        {shipping?.map((location,index) => (
          <div id={location.id} key={index} className='w-full min-h-32 bg-white flex flex-col items-center justify-center border-y-2 border-black relative py-10'>
            <div className='absolute w-full flex flex-row top-2 left-5'>
              <label>{`Location # - ${index+1}`}</label>
            </div>
            <div className='w-full flex flex-col items-center justify-center relative h-[80%]'>
              <h1 className='w-[90%] h-10 lg:w-[60%] text-primary text-center text-sm p-2 border-b-2 border-black mb-2 lg:mx-2'>
                  City: {location.city.toUpperCase()}
              </h1>
              <h1 className='w-[90%] h-10 lg:w-[60%] text-primary text-center text-sm p-2 border-b-2 border-black mb-2 lg:mx-2'>
                  State: {location.state.toUpperCase()}
              </h1>
              <h1 className='w-[90%] h-10 lg:w-[60%] text-primary text-center text-sm p-2 border-b-2 border-black mb-2 lg:mx-2'>
                  Country: {location.country.toUpperCase()}
              </h1>
              <h1 className='w-[90%] h-10 lg:w-[60%] text-primary text-center text-sm p-2 border-b-2 border-black mb-2 lg:mx-2'>
                  Rate: {location.rate}
              </h1>
              <div className='w-full lg:w-[20%] flex flex-row items-center justify-between my-5'>
                <BsFillTrashFill className='h-6 lg:h-10 w-full text-red-500 lg:mx-2 cursor-pointer' onClick={()=>removeLocation(location.id,index)}/>
              </div>
            </div>
            <ShippingUpdateComponent location={location} setLoading={setLoading}/>
          </div>
        ))}
      </div>
      <div className='w-[80%] h-full flex flex-col items-center justify-around'>
        <input placeholder='Search' onChange={handleFilter} name="search" type='text' className='text-center text-sm rounded-lg w-[70%] lg:w-[90%] p-2 border-2 border-black mt-3 my-2' />
        <form id='shippingForm' className='w-[60%] flex flex-col items-center justify-between' onSubmit={addLocation}>
            <input placeholder='City Name' name="city" type='text' className='text-center text-sm rounded-lg w-full lg:w-full p-2 border-2 border-black mt-3 my-2' />
            <input placeholder='State Name' name="state" type='text' className='text-center text-sm rounded-lg w-full lg:w-full p-2 border-2 border-black my-2' />
            <input placeholder='Country Name' name="country" type='text' className='text-center text-sm rounded-lg w-full lg:w-full p-2 border-2 border-black my-2' />
            <input placeholder='Rate' name="rate" type='number' className='text-center text-sm rounded-lg w-full lg:w-[80%] p-2 border-2 border-black my-2' />
            <button className='w-[80%] lg:w-[40%] rounded-lg bg-gradient-to-t from-red-300 via-red-400 to-accent-secondary shadow-md hover:shadow-inner shadow-black text-white mt-5 py-2'>ADD</button>
        </form>
      </div>
    </div>


    </div>
  )
}



























