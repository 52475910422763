import React,{useState,useEffect} from 'react'
import {toast} from 'react-toastify'
import { Navigate,useNavigate } from 'react-router-dom'
import { useStateContext } from '../../services/contexts/StateContext'
import { PaystackCheckoutButton } from '../../components/general-components/PaystackCheckoutButton'
import { CartProductComponent } from '../../components/general-components/CartProductComponent'
import { calculateShipping, placeOrder } from '../../services/serverRequest'
import { ValidateContactData, ValidateLocationData } from '../../services/utilities/errorHandler'
import ShippingInfoModal from '../../components/general-components/ShippingInfoModal'
import DataService from '../../services/providers/data.service'
import visa from '../../assets/Visa-card-dark.png'
import master from '../../assets/MasterCard-dark.png'

export const CartPage = () => {

  const [saveInfo, setSaveInfo] = useState(false)

  //when a payment option is selected, all buttons are locked and animation will be triggered?
  const [lock, setLock] = useState(false)

  const navigate = useNavigate()
  
  const {totalPrice, cartItems,currentUser,reset} = useStateContext()

  const [location,setLocation] = useState(null)

  const [state, setState] = useState(null)
  const [country, setCountry] = useState(null)
  const [city, setCity] = useState(null)

  const [shipping, setShipping] = useState(null)

  const [openShipping, setOpenShipping] = useState(false)

  const [order, setOrder] = useState(Object.freeze({
    userCart: cartItems,
    subtotal: totalPrice,
    shipping: 0,
    total: totalPrice,
    customerFName:'',
    customerLName:'',
    customerEmail:'',
    customerPhone:'',
    userUID: currentUser ? currentUser.id : "Guest User",
    address: '',
    address_2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',

  }))

    // shipping cost calculation use effect
    useEffect(()=>{
        
        const calculate = async () =>{

            try{
                await calculateShipping(location,cartItems)
                .then((value) => {
                setOrder(o => ({
                    ...o,
                    userCart: cartItems,
                    shipping: parseInt(value),
                    subtotal: totalPrice,
                    total: totalPrice + parseInt(value),
                })
                )
            })
            
            }catch(err){
                toast.error("Uh-Oh. There was a problem calculating your shipping. Please try again")
            }
        }

        calculate()
        
        
    },[location,cartItems,totalPrice])

  // get user info useeffect  
  useEffect(()=>{
    const getUser = async () =>{
        try{
            const data = await DataService.getDocument("users",currentUser.id)
            setOrder(o => ({
                ...o,
                customerFName: data?.data()?.fname,
                customerLName: data?.data()?.lname,
                customerEmail: data?.data()?.email,
                customerPhone: data?.data()?.phone,
                address: data?.data()?.defaultAddress.address,
                address_2: data.data()?.defaultAddress.address_2,
                city: data?.data()?.defaultAddress.city,
                state: data?.data()?.defaultAddress.state,
                postal_code: data?.data()?.defaultAddress.postal_code,
                country: data?.data()?.defaultAddress.country,

            }))
        }catch (err){
            toast.error(err.message)
        }
    }

    if(currentUser){
        getUser()
    }
  },[currentUser])

  //get shipping details
  useEffect(()=>{
    const getShipping= async () => {
        const locations = await DataService.getAllShipping()
        setShipping([...locations.docs.map(item => ({id:item.id, ...item.data()}))])
    }

    getShipping()
  },[])
  


  const inputHandler = (e) => {
    setOrder({
        ...order,
        [e.target.name]: e.target.value.trim(),
    })
  }


  const handleSave = (e) => {
    e.preventDefault()

    try{
        let savedLocation = {
            address: order.address !== '' ? order.address : e.target.address.value,
            address_2: order.address_2 !== '' ? order.address_2 : e.target.address_2.value,
            city: city,
            state: state,
            postal_code: order.postal_code !== '' ? order.postal_code :e.target.postal_code.value,
            country: country,
        }

        let savedContact = {
            fname: order.customerFName !== '' ? order.customerFName :e.target.customerFName.value,
            lname: order.customerLName !== '' ? order.customerLName :e.target.customerLName.value,
            email: order.customerEmail !== '' ? order.customerEmail :e.target.customerEmail.value,
            phone: order.customerPhone !== '' ? order.customerPhone :e.target.customerPhone.value,
        }

        let locErrors = ValidateLocationData(savedLocation,shipping)
        let conErrors = ValidateContactData(savedContact)

        if(locErrors.length > 0 || conErrors.length > 0){
            throw new Error(`${locErrors}\n\n${conErrors}`)
        }
        
        setLocation(savedLocation)
        setSaveInfo(!saveInfo)
        setOrder((o) => ({
            ...o,
            ...savedContact,
            ...savedLocation,
        }))
        
        
    }catch(err){
        toast.error(err.message)
    }
    }


  const handleTransfer = async (e) => {
    e.preventDefault()
    setLock((value) => !value)
    try{
        if(!saveInfo){
            setLock((value) => !value)
            throw new Error("Please Fill In All Required Information")
        }else
        if(shipping <= 0){
            setLock((value) => !value)
            throw new Error("Please Wait While Shipping Calculates")
        }
        var transferButton = document.getElementById("transfer-button")
        transferButton.classList.add("spinning")
        await placeOrder(order)
        .then((id) => {
            setLock(!lock)
            navigate(`/order-receipt/${id}`)
            reset()
            toast.success("Your Order Has Been Succesfully Placed")
        })
        .finally(() =>{
            setLock(!lock)
            transferButton.classList.remove("spinning")
        })
    }catch (err) {
        toast.error(err.message)
    }
  }

  
  return (cartItems.length > 0 ? (

    <div className='w-full flex flex-col lg:flex-row lg:justify-center pb-[25vh]'>
        {openShipping && <ShippingInfoModal shipping={shipping} setOpen={setOpenShipping}/>}
        
        {/* Customer Forms */}
        <div className='w-[full] lg:w-[45%] mt-10'>
            <div className='grid grid-cols-2 w-full h-[40rem] lg:h-[45rem]'>
                
                {!saveInfo ?

                // Customer Info Form
                <form onSubmit={handleSave} className='col-span-2 grid grid-cols-4 text-white text-xl md:text-2xl lg:text-3xl h-full'>
                    
                    <div className='col-span-4 h-10 bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white flex justify-center items-center text-xl md:text-2xl lg:text-3xl'>{"Contact Information"}</div>
                    <input type='text' placeholder={order.customerFName ? order.customerFName: "First Name*"} name='customerFName' className='col-span-2 border-2 rounded-lg h-10 text-black' onChange={inputHandler}/>
                    <input type='text' placeholder={order.customerLName ? order.customerLName: "Last Name*"} name='customerLName' className='col-span-2 w-full border-2 rounded-lg h-10 text-black' onChange={inputHandler}/>
                    <input type='email' placeholder={order.customerEmail ? order.customerEmail : "Email Address*"} name='customerEmail' className='col-span-4 w-full border-2 rounded-lg h-10 pl-5 text-black' onChange={inputHandler}/>
                    {/* <select className='col-span-2 md:col-span-1 w-full border-2 rounded-lg h-10 text-black'
                    required onChange={()=>{}}>
                        <option value={null}>{"---------"}</option>
                        {shippingLocations.map(location => location.extension)
                        .map((ext,index) => {
                            return <option key={index} value={ext}>
                                {ext.flag}   {ext['alpha-2']}   {ext.code}
                            </option>
                        })}
                    </select> */}
                    <input type='number' placeholder={order.customerPhone ? order.customerPhone : "Phone Number*"} name='customerPhone' className='col-span-4 w-full border-2 rounded-lg h-10 pl-5 text-black' onChange={inputHandler}/>

                    <div className='col-span-4 h-10 bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white flex justify-center items-center text-xl md:text-2xl lg:text-3xl'>{"Delivery Information"}</div>
                    <input type='text' placeholder={order.address ? order.address:"Street Address*"} name='address' className='col-span-4 border-2 rounded-lg h-10 text-black' onChange={inputHandler}/>
                    <input type='text' placeholder={order.address_2 ? order.address_2:"Street Address Line 2"} name='address_2' className='col-span-4 w-full border-2 rounded-lg h-10 text-black' onChange={inputHandler}/>
                    
                    {/* <input type='text' placeholder={order.city ? order.city:"City*"} name='city' className='col-span-2 w-full border-2 rounded-lg h-10 text-black' onChange={inputHandler}/>
                    <input type='text' placeholder={order.state ? order.state:"State/Province*"} name='state' className='col-span-2 w-full border-2 rounded-lg h-10 text-black' onChange={inputHandler}/>
                     */}
                    <select
                        onChange = {(e) => {setCity(e.target.value)}}
                        className='col-span-2 w-full border-2 rounded-lg h-10 text-black'
                        name='city'
                        required>
                            {/* PLACEHOLDER TEXT */}
                            <option value={null}>{"City/Locality*"}</option>
                            {
                                Array.from(new Set(shipping?.map(item => item.city.toUpperCase()))).sort().map((city,index) => {
                                    return <option key={index} value={city}>{city}</option>
                                })
                            }
                    </select>
                    <select
                        onChange = {(e) => {setState(e.target.value)}}
                        className='col-span-2 w-full border-2 rounded-lg h-10 text-black'
                        name='state'
                        required>
                            {/* PLACEHOLDER TEXT*/}
                            <option value={null}>{"State/Province*"}</option>
                            {
                                Array.from(new Set(shipping?.map(item => item.state.toUpperCase()))).sort().map((state,index) => {
                                    return <option key={index} value={state}>{state}</option>
                                })
                            }
                    </select>
                    <input type='text' placeholder={order.postal_code ? order.postal_code:"Postal Code"} name='postal_code' className='col-span-2 w-full border-2 rounded-lg h-10 text-black' onChange={inputHandler}/>

                    <select
                        onChange = {(e) => {setCountry(e.target.value)}}
                        className='col-span-2 w-full border-2 rounded-lg h-10 text-black'
                        name='state'
                        required>
                            {/* PLACEHOLDER TEXT*/}
                            <option value={null}>{"Country*"}</option>
                            {
                                Array.from(new Set(shipping?.map(item => item.country.toUpperCase()))).sort().map((country,index) => {
                                    return <option key={index} value={country}>{country}</option>
                                })
                            }
                    </select>
                    {/* <input type='text' placeholder={order.country ? order.country:"Country"} name='country' className='col-span-2 w-full border-2 rounded-lg h-10 text-black' onChange={inputHandler}/> */}
                    <button type='submit' className="col-span-2 h-[50%] text-xl col-start-2 tonedButton bg-gradient-to-b from-green-400 via-green-600 to-green-800 mt-10"
                    >
                    SAVE
                    </button>



                </form>

                : 

                // INFORMATION DISPLAY AFTER SAVE
                <div className='col-span-2 grid grid-cols-5 text-xl md:text-2xl lg:text-3xl h-full'>
                    
                    <div className='col-span-5 h-10 bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white flex justify-center items-center text-lg md:text-2xl lg:text-3xl'>{"Contact Information"}</div>
                    <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"First Name:"}</div>
                    <div className='col-span-3 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order.customerFName}</div>
                    <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"Last Name:"}</div>
                    <div className='col-span-3 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order.customerLName}</div>
                    <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"Email Address:"}</div>
                    <div className='col-span-3 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order.customerEmail}</div>
                    <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"Phone Number:"}</div>
                    <div className='col-span-3 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order.customerPhone}</div>

                    <div className='col-span-5 h-10 bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white flex justify-center items-center text-lg md:text-2xl lg:text-3xl'>{"Delivery Information"}</div>
                    <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"Address:"}</div>
                    <div className='col-span-3 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order.address}</div>
                    <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"Address 2:"}</div>
                    <div className='col-span-3 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order.address_2}</div>
                    <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"City:"}</div>
                    <div className='col-span-3 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order.city}</div>
                    <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"State:"}</div>
                    <div className='col-span-3 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order.state}</div>
                    <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"Postal Code:"}</div>
                    <div className='col-span-3 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order.postal_code}</div>
                    <div className='col-span-2 h-10 bg-white text-primary flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{"Country:"}</div>
                    <div className='col-span-3 h-10 bg-white text-black flex justify-center items-center text-lg md:text-xl lg:text-2xl'>{order.country}</div>

                    <button className="col-span-3 h-[50%] text-xl col-start-2 tonedButton bg-gradient-to-b from-green-400 via-green-600 to-green-800 mt-10"
                    onClick={()=>setSaveInfo(!saveInfo)}
                    disabled={lock}
                    >
                    EDIT
                    </button>

                </div>
                
                }

                {/* Shipping Locations Modal  */}
                <div className='col-span-2 w-full h-[10rem] flex flex-col items-center justify-center'>
                    <h3 className='text-2xl cursor-pointer underline text-secondary underline-offset-4 decoration-secondary hover:text-secondary' onClick={() => setOpenShipping(true)}>Our Shipping Locations</h3>
                </div>
            </div>
        </div>

        {/* Cart Info and Payment Processing */}
        <div className='w-full lg:w-[45%] bg-yellow-40 flex flex-col items-center mt-10 lg:ml-10'>
            <h5 className='text-2xl font-extrabold my-5 bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white w-full text-center py-3'>Order Summary</h5>

            <div className='w-full max-h-[50vh] overflow-y-scroll flex flex-col items-center lg:mb-10'>
                {
                cartItems.map((item,i) => (<CartProductComponent key={i} item={item} index={i}/>))
                }  
            </div>


            <div className='w-full flex justify-between items-center mt-5 text-primary bg-white py-2'>
                <h5 className='text-lg font-extrabold mx-5'>Subtotal:</h5>
                <h5 className='text-lg font-extrabold mx-5'>&#8358;{totalPrice}</h5>
            </div>

            <div className='w-full flex justify-between items-center mt-5 text-primary bg-white py-2'>
                <h5 className='text-lg font-light mx-5'>Shipping:</h5>
                <h5 className='text-lg font-light mx-5'>&#8358;{order.shipping}</h5>
            </div>

            <div className='w-full flex justify-between items-center mt-5 bg-gradient-to-b from-gray-600 via-gray-800 to-black py-3'>
                <h5 className='text-lg font-extrabold mx-5'>Order Total:</h5>
                <h5 className='text-lg font-extrabold mx-5'>&#8358;{order.total}</h5>
            </div>

            <div className='my-10 lg:w-full flex flex-col lg:flex-row justify-between'>

                {/* orders are passed without location data so errors occur */}
                <PaystackCheckoutButton setLock={setLock} lock={lock} order={order} saveInfo={saveInfo}/>
                <button className="w-72 tonedButton bg-gradient-to-b from-green-400 via-green-600 to-green-800 mt-10 lg:mt-0"
                onClick={handleTransfer}
                disabled={lock}
                id="transfer-button"
                >
                Pay With Bank Transfer
                </button>
            </div>

            <div className='w-full h-[10vh] flex flex-row items-center justify-center'>
                <img src={visa} alt={'visa'} className='w-[20] h-[12] mr-2'  onClick={()=>console.log(order)}/>
                <img src={master} alt={'mastercard'} className='w-[20] h-[12] ml-2'/>
            </div>
        </div>
    </div>
  )
  : <Navigate to='/shop'/> 
  )
}
 