import React,{useState,useEffect} from 'react'
import { useStateContext } from '../../services/contexts/StateContext'
import {useParams} from 'react-router-dom'
import { BreadcrumbComponent } from '../../components/general-components/BreadcrumbComponent'
import { StarRating } from '../../components/general-components/StarRating'
import DataService from '../../services/providers/data.service'
import { ProductDescription } from '../../components/general-components/ProductDescription'
import { ProductIngredients } from '../../components/general-components/ProductIngredients'
import { ProductUsage } from '../../components/general-components/ProductUsage'
import { ProductReviews } from '../../components/general-components/ProductReviews'
import { ProductImageCarousel } from '../../components/general-components/ProductImageCarousel'
import { calculateDiscountPrice } from '../../services/utilities/calculateDiscountPrice'
import { slugify } from '../../services/utilities/slugify'
import { LoadingPage } from './LoadingPage'
import { ProductScrollBar } from '../../components/general-components/ProductScrollBar'

export const SingleProductPage = () => {

  const {slug} = useParams()
  const [products,setProducts] = useState()
  const [loading, setLoading] = useState(true)
  const [reveal, setReveal] = useState()
  const [items,setItems] = useState([])
  const [selected, setSelected] = useState('description')
  const [selectedIndex, setselectedIndex] = useState(0)
  const {addToCart} = useStateContext()
  const [qty, setQty] = useState(1)

  const baseurl = process.env.REACT_APP_PRODUCTION_URL
  const category = products ? slugify(products?.category) : ''

  
  useEffect(()=>{
    
    const toggleShow = () => {
      const triggerBottom = window.innerHeight/5 * 4
  
      const scrollDiv = document?.getElementsByName("product-scroll")[0]
      if(!scrollDiv) return
      const scrollTop = scrollDiv.getBoundingClientRect().top
      const scrollBottom = scrollDiv.getBoundingClientRect().bottom

      if(scrollTop < triggerBottom && triggerBottom < scrollBottom * 2){
        setReveal(true)
      }
      
    }

    window.addEventListener("scroll",toggleShow)

    return () => {
      window.removeEventListener("scroll",toggleShow)
    }

  },[])


  useEffect(()=>{
    const getData = async () => {
      const d = await DataService.getDocumentByAttr('products','slug',slug)
      const data = d.docs.map((doc) => ({...doc.data(),id:doc.id}))
      setProducts(data[0])
    }

    if(loading){
      getData()
      setLoading(false)
    }
  },[slug,loading])

  useEffect(()=>{
    
    const getConfig = async () => {
      const relatedProducts = await DataService.getRelatedProducts(products?.tags,slug)
      setItems(relatedProducts.docs.map((item) => ({...item.data(),id:item.id})))
    }

    if(products){
      getConfig()
    }
    
  },[products,slug])
  

  return (
    <>
      {products?.hidden ? 
      <>
        <div className='w-[100vw] h-[100vh] flex flex-col items-center text-center justify-center text-black text-2xl'>
          {"Sorry This Product Is Currently Unavailable 😑"}
        </div>
      </>
      :
      <>
    <div className='w-full min-h-[100vh] flex flex-col lg:flex-row bg-background lg:justify-center'>
      
        {/* images */}
        <div className='w-full lg:w-[45%] h-[500px] lg:h-[80vh] mt-10 sm:px-[5rem] lg:px-0 mb-[15%] md:mb-[10%]'>
          <ProductImageCarousel images={products?.image}/>
        </div>

        {/* data */}
        <div className='w-full lg:w-[45%] bg-yellow-40 flex flex-col items-center mt-10 lg:ml-10'>
        <BreadcrumbComponent item={products} />
          {/* PRODUCT INFO SECTION */}
          <div className='flex-col justify-between text-primary'>
            <h2 className='text-5xl py-5 text-center'>{products?.name}</h2>
            <div className='flex flex-col lg:flex-row items-center lg:justify-center'>
              <StarRating size={20} value={products?.rating && products?.rating} disabled={true}/>
              <h4 className='text-md lg:pl-3'>({products?.reviews.length}) customer {products && products.reviews.length !== 1 ? "reviews":"review"}</h4>
            </div>
          </div>

          {/* <h4 className='text-3xl my-5'>N{products?.options[selectedIndex].price}</h4> */}
          <div className='my-5 w-full flex flex-col items-center justify-center'>
          {/* IN STOCK TAG */}
          {products?.options[selectedIndex]?.status === 0 ? 
            <div className='text-lg bg-black text-white border border-black w-30 px-10 py-2 mb-5 text-center'>Out Of Stock</div>
            :products?.options[selectedIndex]?.status === 1 ?
            <div className='text-lg bg-gradient-to-b from-yellow-300 via-yellow-400 to-yellow-500 text-white border border-black w-30 px-10 py-2 mb-5 text-center '>Low Stock</div>
            :null
            }
          <div className='w-full flex flex-row justify-center mb-5 text-primary'>
            <span className={products?.options[selectedIndex]?.discountRate > 0 ?  "text-xl font-bold line-through decoration-4 decoration-accent-secondary opacity-50 mr-3":"text-3xl font-bold "}>&#8358;{products?.options[selectedIndex]?.price}</span>
            {products?.options[selectedIndex]?.discountRate > 0 ? 
              <div className='text-xs bg-gradient-to-b from-red-300 via-red-400 to-accent-secondary text-white border border-black px-3 text-center rounded-full py-1 ml-3'>-{products?.options[selectedIndex]?.discountRate}%</div>
              :null
            }
          </div>
          <span className={products?.options[selectedIndex]?.discountRate > 0 ?  "text-3xl font-bold text-primary":"hidden"}>&#8358;{calculateDiscountPrice(products?.options[selectedIndex]?.price,products?.options[selectedIndex]?.discountRate)}</span>
          </div>

          <p className='text-primary w-[75%] lg:w-full leading-relaxed mb-10 text-lg text-center'>{products?.summary}</p>

          <div className='flex flex-col lg:flex-row items-center text-primary'>
            <h4 className='text-xl mb-2'>Available Sizes:</h4>
            <div className='inline-flex py-5'>
              {products?.options.map((option,i)=> {
                return (
                <label key={i}>
                  <input type='radio' className='hidden' value={i} onClick={()=> setselectedIndex(i)}/>
                  <div className={selectedIndex === i ? 'text-xl ml-3 px-3 border-2 border-black cursor-pointer bg-secondary ':' text-xl ml-3 px-3 border-dashed border-2 border-black cursor-pointer'}>{option.size}</div>
                </label>
                )})}
            </div>
          </div>


          {/* BUTTONS */}
          {products?.options[selectedIndex].status === 0 ? 
          
            <div className='border-2 border-white w-full h-14 text-2xl rounded-full bg-gradient-to-b from-gray-400 via-gray-500 to-gray-600 text-black flex justify-center items-center mb-10'>Sorry! Currently out of stock.</div>
          :

            <div className='flex mb-10 flex-col lg:flex-row'>
              
              <div className='border-2 border-black p-1 text-black flex mb-5'>
                <select id='Qty' className='w-full pl-5 focus:outline-none  text-center' onChange={(e) => setQty(e.target.value)}>
                    {[...Array(30)].map((item,i)=>{
                        var value = i + 1
                        return <option value={value} key={i}>{value}</option>
                    })}
                </select>
              </div>

              <button className='text-white text-md w-[350px] h-14 bg-gradient-to-b from-gray-600 via-gray-800 to-black shadow-md hover:shadow-inner hover:shadow-black' 
              onClick={()=> {
                const product = Object.freeze({
                  id: products.id,
                  name: products.name,
                  image: products.image,
                  size: products.options[selectedIndex].size,
                  price: products.options[selectedIndex].price,
                  weight: products.options[selectedIndex].weight,
                  discountRate: products.options[selectedIndex].discountRate,
                  qty: 1,
                })
                addToCart(product,parseFloat(qty))
              }} >Add to cart</button>
            </div>
          }

              
          {/* ADDITIONAL INFO */}

          <div className='w-full grid grid-cols-3 gap-y-1 px-1'>
            <div className='col-span-3 text-center flex items-center justify-center py-3 text-xl text-white bg-gradient-to-b from-gray-600 via-gray-800 to-black'>{"Product Info"}</div>
            <div className='col-span-1 text-center flex items-center justify-center text-primary py-1 bg-white'>{"Weight:"}</div>
            <div className='col-span-2 text-center flex items-center justify-center bg-transparent py-1 text-primary border-b-2 border-black'>{products?.options[selectedIndex].weight}g</div>
            <div className='col-span-1 text-center flex items-center justify-center text-primary py-1 bg-white'>{"Dimensions:"}</div>
            <div className='col-span-2 text-center flex items-center justify-center bg-transparent py-1 text-primary border-b-2 border-black'>{products?.options[selectedIndex].dimensions}cm</div>
            <div className='col-span-1 text-center flex items-center justify-center text-primary py-1 bg-white'>{"Category:"}</div>
            <div className='col-span-2 text-center flex items-center justify-center bg-transparent py-1 text-white border-b-2 border-black'>
              <a href={`${baseurl}/shop/product-category/${slugify(category)}`} className=' py-1ml-2 text-white  bg-gradient-to-b from-gray-600 via-gray-800 to-black px-2 py-1 hover:bg-secondary hover:from-secondary hover:to-green-900' >{products?.category}</a>
            </div>
            <div className='col-span-1 text-center flex items-center justify-center text-primary py-1'>{"Tags:"}</div>
            <div className='col-span-2 text-center grid grid-cols-3 gap-2 ml-1 lg:grid-cols-3 bg-transparent py-1 text-white '>
            {!products?.tags ? null:
                products?.tags.map((tag,i)=>{
                return (
                  <a href={`${baseurl}/shop/product-tags/${slugify(tag)}`} key={i} className='col-span-1 flex items-center justify-center py-1 px-2 text-xs md:text-sm bg-gradient-to-b from-gray-600 via-gray-800 to-black text-white hover:from-secondary hover:to-green-900 overflow-hidden whitespace-normal break-all'>{tag}</a>
                )
              })
              }
            </div>
          </div>  


        </div>
        

    </div>
    <div className='w-full min-h-[70vh] flex flex-col lg:flex-row bg-background lg:justify-center mt-10'>
        <div className='w-full lg:w-[90%] flex flex-col items-center'>
            {/* switch tabs */}
            <div className=' w-full flex items-center justify-center'>
            <ul className='w-[95vw] grid grid-cols-4'>
                <li onClick={()=>setSelected('description')} className={selected==='description' ? 'col-span-1 shadow-inner shadow-black text-center p-4 text-xs lg:text-lg  bg-secondary text-background cursor-pointer':'col-span-1 text-center p-2 text-md bg-background border-2 border-primary text-primary cursor-pointer hover:shadow-inner hover:shadow-black text-xs lg:text-lg px-3 break-all'}>Description</li>
                <li onClick={()=>setSelected('usage')} className={selected==='usage' ? 'col-span-1 shadow-inner shadow-black  text-center p-4 text-xs lg:text-lg  bg-secondary text-white cursor-pointer':'col-span-1 text-center p-2 text-md bg-background text-primary border-2 border-primary hover:shadow-inner hover:shadow-black cursor-pointer text-xs lg:text-lg px-3 break-words'}>How To Use</li>
                <li onClick={()=>setSelected('ingredients')} className={selected==='ingredients' ? 'col-span-1 shadow-inner shadow-black text-center p-4 text-xs lg:text-lg  bg-secondary text-background cursor-pointer':'col-span-1 text-center p-2 border-x-0 text-md bg-background border-2 border-primary text-primary cursor-pointer hover:shadow-inner hover:shadow-black text-xs lg:text-lg px-3 break-all'}>Ingredients</li>
                <li onClick={()=>setSelected('reviews')} className={selected==='reviews' ? 'col-span-1 shadow-inner shadow-black  text-center p-4 text-xs lg:text-lg  bg-secondary text-white cursor-pointer':'col-span-1 text-center p-2 text-md bg-background text-primary border-2 border-primary hover:shadow-inner hover:shadow-black cursor-pointer text-xs lg:text-lg px-3 break-all'}>Reviews ({products?.reviews.length})</li>
            </ul>
            </div>

            { selected === "description" ?

            <ProductDescription products={products}/>
            : selected === "usage" ?
            <ProductUsage products={products}/>
            : selected === "ingredients" ?
            <ProductIngredients products={products}/>
            :
            <ProductReviews products={products} setLoading={setLoading}/>
            }
        </div>

    </div>
    <div className='w-full flex items-center justify-center'>

      {/* newest products */}
      <div name="product-scroll" className={` w-full h-[80vh] lg:w-[90vw] grid grid-rows-7 text-white mt-5`}>
        <span className='bg-gradient-to-b from-gray-600 via-gray-800 to-black row-span-1 flex items-center justify-center w-full text-center text-xl lg:text-2xl'>{"Shop Related Products"}</span>
        <div className='row-span-6 overflow-hidden relative'>
              <ProductScrollBar productList={items} reveal={reveal} />
        </div>
      </div>

    </div>
    {!products ? <LoadingPage/> : null }
    </>}
    </>
  )
} 
