import React,{useEffect} from 'react'
import { useParams } from 'react-router-dom'
import {verifyEmail} from '../../services/serverRequest'
import {toast} from 'react-toastify'

export const UserVerificationPage = () => {

  const { token } = useParams()

  useEffect(()=>{
    verifyEmail(token)
    .then((data) => {
        toast.success(data.message)
    })
    .catch(err => toast.error(err))
  },[token])

  return (
    <div className='w-full h-[100vh] flex items-center justify-center text-black text-center'>
      Your Email Has Been Succesfully Confirmed! You're ready to login!
    </div>
  )
}
