import './App.css';
import {Routes,Route} from 'react-router-dom'

import { LayoutComponent } from './components/general-components/LayoutComponent';
import {StateContextProvider} from './services/contexts/StateContext'



import {Error404Page} from './pages/general-pages/Error404Page'
import {Homepage} from './pages/general-pages/Homepage'
import {ProductListPage} from './pages/general-pages/ProductListPage'
import {SingleProductPage} from './pages/general-pages/SingleProductPage'
import {CartPage} from './pages/general-pages/CartPage'
import {OrderReceiptPage} from './pages/general-pages/OrderReceiptPage'
import {OrderSuccessPage} from './pages/general-pages/OrderSuccessPage'
import { RegistrationPage } from './pages/general-pages/RegistrationPage';
import {ContactUsPage} from './pages/general-pages/ContactUsPage'
import { AboutUsPage } from './pages/general-pages/AboutUsPage';
import {FAQsPage} from './pages/general-pages/FAQsPage'
import { PasswordRecoveryPage } from './pages/general-pages/PasswordRecoveryPage';
import { SkinGuidePage } from './pages/general-pages/SkinGuidePage';


import {AdminPage} from './pages/admin-pages/AdminPage'
import { Dashboard } from './pages/admin-pages/Dashboard';
import { OrdersPage } from './pages/admin-pages/orders/OrdersPage';
import { SingleOrderPage } from './pages/admin-pages/orders/SingleOrderPage';
import {DailySalesStatsPage} from './pages/admin-pages/stats/DailySalesStatsPage'
import {MonthlySalesStatsPage} from './pages/admin-pages/stats/MonthlySalesStatsPage'
import {YearlySalesStatsPage} from './pages/admin-pages/stats/YearlySalesStatsPage'
import { UsersPage } from './pages/admin-pages/users/UsersPage';
import { SingleUserPage } from './pages/admin-pages/users/SingleUserPage';
import { ProductsPage } from './pages/admin-pages/products/ProductsPage';
import {ProductsFormPage} from './pages/admin-pages/products/ProductsFormPage'
import {SettingsPage} from './pages/admin-pages/SettingsPage'

import {UserPage} from './pages/user-pages/UserPage'
import {UserProfilePage} from './pages/user-pages/UserProfilePage'
import {UserSettingsPage} from './pages/user-pages/UserSettingsPage'
import {UserOrdersPage} from './pages/user-pages/UserOrdersPage'
import {UserOrderPage} from './pages/user-pages/UserOrderPage'
import {UserVerificationPage} from './pages/user-pages/UserVerificationPage'


function App() {
  return (
    <StateContextProvider>
    <LayoutComponent>
      <Routes>

        {/* general routes */}
        <Route path='/' element={<Homepage/>}/>
        <Route path='cart' element={<CartPage/>}/>
        <Route path='order-receipt/:orderid' element={<OrderReceiptPage/>}/>
        <Route path='order-confirmation/success' element={<OrderSuccessPage/>}/>
        <Route path='registration' element={<RegistrationPage/>}/>
        <Route path='user-verification/:token' element={<UserVerificationPage/>}/>
        <Route path='recovery' element={<PasswordRecoveryPage/>}/>
        <Route path='about-us' element={<AboutUsPage/>}/>
        <Route path='contactus' element={<ContactUsPage/>}/>
        <Route path='faqs' element={<FAQsPage/>}/>
        <Route path='*' element={<Error404Page/>}/>
        
        {/* transform into a full blog section later */}
        <Route path='blog'>
          <Route path='skin-guides' element={<SkinGuidePage/>}/>
        </Route>

        {/* store routes */}
        <Route path='shop'>
          <Route index element={<ProductListPage/>}/>
          <Route path=':slug' element={<SingleProductPage/>}/>
          <Route path='product-category/:category' element={<ProductListPage/>}/>
          <Route path='product-tags/:tagsArray' element={<ProductListPage/>}/>
          <Route path='product-category/:category/tags/:tagsArray' element={<ProductListPage/>}/>
          <Route path='*' element={<Error404Page/>}/>
        </Route>

        {/* admin routes */}
        <Route path='admin' element={<AdminPage/>}>
                    
          <Route path='dashboard'>
            <Route index element={<Dashboard/>}/>
            <Route path='*' element={<Error404Page/>}/>
          </Route>

          <Route path='products'>
            <Route index element={<ProductsPage/>}></Route>
            <Route path='create-product' element={<ProductsFormPage/>} />
            <Route path=':id' element={<ProductsFormPage/>}/>
            <Route path='*' element={<Error404Page/>}/>
          </Route>

          <Route path='orders'>
            <Route index element={<OrdersPage/>}></Route>
            <Route path='sales-data'>
                <Route path=':year/:month/:day' element={<DailySalesStatsPage/>}/>
                <Route path=':year/:month' element={<MonthlySalesStatsPage/>}/>
                <Route path=':year' element={<YearlySalesStatsPage/>}/>
            </Route>
            <Route path=':id' element={<SingleOrderPage/>}/>
            <Route path='*' element={<Error404Page/>}/>
          </Route>

          <Route path='users'>
            <Route index element={<UsersPage/>}/>
            <Route path=':id' element={<SingleUserPage/>}/>
            <Route path='*' element={<Error404Page/>}/>
          </Route>

          <Route path='settings' element={<SettingsPage/>}/>
        </Route>
        
        {/* registered user routes */}
        <Route path='account' element={<UserPage/>}>
          <Route path='profile' element={<UserProfilePage/>}/>
          <Route path='orders' element={<UserOrdersPage/>}/>
          <Route path='orders/:orderid' element={<UserOrderPage/>}/>
          <Route path='settings' element={<UserSettingsPage/>}/>
          <Route path='*' element={<Error404Page/>}/>
        </Route>

      </Routes>
    </LayoutComponent>
    </StateContextProvider>
  );
}

export default App;
