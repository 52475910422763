import React from 'react'

export const PaymentInstructionsComponent = () => {
  return (
    <div className='text-black text-center flex flex-col items-center justify-center'>
        <h5 className='mb-5 text-2xl border-b-4 border-black'>Payment Instructions</h5>
        <div className='w-[85%] mt-5 mb-10 flex flex-col'>
            <div className='w-full flex-col flex'>
                <h5 className='w-full text-4xl pb-5 pl-5 text-center'>NAIRA PAYMENT INSTRUCTIONS</h5>
                <h5 className='w-full pl-5'> - Make your NAIRA payment directly into our bank account via Online Banking or Physical Deposit at your local Bank branch.</h5>
                <h5 className='w-full pl-5 font-bold'> - Please use your Order ID as the payment reference.</h5>
                <h5 className='w-full pl-5'> - Your order wont be shipped until the funds have cleared in our account.</h5>
                <h5 className='w-full pl-5 mb-5'> - Kindly note that all orders are shipped out within 3 working days from payment receipt.</h5>
                
                {/* BANKING INFORMATION */}
                <div className='w-full my-5 py-5 bg-gray-100 border-2 border-gray-300 flex flex-col'>
                 <div className='flex flex-row w-full justify-around items-center'>
                  <h5 className='w-[50%]'>Company Bank: </h5>
                  <h5 className='w-[50%]'>Opay</h5>
                 </div>
                 <div className='flex flex-row w-full justify-around items-center'>
                  <h5 className='w-[50%]'>Account Number: </h5>
                  <h5 className='w-[50%]'>8126794255</h5>
                 </div>
                </div>
                {/* <div className='w-full my-5 py-5 bg-gray-100 border-2 border-gray-300 flex flex-col'>
                 <div className='flex flex-row w-full justify-around items-center'>
                  <h5 className='w-[50%]'>Company Bank: </h5>
                  <h5 className='w-[50%]'>Guarantee Trust Bank (GTB) </h5>
                 </div>
                 <div className='flex flex-row w-full justify-around items-center'>
                  <h5 className='w-[50%]'>Account Number: </h5>
                  <h5 className='w-[50%]'>1234567890</h5>
                 </div>
                </div> */}

            </div>


            <div className='w-full flex-col my-10 flex'>
                <h5 className='w-full text-4xl pb-5'>INTERNATIONAL PAYMENT INSTRUCTIONS</h5>
                <h5 className='w-full pl-5'> - Visit wwww.worldremit.com and create an account or login to your existing account.</h5>
                <h5 className='w-full pl-5'> - Select the country NIGERIA in the “THEY GET” option.</h5>
                <h5 className='w-full pl-5'> - Select the Bank Transfer option and input your exact order amount in the 'THEY GET' section.</h5>
                <h5 className='w-full pl-5'> - Choose BANK TRANSFER in the PAYMENT METHOD option.</h5>
                <h5 className='w-full pl-5'> - Choose (BANK HERE) in the PAYMENT PARTNER option.</h5>
                <h5 className='w-full pl-5 font-bold'> - Input the banking details below.</h5>
                <h5 className='w-full pl-5'> - Select I agree to send the payment to my recipient’s USD account: YES I AGREE.</h5>
                <h5 className='w-full pl-5'> - Reason for sending: PURCHASE OF SERVICES.</h5>
                <h5 className='w-full pl-5'> - CONTINUE AND PROCEED TO MAKE PAYMENT.</h5>
                {/* BANKING INFORMATION */}
                <div className='w-full mt-10 py-5 bg-gray-100 border-2 border-gray-300 flex flex-col'>
                 <div className='flex flex-row w-full justify-around items-center mb-2'>
                  <h5 className='w-[40%]'>First Name: </h5>
                  <h5 className='w-[60%]'>HSF</h5>
                 </div>
                 <div className='flex flex-row w-full justify-around items-center mb-2'>
                  <h5 className='w-[40%]'>Last Name: </h5>
                  <h5 className='w-[60%]'>Naturals</h5>
                 </div>
                 <div className='flex flex-row w-full justify-around items-center mb-2'>
                  <h5 className='w-[40%]'>City/Town: </h5>
                  <h5 className='w-[60%]'>Kaduna</h5>
                 </div>
                 <div className='flex flex-row w-full justify-around items-center mb-2'>
                  <h5 className='w-[40%]'>Mobile Number: </h5>
                  <h5 className='w-[60%]'>+234 (0) 8126794255</h5>
                 </div>
                 <div className='flex flex-row w-full justify-around items-center mb-2'>
                  <h5 className='w-[40%]'>Email Address: </h5>
                  <h5 className='w-[60%]'>hsfnaturalsng@gmail.com</h5>
                 </div>
                 <div className='flex flex-row w-full justify-around items-center mb-2'>
                  <h5 className='w-[40%]'>Bank Name: </h5>
                  <h5 className='w-[60%]'>OPay</h5>
                 </div>
                 <div className='flex flex-row w-full justify-around items-center mb-2'>
                  <h5 className='w-[40%]'>Account Number: </h5>
                  <h5 className='w-[60%]'>8126794255</h5>
                 </div>
                </div>
            </div>

            
        </div>
    </div>
  )
}
