import React,{useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import { StarRating } from './StarRating'
import DataService from '../../services/providers/data.service'
import { useStateContext } from '../../services/contexts/StateContext'
import {ValidateProductReview} from '../../services/utilities/errorHandler'

// EXCESSIVE POST HANDLING


export const ReviewFormComponent = ({product,setLoading}) => {
    
    const {currentUser} = useStateContext()

    const [review, setReview] = useState(Object.freeze({
        rating: 0,
        review: '',
        name: '',
        userUID: currentUser?.id,
        date: new Date().toLocaleString().split(',')[0]
    }))
    const [item, setItem] = useState(product)

    const ratingHandler = (value) => {
        setReview({
            ...review,
            rating: value,
        })
    }
    
    const reviewHandler = (e) => {
        setReview({
            ...review,
            [e.target.name]: e.target.value.trim(),
        })
    }

    const submitReview = async (e) => {
        e.preventDefault()

        item.reviews.push(review)

        try{
            console.log(currentUser)

            if(!currentUser) throw new Error("Please Log In To Post A Review")
            if(!currentUser?.emailVerified) throw new Error("Please Verify Your Email. Check Your Email Or Contact Support")
            if(currentUser?.status === false) throw new Error("Your Account Has Been Suspended. Please Contact Support")
            let errors = ValidateProductReview(review)
            if(errors.length > 0){
                item?.reviews.pop()
                throw new Error(errors)
            }
            await DataService.addReview(item.id,item,currentUser)
            .then(() => {
                setReview({
                    ...review,
                    rating: 0,
                })
                setLoading(true)
                document.getElementById("review").reset()
            })
        } catch (err) {
            toast.error(err.message)
        }
    }

    useEffect(()=>{
        setItem(product)
    },[product])



  return (
    <form id="review" className='flex flex-col items-center w-full text-primary mt-10'>
        
        {/* DISCLAIMER */}
        {/* <h5 className='text-md font-medium mb-7'>Your email will never be published when leaving a review</h5> */}

        {/* RATING SECTION */}
        <div className='inline-flex mb-7'>
            <h5 className='text-lg font-medium underline'>Your rating</h5>
            <h5 className='text-accent-secondary text-md'>*</h5>
        </div>
        <StarRating size={25} disabled={false} handler={ratingHandler}/>
        

        {/* NAME SECTION */}
        <div className='flex flex-col items-center w-full'>
            <div className='inline-flex my-7'>
                <h5 className='text-lg font-medium underline'>Your Name</h5>
                <h5 className='text-accent-secondary text-md'>*</h5>
            </div>
            <input type='text' name='name' onChange={reviewHandler} required={true} className='w-[50%] h-10 p-5 border-2'/>
        </div>

        {/* REVIEW SECTION */}
        <div className='flex flex-col items-center w-full'>
            <div className='inline-flex my-7'>
                <h5 className='text-lg font-medium w-full text-center underline'>Your review</h5>
                <h5 className='text-accent-secondary text-md'>*</h5>
            </div>
            <textarea type='text' name='review' rows={10} required={true} onChange={reviewHandler} className='w-[90%] text-black lg:w-[50%] border-2'/>
        </div>

        {/* SUBMIT BUTTON */}
        <div className='flex justify-start my-10'>
            <button className='bg-gradient-to-b from-gray-600 via-gray-800 to-black shadow-md hover:shadow-inner hover:shadow-black text-white p-3 text-lg w-40' onClick={submitReview}>Submit</button>
        </div>


    </form>
  )
}

