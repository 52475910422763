import React from 'react'
// import { useStripe } from '@stripe/react-stripe-js'
import { paystackLink } from '../../services/serverRequest'
import {toast} from 'react-toastify'

export const PaystackCheckoutButton = ({setLock,lock,order,saveInfo}) => {

  
  const handleCheckout = async (e) => {
    e.preventDefault()
    setLock((value) => !value)
    try{
      if(!saveInfo){
        setLock((value) => !value)
        throw new Error("Please Fill In All Required Information")
      }else
      if(order.shipping <= 0){
          setLock((value) => !value)
          throw new Error("Please Wait While Shipping Calculates")
      }
      var transferButton = document.getElementById("paystack-button")
      transferButton.classList.add("spinning")
      await paystackLink(order)
    }catch(err){
      toast.error(err.message)
    }


  }


  return (
    <button className="w-72 tonedButton bg-gradient-to-b from-blue-400 via-blue-600 to-blue-800"
    onClick={(e)=> handleCheckout(e)}
    disabled={lock}
    id="paystack-button"
    >Pay With Paystack</button>
  )
}
