import React,{useState} from 'react'
import { BurgerSidebar } from './BurgerSidebar'
import { LoginSidebar } from './LoginSidebar'
// import { CartSidebar } from './CartSidebar'
import { useStateContext } from '../../services/contexts/StateContext'
import logo from '../../assets/logo-black.png'

export const Navbar = () => {
  
  const [openBurger, setOpenBurger] = useState(false)
  const [openUserbar, setOpenUserbar] = useState(false)
  const {setShowCart,totalQty} = useStateContext()

  const [prevPos, setPrevPos] = useState(0)

  function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

  window.onscroll = debounce(() => {
    const currentPos = window.scrollY
    if(
        (prevPos < currentPos && prevPos - currentPos < 70)
     ){
        document.getElementById('navbar').classList.add(`-translate-y-[20vh]`)
        document.getElementById('navbar').classList.add(`md:-translate-y-[18vh]`)
    }else{
        document.getElementById('navbar').classList.remove(`-translate-y-[20vh]`)
        document.getElementById('navbar').classList.remove(`md:-translate-y-[18vh]`)
    }
    setPrevPos(currentPos)
    
  }, 100)


  return (
    <>
    <LoginSidebar open={openUserbar} setOpen={setOpenUserbar}/>
    <BurgerSidebar open={openBurger} setOpen={setOpenBurger}/>
    
    <div id='navbar' className={` z-30 w-full fixed grid grid-cols-1 grid-rows-3 bg-gradient-to-b from-background via-gray-50 to-gray-100 shadow-md shadow-black h-[20vh] md:h-[18vh] transition-transform ease-linear duration-150`}>
        <div className='col-span-1 row-span-2 flex items-center justify-center'>
            <a href={`/`} className='text-7xl text-white tracking-[1rem] px-4 rounded-2xl cursor-pointer'>
                {/* LINX */}
                <img src={logo} width='200' height='200' alt='' className='pt-10 xl:pt-5'/>
            </a>
        </div>

        <div className='grid grid-cols-7 col-span-1 row-span-1 bg-inherit'>
            
            
            {/* left side */}
            <div className='col-span-2 grid grid-cols-2'>
                <div className='col-span-1 flex items-center justify-center text-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 p-1 text-secondary cursor-pointer xl:hidden"
                    onClick={()=> setOpenBurger(!openBurger)}
                    fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </div>
            </div>

            {/* middle */}
            <div className='col-span-3 w-full h-full xl:flex items-center justify-evenly text-center text-2xl text hidden '>
                {[{title:'Home',url: '/'},{title:'Our Story',url: '/about-us'},{title:'Shop',url: '/shop'},{title:'FAQs',url: '/faqs'},{title:'Contact Us',url: '/contactus'},]
                .map((link,index) => <a key={index} className='text-primary hover:underline decoration-secondary cursor-pointer hover:font-semibold' href={`${link.url}`}>
                    {link.title}
                </a>)}
            </div>

            {/* end */}
            <div className='col-span-2 col-start-6 grid grid-cols-2'>
                <div className='col-span-1 flex items-center justify-center text-center'>
                    <svg xmlns="http://www.w3.org/2000/svg"
                    onClick={()=> setOpenUserbar(!openUserbar)}
                    className="w-10 h-10 sm:w-12 sm:h-12 p-1 text-secondary cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                    </svg>
                </div>
                <div className='col-span-1 flex items-center justify-center text-center'>
                    <div className='inline-flex relative cursor-pointer'
                        onClick={()=> setShowCart(true)}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg"
                        className="w-10 h-10 sm:w-12 sm:h-12 p-1 text-secondary" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                        <div className='rounded-full bg-white text-black w-4 h-4 sm:w-6 sm:h-6 absolute top-[-3px] text-sm sm:text-base right-1 border border-black flex items-center justify-center'>{totalQty}</div>
                    </div>
                </div>
            </div>


        </div>
    </div>
    </>


  )
}
