import React,{useEffect,useState} from 'react'
import DataService from '../../services/providers/data.service'
import {AiFillCloseCircle as Close} from 'react-icons/ai'
import { CarouselComponent } from '../../components/general-components/CarouselComponent'
import { ProductScrollBar } from '../../components/general-components/ProductScrollBar'
import { TestimonialScrollBar } from '../../components/general-components/TestimonialScrollBar'
import useTestimonials from '../../hooks/useTestimonials'
import {images, promoImages} from '../../system/settings'
import { LoadingPage } from './LoadingPage'

export const Homepage = () => {
  
  const [config, setConfig] = useState()
  
  const [show, setShow] = useState()
  const [reveal, setReveal] = useState()
  const [revealTestimonial, setRevealTestimonial] = useState()
  const [items,setItems] = useState([])
  const [testimonialItems,setTestimonialItems] = useState([])
  const testimonials = useTestimonials(testimonialItems)
  
  useEffect(()=>{

    const toggleShow = () => {
      const triggerBottom = window.innerHeight/5 * 4
      
      const scrollDiv = document.getElementsByName("product-scroll")[0]
      const scrollTop = scrollDiv.getBoundingClientRect().top
      const scrollBottom = scrollDiv.getBoundingClientRect().bottom
      
      
      const infoDiv = document.getElementsByName("reveal-info")[0]
      const infoTop = infoDiv.getBoundingClientRect().top
      const infoDivMiddle = infoDiv.getBoundingClientRect().height/2
      
      const testimonialDiv = document.getElementsByName("testimonial")[0]
      const testimonialTop = testimonialDiv.getBoundingClientRect().top
      const testimonialBottom = testimonialDiv.getBoundingClientRect().bottom
      
      if(infoTop + infoDivMiddle < triggerBottom && triggerBottom < infoTop + 2*infoDivMiddle){
        setShow(true)
      }
      if(scrollTop < triggerBottom/2 && triggerBottom < scrollBottom * 2){
        setReveal(true)
      }
      if(testimonialTop < triggerBottom /2 && triggerBottom < testimonialBottom * 2){
        setRevealTestimonial(true)
      }
      
    }

    window.addEventListener("scroll",toggleShow)

    return () => {
      window.removeEventListener("scroll",toggleShow)
    }
  },[])
  
  useEffect(()=>{
    
    const getConfig = async () => {
      const data = await DataService.getDocument("config","settings")
      const latestFive = await DataService.getLatestFiveProducts()
      const topFive = await DataService.getTopFiveProducts()
      setConfig(data.data())
      setItems(latestFive.docs.map((item) => ({...item.data(),id:item.id})))
      setTestimonialItems(topFive.docs.map((item) => ({...item.data(),id:item.id})))
    }

    getConfig()

  },[])

  if(!config){
    return (
      <LoadingPage/>
    )
  }


  return (
    <div className='min-h-[100vh] flex flex-col items-center justify-center overflow-hidden bg-white'>
    
    { config?.messageBanner.show &&
      <div className='w-full h-10 inline-flex items-center justify-center bg-slate-300 text-black relative overflow-hidden whitespace-nowrap'>
        <p className='animate-infinite-scroll block'>
          {config?.messageBanner.message}
        </p> 
        
        <Close className='cursor-pointer text-black absolute right-0 opacity-40 hover:opacity-80' size={30} onClick={()=> setConfig({...config,messageBanner: {...config.messageBanner, show:false}})}/>
      </div> 
    }

    <div className='w-full' name="carousel">
      <CarouselComponent initialStack={images}/>
    </div>


    {/* learn more */}
    <div className={`${show ? `gap-5 min-h-[70vh] bg-gray-50`:`bg-inherit`} w-full p-10 text-primary grid grid-cols-2 ease-linear duration-300 align-items-center`} name='reveal-info'>
      
      {/* image */}
      <div className='w-full h-[50vh] md:h-[60vh] lg:h-[70vh] col-span-2 lg:col-span-1 relative flex items-center justify-center overflow-hidden bg-gray-500'>
        <img className='w-full h-full absolute object-cover mix-blend-overlay'
        src={promoImages.promo3.image} alt={promoImages.promo3.alt}/>
        <div className='absolute bottom-[10%] flex flex-col items-center justify-evenly w-full'>
          <span className='text-center flex flex-col items-center justify-center text-base lg:text-xl text-primary bg-white py-2 px-5 opacity-80 min-w-[75%]'>
            <a href={`about-us`} 
            className='px-3 h-10 bg-black hover:shadow-inner text-white hover:shadow-black white flex items-center opacity-100 justify-center text-center my-3 hover:scale-[1.2]'>
              Learn More About Us
            </a>
          </span>
        </div>
      </div>

      
      <div className='order-first lg:order-last w-full col-span-2 lg:col-span-1 lg:h-full flex flex-col items-center justify-center'>
        <div id='founder-message' className={`${show ? `-translate-x-0 opacity-100`:`-translate-x-[5vw] opacity-0`} w-full ease-linear duration-500 py-5 flex flex-col`}>
          <span className='text-center font-semibold flex items-center text-transparent justify-center text-2xl bg-clip-text font-mono bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900'>
            {"FROM THE FOUNDER"}
          </span>
          <span className='text-center flex items-center justify-center text-sm p-2 lg:p-10 font-mono'>
            {"Venturing into the world of natural skincare was an eye opener. Most especially learning how common ingredients often taken for granted have been around and used for generations to cure ailments and skin issues. It’s like bringing back traditional with a modern flair, even the humble Coconut oil would look good anywhere when packaged the right way. No need to break the bank for that good looking skin and hair, sometimes the best solution is a little closer to home that you’d expect. Theres a little something for everyone, whether looking to smell good or find products aimed at problematic skin, our brand is neither gender nor age exclusive. Every one deserves to glow from head to toe!"}
          </span>
          <span className='text-center flex items-center justify-center text-lg text-transparent bg-clip-text bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 font-semibold'>
            {"- ’S’ of H.S.F"}
          </span>
        </div>
      </div>

    </div>


    {/* newest products */}
    <div name="product-scroll" className={` w-full h-[80vh] lg:w-[90vw] grid grid-rows-7 ease-linear duration-300 text-white mt-5`}>
      <span className='bg-gradient-to-b from-gray-600 via-gray-800 to-black row-span-1 flex items-center justify-center w-full text-center text-xl lg:text-2xl'>{"Shop Our New Releases"}</span>
      <div className='row-span-6 overflow-hidden relative'>
            {/* <ProductScrollBar productList={items} reveal={true}/> */}
            <ProductScrollBar productList={items} reveal={reveal} />
      </div>
    </div>

    {/* solid image-overlays */}
    <div className={'w-full h-[100vh] lg:w-[90vw]  lg:h-[70vh] grid grir-cols-1 lg:grid-cols-2 gap-5'}>


      <div className='col-span-1 relative flex items-center justify-center overflow-hidden bg-gray-500'>
        <img className='w-full h-full absolute object-cover mix-blend-overlay'
        src={promoImages.promo1.image} alt={promoImages.promo1.alt}/>
        <div className='absolute bottom-[10%] flex flex-col items-center justify-evenly w-full'>
          <span className='text-center flex flex-col items-center justify-center text-base lg:text-xl text-primary bg-white py-2 px-5 opacity-80 min-w-[75%]'>
              {"Find Skincare That Suits Your Skin Type"}
            <a href={`blog/skin-guides`} 
            className='w-40 h-10 bg-black hover:shadow-inner text-white hover:shadow-black white flex items-center opacity-100 justify-center text-center my-3 hover:scale-[1.2]'>
              Discover
            </a>
          </span>
        </div>
      </div>
      <div className='col-span-1 relative flex items-center justify-center overflow-hidden bg-gray-500'>
        <img className='w-full h-full absolute object-cover mix-blend-overlay'
        src={promoImages.promo2.image} alt={promoImages.promo2.alt}/>
        <div className='absolute bottom-[10%] flex flex-col items-center justify-evenly w-full'>
          <span className='text-center flex flex-col items-center justify-center text-base lg:text-xl text-primary bg-white py-2 px-5 opacity-80 min-w-[75%]'>
          {"Find Your Glow Mask"}
            <a href={`shop/product-category/masks`} 
            className='w-40 h-10 bg-black hover:shadow-inner text-white hover:shadow-black white flex items-center opacity-100 justify-center text-center my-3 hover:scale-[1.2]'>
              Discover
            </a>
          </span>
        </div>
      </div>

    </div>


    {/* product testimonials */}
    <div name="testimonial" className={` w-full min-h-[40rem] lg:w-[90vw] grid grid-rows-7 ease-linear duration-300   text-white my-5`}>
      <span className='bg-gradient-to-b from-gray-600 via-gray-800 to-black row-span-1 flex items-center justify-center w-full text-center text-xl lg:text-2xl'>{"What Our Customers Are Saying About Us"}</span>
      <div className='row-span-6 overflow-hidden relative'>
          {
            testimonials?.length > 0 ?
            <TestimonialScrollBar testimonials={testimonials} reveal={revealTestimonial}/>
            :
            <div className='w-full h-full flex flex-row items-center justify-center text-black text-2xl font-bold'>
              {"Be the first to leave a review!"}
            </div>
          }
            
      </div>
    </div>


    </div>

    
  )
}
