import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREB_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
})

export const auth = app.auth();
export const store = app.firestore();
export const storage = app.storage();

export const timeStamp = firebase.firestore.FieldValue.serverTimestamp;

export default app;