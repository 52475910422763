export const SearchOrders = (array,keyword) => {
    //might not need one of the lines below for slug match
    if(!array) return
    return array.filter(value => {
        return value.id?.toLowerCase().match(new RegExp(keyword, "g")) ||
        value.id?.match(new RegExp(keyword, "g")) ||
        value.customerFName?.match(new RegExp(keyword, "g")) ||
        value.customerFName?.toLowerCase().match(new RegExp(keyword, "g")) ||
        value.customerLName?.match(new RegExp(keyword, "g")) ||
        value.customerLName?.toLowerCase().match(new RegExp(keyword, "g"))
        // value.category.toLowerCase().match(new RegExp("^" + keyword + "$"))
    })
}

export const SearchProducts = (array,keyword) => {
    //might not need one of the lines below for slug match
    if(!array) return
    return array.filter(value => {
        return value.name?.toLowerCase().match(new RegExp(keyword,'g')) ||
        value.name?.match(new RegExp(keyword, "g")) ||
        value.id?.toLowerCase().match(new RegExp(keyword, "g")) ||
        value.id?.match(new RegExp(keyword, "g")) ||
        value.slug?.toLowerCase().match(new RegExp(keyword, "g")) ||
        value.slug?.match(new RegExp(keyword, "g")) ||
        value.category?.toLowerCase().match(new RegExp(keyword, "g"))
    })
}

export const UserSearchProducts = (array,keyword) => {
    //might not need one of the lines below for slug match
    if(!array) return
    return array.filter(value => {
        return value.name?.toLowerCase().match(new RegExp(keyword,'g')) ||
        value.name?.match(new RegExp(keyword, "g")) ||
        value.category?.toLowerCase().match(new RegExp(keyword, "g")) ||
        value.category?.match(new RegExp(keyword, "g"))
    })
}

export const SearchUsers = (array,keyword) => {
    //might not need one of the lines below for slug match
    if(!array) return
    return array.filter(value => {
        return value.id?.toLowerCase().match(new RegExp(keyword, "g")) ||
        value.id?.match(new RegExp(keyword, "g")) ||
        value.email?.match(new RegExp(keyword, "g")) ||
        value.email?.toLowerCase().match(new RegExp(keyword, "g")) ||
        value.fname?.match(new RegExp(keyword, "g")) ||
        value.fname?.toLowerCase().match(new RegExp(keyword, "g")) ||
        value.lname?.match(new RegExp(keyword, "g")) ||
        value.lname?.toLowerCase().match(new RegExp(keyword, "g"))
        // value.category.toLowerCase().match(new RegExp("^" + keyword + "$"))
    })
}


export const ProductSearch = (array,keyword) => {
    if(!array) return
    const category = keyword.toLowerCase()
    console.log(category)
}