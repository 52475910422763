import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom'
import {BsFillArrowUpCircleFill as Up, BsFillArrowDownCircleFill as Down} from 'react-icons/bs'

export const DaysDropDown = ({product,index,month,year}) => {
  
    const [open,setOpen] = useState(true)
    const navigate = useNavigate()

    return (

        <>
    <div className='w-full lg:w-[80vw] grid grid-flow-row-dense grid-cols-5 bg-white text-black text-xs md:text-base lg:text-lg border-y-2'>
        <div className='col-span-1 relative flex items-center justify-center cursor-pointer text-center text-white border-x-2 'onClick={()=> navigate(`${parseInt(index)+1}`)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="text-black" viewBox="0 0 16 16"> 
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
            </svg>
            <p className='absolute top-[30%] lg:top-[10%] text-black'>{parseInt(index)+1}</p>
        </div>
        <div className='col-span-2 border-b border-r-2 flex items-center justify-center'>N{product[0]?.totalSales ? product[0]?.totalSales : 0}</div>
        <div className='col-span-1 border-b border-r-2 flex items-center justify-center'>{product.length > 0 ? product[0]?.orders.length:0}</div>
        <div className='col-span-1 border-b border-r-2 flex items-center justify-center cursor-pointer text-2xl md:text-3xl' onClick={()=>setOpen(!open)}>{open ?  <Down/>:<Up/>}</div>
    </div>

    {open ? null :
    <div className='w-full lg:w-[80vw] grid grid-flow-row-dense grid-cols-4 bg-secondary text-white text-xs md:text-base lg:text-lg border-t-2'>
        {product.length > 0 ?
        <>
            <div className='col-span-1 border-b border-r-2 flex justify-center items-center'>{"Order ID"}</div>
            <div className='col-span-2 border-b border-r-2 flex justify-center items-center'>{"User ID"}</div>
            <div className='col-span-1 border-b border-r-2 flex justify-center items-center'>{"Sale Amount"}</div>
        </>
        :
        <div className='col-span-4 border-b border-r-2 bg-gray-200 flex justify-center text-black items-center'>{"There are no orders for this day"}</div>
        }
    </div>


    }
    

    {open ?  null:
    product[0]?.orders?.map((item,i)=>{
        return <div key={i} className='w-full flex flex-col items-center text-center'>
            <div className='w-full lg:w-[80vw] grid grid-flow-row-dense grid-cols-4 bg-white text-black text-xs md:text-base border-t-2'>
                <div className='overflow-hidden col-span-1 border-b border-r-2 flex justify-center items-center cursor-pointer hover:underline hover:text-secondary' onClick={()=>item?.orderID ? navigate(`/admin/orders/${item?.orderID}`) : null}>{item?.orderID}</div>
                <div className='overflow-hidden col-span-2 border-b border-r-2 flex justify-center items-center cursor-pointer hover:underline hover:text-secondary' onClick={()=>item?.userID ? navigate(`/admin/users/${item?.userID}`) : null} >{item?.userID}</div>
                <div className='col-span-1 border-b border-r-2 flex justify-center items-center'>N{item?.orderAmount}</div>
            </div>
        </div>
    })
    }
    </>
  )
}
