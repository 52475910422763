import React,{useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
import { useStateContext } from '../../../services/contexts/StateContext'
import DataService from '../../../services/providers/data.service'
import { sendPaymentReminder, sendPaymentConfirmation } from '../../../services/serverRequest'
import { ConfirmationModal } from '../../../components/general-components/ConfirmationModal'
import { DeleteModal } from '../../../components/general-components/DeleteModal'

export const SingleOrderPage = () => {

  const {currentUser} = useStateContext()
  const [item,setItem] = useState(null)
  const [loading,setLoading] = useState(true)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const {id} = useParams()
  const navigate = useNavigate()

  const date = item?.created?.toDate().toUTCString()

  const handleUpdate = async (e) => {
    
    e.preventDefault()

    const order = {
      ...item,
      status: true,
      updates: [{
        admin: currentUser.id,
        date: new Date(),
        action: "Confirm User Payment"
      }],
    }


    try{
      await DataService.updateDocument('orders',id,order)
      await sendPaymentConfirmation(item)
    } catch (err) {
      console.log(err)
    } finally{
      setLoading(true)
      setOpenConfirmation((open)=>!open)
      toast.success(`Order ${id} has been successfully confirmed`);
    }

  }

  const handleDelete = async (e) => {
    
    e.preventDefault()

    const order = {
      ...item,
      updates: item.updates ? [...item.updates,{
        admin: currentUser.id,
        date: new Date(),
        action: "Delete Order"
      }]
      : [{
        admin: currentUser.id,
        date: new Date(),
        action: "Delete Order"
      }],
    }

    try{
      await DataService.deleteOrder(id,order)
    } catch (err) {
      console.log(err)
    } finally{
      navigate('/admin/orders')
      toast.success(`Order ${id} has been successfully deleted`);
    }

  }

  const sendReminder = async (e) => {
    e.preventDefault()

    const order = {
      ...item,
      updates: {
        admin: currentUser.id,
        date: new Date(),
        action: "Send Payment Reminder"
      },
      reminderSent: true,
    }

    try{
      await sendPaymentReminder(item)
      .then( async () => {
        await DataService.updateDocument('orders',id,order)
      })
    }catch(err){
      console.log(err)
    } finally{
      setLoading(true)
      toast.info(`Payment Reminder Email Has Been Successfully Sent`);
    }
  }

  useEffect(()=>{
    
    const getOrder = async () => {
      const data = await DataService.getDocument("orders",id)
      setItem(data.data())
      
      if(data.data().viewed) return
      await DataService.updateDocument('orders',id,{...data.data(),viewed:true})
      
    }
    
    if(loading){
      getOrder()
      setLoading(prev => !prev)
    }

  },[id,loading])

  return (
    <div className='min-h-[100vh] w-full flex flex-col items-center bg-background pb-[25vh]'>
      {openConfirmation ? <ConfirmationModal setOpen={setOpenConfirmation} runFunction={handleUpdate}/>:null}
      {openDelete ? <DeleteModal setOpen={setOpenDelete} runFunction={handleDelete}/>:null}
      {/* page title */}
      <span className='text-white text-3xl md:text-5xl bg-gradient-to-b from-secondary via-emerald-800 to-emerald-900 px-10 py-5 mt-10 border-primary border-2 rounded-2xl'>{`ORDER# ${id}`}</span>
          <span className='mt-10 bg-blue-100 text-black text-center rounded-full text-md md:text-lg px-5'>{`ORDER DATE :  ${date}`}</span>

          {/* CUSTOMER INFO SECTION */}
          <div className='w-full lg:max-w-[75vw] flex flex-col justify-between mt-10 items-center'>

          <div className='grid grid-col-2 w-full bg-gradient-to-t from-gray-700 via-gray-900 to-black text-base lg:text-2xl mb-10'>
            <div className='col-span-2 flex items-center justify-center text-center h-10 border'>{"CUSTOMER CONTACT INFO"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Name :"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.customerFName} ${item?.customerLName}`}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Phone :"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.customerPhone}`}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Email :"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.customerEmail}`}</div>

            <div className={`col-span-1 flex items-center justify-center text-center h-10 border-b border-x ${item?.userUID ? ``:`hidden`}`}>{"UID :"}</div>
            <div className={`col-span-1 flex items-center justify-center text-center h-10 border-b border-x ${item?.userUID ? ``:`hidden`} hover:underline decoration-secondary cursor-pointer`}
            onClick={()=> item.userUID === 'Guest User' ? null : navigate(`/admin/users/${item.userUID}`)}
            >{`${item?.userUID}`}</div>
          </div>

          <div className='grid grid-col-2 w-full bg-gradient-to-t from-gray-700 via-gray-900 to-black text-xl lg:text-2xl'>
            <div className='col-span-2 flex items-center justify-center text-center h-10 border'>{"ORDER DETAILS"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Subtotal :"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`N${item?.subtotal}`}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Shipping :"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`N${item?.shipping}`}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Total :"}</div>
            <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`N${item?.total}`}</div>
          </div>

            <div className='h-full w-[50vw] flex justify-center mb-10'>
              <div className='flex-col flex items-center'>

                <span className={`px-10 py-5 text-6xl mt-10 ${item?.status ? `bg-green-500 text-white`:`bg-red-500 border-4 border-white text-white`}`}>{item?.status ? "PAID":"UNPAID"}</span>
                
                {item?.status ? null :
                <button  className='w-[80%] h-10 mt-5 bg-gradient-to-b from-emerald-400 via-emerald-600 to-emerald-800 shadow-md hover:shadow-inner shadow-black text-white rounded-full'
                onClick={()=> setOpenConfirmation((open)=>!open)}
                >{"CONFIRM PAYMENT"}</button>}

                {item?.status ? null :
                <button disabled={item?.reminderSent} className={item?.reminderSent ? 'w-[80%] h-10 mt-5  bg-gray-500 text-white rounded-full':'w-[80%] h-10 mt-5 bg-gradient-to-b from-cyan-400 via-cyan-600 to-cyan-800 shadow-md hover:shadow-inner shadow-black text-white rounded-full'}
                onClick={(e) => sendReminder(e)}
                >{item?.reminderSent ? "REMINDER SENT": "SEND PAYMENT REMINDER"}</button>}

                <button  className='w-[80%] h-10 mt-5 bg-gradient-to-b from-red-400 via-red-600 to-red-800 shadow-md hover:shadow-inner shadow-black text-white rounded-full'
                onClick={()=> setOpenDelete((open)=>!open)}
                >{"DELETE ORDER"}</button>
              </div>
            </div>

            <div className='grid grid-col-2 w-full bg-gradient-to-t from-gray-700 via-gray-900 to-black text-xl lg:text-2xl'>
                <div className='col-span-2 flex items-center justify-center text-center h-10 border'>{"DELIVERY DETAILS"}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Address :"}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.address}`}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Address 2 :"}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.address_2 ? item?.address_2:""}`}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"City :"}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.city}`}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Postal Code :"}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.postal_code ? item?.postal_code:""}`}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"State :"}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.state}`}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{"Country :"}</div>
                <div className='col-span-1 flex items-center justify-center text-center h-10 border-b border-x'>{`${item?.country}`}</div>
            </div>
          </div>

          {/* ORDER ITEMS */}
          <span className='mt-10 bg-gradient-to-t from-gray-700 via-gray-900 to-black text-white text-2xl px-5 py-2'>{"ORDER DETAILS"}</span>
          <div className='w-[80%] flex-col mt-5'>
                  {item?.userCart.map((product,i) => (
                    <div key={i} className='w-full flex mt-5 flex-col lg:flex-row items-center'>
                      <img src={product?.image} className='w-40 h-40 mr-5 border-black border-2' alt=''/>
                      <div className='h-full flex flex-col lg:flex-row mt-5'>
                        <div className='h-full flex flex-col border-2 border-black bg-white text-black px-5 mr-1'>
                          <span className='text-center border-b-2 border-black'>Name</span>
                          <span className='text-center'>{product?.name}</span>
                        </div>
                        <div className='h-full flex flex-col border-2 border-black bg-white text-black px-5 mr-1'>
                          <span className='text-center border-b-2 border-black'>Size</span>
                          <span className='text-center'>{product?.size}</span>
                        </div>
                        <div className='h-full flex flex-col border-2 border-black bg-white text-black px-5 mr-1'>
                          <span className='text-center border-b-2 border-black'>QTY</span>
                          <span className='text-center'>{product?.qty}</span>
                        </div>
                        <div className='h-full flex flex-col border-2 border-black bg-white text-black px-5 mr-1'>
                          <span className='text-center border-b-2 border-black'>Sale Price</span>
                          <span className='text-center'>N{product?.price}</span>
                        </div>
                        <div className='h-full flex flex-col border-2 border-black bg-white text-black px-5 mr-1'>
                          <span className='text-center border-b-2 border-black'>Applied Discount</span>
                          <span className='text-center'>-{product?.discountRate}%</span>
                        </div>
                      </div>
                    </div>
                  ))}
          </div>

    </div>
  )
}
