import { useState } from 'react'
import types from '../../assets/skin-types.png'
import test from '../../assets/type_test.png'
import step_1 from '../../assets/step-1.png'
import step_2 from '../../assets/step-2.png'
import step_3 from '../../assets/step-3.png'
import step_4 from '../../assets/step-4.png'
import step_5 from '../../assets/step-5.png'
import SkinGuideComponent from '../../components/general-components/SkinGuideComponent'


export const SkinGuidePage = () => {

  return (
    <div className='w-[100vw] flex flex-col items-center justify-center my-10'>
        <h2 className='text-primary text-4xl md:text-6xl text-center underline decoration-secondary underline-offset-8 mb-10'>Discover Your Skin Type</h2>
        <div className='text-primary w-full flex flex-col items-center justify-center'>
            
            <SkinGuideComponent image={test} description={'skin type test'}/>
            <SkinGuideComponent image={types} description={'skin types'}/>
            <SkinGuideComponent image={step_1} description={'cleanse products'}/>
            <SkinGuideComponent image={step_2} description={'exfoliate products'}/>
            <SkinGuideComponent image={step_3} description={'mask products'}/>
            <SkinGuideComponent image={step_4} description={'moisture products'}/>
            <SkinGuideComponent image={step_5} description={'lock products'}/>

        </div>
    </div>
  )
}
