import React,{useState} from 'react'
import {toast} from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { DeleteModal } from '../general-components/DeleteModal'
import DataService from '../../services/providers/data.service'

export const AdminProductComponent = ({item,setProducts,products,index}) => {

  const [openDelete, setOpenDelete] = useState(false)
  const navigate = useNavigate()

  const handleDelete = async (e) => {
    e.preventDefault()
    
    try{
      await DataService.deleteDocument("products",item.id)
    } catch (err) {
      console.log(err)
    } finally{
      setOpenDelete(false)
      products.splice(index,1)
      setProducts([...products])
      toast.success(`Product ${item.name} has been successfully deleted`);

    }

  }

  return (
    <div className='w-[300px] min-h-[400px] flex flex-col'>
        <div className='w-[300px] min-h-[50px] flex flex-col items-center justify-evenly text-center border-black border-2 text-black bg-white'>
            <span className=' text-lg'>{item.name}</span>
            <span className=' text-sm'>{item.id}</span>
        </div>
        <img className='w-[300px] h-[300px]' src={item?.image} alt=''></img>
        <div className='w-[300px] h-[40px] flex justify-evenly items-center border-2 border-black'>
            <button className='w-full h-full bg-gradient-to-b from-emerald-400 via-emerald-600 to-emerald-800 shadow-md hover:shadow-inner hover:shadow-black border-r-2 border-black' onClick={()=> navigate(`${item.id}`)}>EDIT</button>
            <button className='bg-gradient-to-b from-red-400 via-red-600 to-red-800 shadow-md hover:shadow-inner hover:shadow-black w-full h-full' onClick={()=> setOpenDelete(!openDelete)}>DELETE</button>
        </div>

        {openDelete ? <DeleteModal open={openDelete} setOpen={setOpenDelete} runFunction={handleDelete} /> : null}
    </div>
  )
}
