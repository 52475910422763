import React from 'react'

export const LoadingPage = () => {
  return (
    <div className='absolute top-0 left-0 w-[100vw] h-[100vh] bg-black text-white text-2xl items-center flex flex-col justify-center z-[100] font-serif'>
      <div className='w-full flex items-center justify-center text-7xl'>
        <div className='animate-blink-h reflection'>{"H"}</div>
        <div className='animate-blink-s reflection'>{".S."}</div>
        <div className='animate-blink-f reflection'>{"F"}</div>
      </div>
        <div className='animate-blink text-xl'>{"NATURALS"}</div>
    </div>
  )
}
