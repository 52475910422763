import { useState, useRef } from 'react'
import {MdSaveAlt as Save} from 'react-icons/md'
import {GrDocumentUpdate} from 'react-icons/gr'
import {FcCancel} from 'react-icons/fc'
import DataService from '../../services/providers/data.service'
import {toast} from 'react-toastify'



const ShippingUpdateComponent = ({location,setLoading}) => {

  const [toggle, setToggle] = useState(true)

  const formEl = useRef()

  
  const updateLocation = async (e) => {
    e.preventDefault()

    try{
        const updatedLocation = {
        city: formEl.current.city.value,
        state: formEl.current.state.value,
        country: formEl.current.country.value,
        rate: parseInt(formEl.current.rate.value),

        }
        const id = location.id

        await DataService.updateDocument("shipping", id, updatedLocation)
        .then(() => {
          toast.success(`Location ${updatedLocation.city.toUpperCase()}, ${updatedLocation.state.toUpperCase()}, ${updatedLocation.country.toUpperCase()} has been updated`)
        })
        setLoading(true)
        setToggle(!toggle)


    }catch(err){
        toast.error(err.message)
    }
  }

  return (
    <div className='w-[100%] flex flex-col lg:flex-row h-[20%] items-center justify-center'>
        {toggle ? 
        <button onClick={()=> setToggle(!toggle)} className='h-6 lg:h-10 w-[50%] bg-yellow-500 text-lg lg:text-xl rounded-full p-1 lg:mx-2 cursor-pointer flex items-center justify-center mt-5'>
            <GrDocumentUpdate/>
        </button>
        :
        <div className='w-[100%] flex flex-col h-[20%]'>
            <form ref={formEl} className='w-full h-[70%] flex flex-col items-center justify-center'>
                <input defaultValue={location.city} name='city' type='text' className='w-[90%] h-10 lg:w-[50%] text-primary text-center text-sm rounded-lg p-2 border-2 border-black mb-2 lg:mx-2'/>
                <input defaultValue={location.state}  name='state' type='text' className='w-[90%] h-10 lg:w-[50%] text-primary text-center text-sm rounded-lg p-2 border-2 border-black mb-2 lg:mx-2'/>
                <input defaultValue={location.country} name='country' type='text' className='w-[90%] h-10 lg:w-[50%] text-primary text-center text-sm rounded-lg p-2 border-2 border-black mb-2 lg:mx-2'/>
                <input defaultValue={location.rate} name='rate' type='number' className='w-[90%] h-10 lg:w-[50%] text-primary text-center text-sm rounded-lg p-2 border-2 border-black mb-2 lg:mx-2'/>
            </form>
            <div className='w-full h-[30%] flex flex-row items-center justify-center mt-5'>
                <button onClick={()=> setToggle(!toggle)} className='h-6 lg:h-10 w-[40%] bg-red-400 text-white text-lg lg:text-xl rounded-full p-1 lg:mx-2 cursor-pointer flex items-center justify-center'>
                    <FcCancel color='white'/>
                </button>
                <button onClick={updateLocation} className='h-6 lg:h-10 w-[40%] bg-yellow-500 text-lg lg:text-xl rounded-full p-1 lg:mx-2 cursor-pointer flex items-center justify-center'>
                    <Save/>
                </button>
            </div>
        </div>
        }   
    </div>
  )
}

export default ShippingUpdateComponent