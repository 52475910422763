import React,{useState} from 'react'
import {AiOutlinePlus as Plus, AiOutlineMinus as Minus,AiOutlineQuestionCircle as Question} from 'react-icons/ai'


export const FAQComponent = ({item}) => {

  const [open, setOpen] = useState(false)
  return (
    <>
    <div className='w-full h-28 flex items-center justify-center bg-white border-y border-black text-black cursor-pointer text-4xl text-center'
    onClick={()=>setOpen(!open)}
    >
        {open ? <Minus/>:<Plus/>}
        {item.category}
    </div>
    <div className={`${open ? `bg-white`:`hidden`} w-full`}>
        {item.faqs.map((faq,index) => (
            <div key={index}
            className={'w-full flex flex-col items-center justify-center text-black'}
            >
                <div className='w-full py-5 flex flex-col md:flex-row items-center justify-center text-center bg-yellow-500 text-3xl'>
                    <Question size={60} className='mr-5'/>
                    <h4 className='w-[75%]'>
                    {faq.question}
                    </h4>
                </div>
                <div className='w-full flex items-center justify-center text-center text-2xl py-5'>
                    <h4 className='w-[75%]'>
                    {faq.answer}
                    </h4>
                </div>
            </div>
        ))}
    </div>
    </>
  )

}
